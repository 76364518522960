import { t } from '@lingui/macro'

export interface MenuSection {
  title: string
  items: MenuItem[]
  overflow?: boolean
  closeMenu?: () => void
}

export interface MenuItem {
  label: string
  href: string
  internal?: boolean
  overflow?: boolean
  closeMenu?: () => void
}

export const menuContent: MenuSection[] = [
  {
    title: t`App`,
    items: [
      { label: t`Wrap`, href: '/wrap', internal: true },
      { label: t`Airdrop`, href: '/airdrop', internal: true },
    ],
  },
  {
    title: t`Need help?`,
    items: [{ label: t`Contact us`, href: 'https://discord.com/invite/TefBNDZBQP' }],
  },
]
