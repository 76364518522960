import { Trans } from '@lingui/macro'
import { useWeb3React } from '@web3-react/core'
import { useToggleAccountDrawer } from 'components/AccountDrawer'
import RedeemModal from 'components/earn/v2/RedeemModal'
import { V2Unsupported } from 'components/V2Unsupported'
import { POINT, RING } from 'constants/tokens'
import { ChainId } from 'eth-mainnet-few-sdk-core-2'
import { useNetworkSupportsV2 } from 'hooks/useNetworkSupportsV2'
import { useCallback, useState } from 'react'
import styled from 'styled-components'
import { ThemedText } from 'theme'

import { ButtonPrimary } from '../../components/Button'
import { AutoColumn } from '../../components/Column'
import { CardBGImage, CardNoise, CardSection, DataCard } from '../../components/earn/v2/styled'
import { RowBetween } from '../../components/Row'
import { useToggleWalletModal } from '../../state/application/hooks'
import { useCurrencyBalance } from '../../state/connection/hooks'

const PageWrapper = styled(AutoColumn)`
  padding: 68px 8px 0px;
  max-width: 640px;
  width: 100%;
  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.md}px`}) {
    padding: 48px 8px 0px;
  }
  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    padding-top: 20px;
  }
`

const PositionInfo = styled(AutoColumn)<{ dim: any }>`
  position: relative;
  max-width: 640px;
  width: 100%;
  opacity: ${({ dim }) => (dim ? 0.6 : 1)};
`

const BottomSection = styled(AutoColumn)`
  border-radius: 12px;
  width: 100%;
  position: relative;
`

const StyledDataCard = styled(DataCard)<{ bgColor?: any; showBackground?: any }>`
  background: radial-gradient(76.02% 75.41% at 1.84% 0%, #1e1a31 0%, #3d51a5 100%);
  z-index: 2;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  background: ${({ theme, bgColor, showBackground }) =>
    `radial-gradient(91.85% 100% at 1.84% 0%, ${bgColor} 0%,  ${
      showBackground ? theme.black : theme.deprecated_bg5
    } 100%) `};
`

const PoolData = styled(DataCard)`
  background: none;
  border: 1px solid ${({ theme }) => theme.deprecated_bg4};
  padding: 1rem;
  z-index: 1;
`

const DataRow = styled(RowBetween)`
  justify-content: center;
  gap: 12px;
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    flex-direction: column;
    gap: 12px;
  `};
`

export default function RedeemManager() {
  const { account, chainId } = useWeb3React()

  const toggleWalletDrawer = useToggleAccountDrawer() // toggle wallet when disconnected

  const networkSupportsV2 = useNetworkSupportsV2()

  const pointBalance = useCurrencyBalance(account ?? undefined, POINT[chainId ?? ChainId.BLAST])
  const ringTokenBalance = useCurrencyBalance(account ?? undefined, RING[chainId ?? ChainId.BLAST])

  // const showToEarnButton = useMemo(() => {
  //   return Boolean(pointBalance?.equalTo('0'))
  // }, [pointBalance])

  // toggle for staking modal and unstaking modal
  const [showRedeemModal, setShowRedeemModal] = useState(false)

  const toggleWalletModal = useToggleWalletModal()

  const wethHandleWrapClick = useCallback(() => {
    if (account) {
      setShowRedeemModal(true)
    } else {
      toggleWalletModal()
    }
  }, [account, toggleWalletModal])

  if (!networkSupportsV2) return <V2Unsupported />

  return (
    <PageWrapper gap="lg" justify="center">
      <DataRow style={{ gap: '24px' }}>
        <PoolData>
          <AutoColumn gap="sm">
            <ThemedText.DeprecatedBody style={{ margin: 0 }}>
              <Trans>{pointBalance?.toSignificant(6)} RING POINT available</Trans>
            </ThemedText.DeprecatedBody>
          </AutoColumn>
        </PoolData>
        <PoolData>
          <AutoColumn gap="sm">
            <ThemedText.DeprecatedBody style={{ margin: 0 }}>
              <Trans>
                {ringTokenBalance?.toSignificant(6)} {RING[chainId ?? ChainId.BLAST].symbol} available
              </Trans>
            </ThemedText.DeprecatedBody>
          </AutoColumn>
        </PoolData>
      </DataRow>
      <>
        <RedeemModal isOpen={showRedeemModal} onDismiss={() => setShowRedeemModal(false)} userBalances={pointBalance} />
      </>
      <PositionInfo gap="lg" justify="center" dim={false}>
        <BottomSection gap="lg" justify="center">
          <StyledDataCard disabled={false}>
            <CardSection>
              <CardBGImage desaturate />
              <CardNoise />
              <AutoColumn gap="md">
                <RowBetween>
                  <ThemedText.DeprecatedWhite fontWeight={600}>
                    <Trans>Your RING POINT balance</Trans>
                  </ThemedText.DeprecatedWhite>
                </RowBetween>
                <RowBetween style={{ alignItems: 'baseline' }}>
                  <ThemedText.DeprecatedWhite fontSize={36} fontWeight={600}>
                    {pointBalance?.toSignificant(6) ?? '-'}
                  </ThemedText.DeprecatedWhite>
                  <ThemedText.DeprecatedWhite>
                    <Trans>RING POINT</Trans>
                  </ThemedText.DeprecatedWhite>
                </RowBetween>
              </AutoColumn>
            </CardSection>
          </StyledDataCard>
        </BottomSection>

        <DataRow style={{ marginBottom: '1rem' }}>
          {!account ? (
            <ButtonPrimary padding="8px" $borderRadius="8px" width="160px" onClick={toggleWalletDrawer}>
              <Trans>Connect Wallet</Trans>
            </ButtonPrimary>
          ) : (
            <DataRow>
              <ButtonPrimary padding="8px" $borderRadius="8px" width="50%" onClick={wethHandleWrapClick}>
                <Trans>Redeem RING POINT</Trans>
              </ButtonPrimary>
            </DataRow>
          )}
        </DataRow>
      </PositionInfo>
    </PageWrapper>
  )
}
