import { ComponentProps } from 'react'

export const SwapIcon = (props: ComponentProps<'svg'>) => (
  <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" width="24" height="24" {...props}>
    <path
      fill="currentColor"
      d="M313.851 315.061h639.673c24.3 0 44 19.7 44 44s-19.7 44-44 44H93l275.518-261.004c17.641-16.712 45.49-15.958 62.203 1.683 16.712 17.642 15.958 45.49-1.683 62.203L313.85 315.06zM70 669c-24.3 0-44-19.7-44-44s19.7-44 44-44h860.524L655.006 842.004c-17.641 16.712-45.49 15.959-62.202-1.683-16.713-17.641-15.96-45.49 1.682-62.202L709.673 669H70z"
    ></path>
  </svg>
)
