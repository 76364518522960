import { Navigate, useParams } from 'react-router-dom'

import AddLiquidityFew from './index'

export function RedirectDuplicateTokenIdsFew() {
  const { currencyIdA, currencyIdB } = useParams<{ currencyIdA: string; currencyIdB: string }>()

  if (currencyIdA && currencyIdB && currencyIdA.toLowerCase() === currencyIdB.toLowerCase()) {
    return <Navigate to={`/add/few/${currencyIdA}`} replace />
  }

  return <AddLiquidityFew />
}
