import { asSupportedChain } from 'constants/chains'
import { Currency, Token } from 'eth-mainnet-few-sdk-core-2'
import JSBI from 'jsbi'

import { nativeOnChain, WRAPPED_NATIVE_CURRENCY } from '../constants/tokens'

export function unwrappedToken(currency: Currency): Currency {
  if (currency.isNative) return currency
  const formattedChainId = asSupportedChain(currency.chainId)
  if (formattedChainId && WRAPPED_NATIVE_CURRENCY[formattedChainId]?.equals(currency))
    return nativeOnChain(currency.chainId)
  return currency
}

export function unwrappedOriginToken(currency: Token): Currency {
  if (currency?.isNative) return currency
  const formattedChainId = asSupportedChain(currency?.chainId)
  if (formattedChainId && WRAPPED_NATIVE_CURRENCY[formattedChainId]?.equals(currency))
    return nativeOnChain(currency.chainId)
  return currency
}

export function unwrappedToDecimals(num: JSBI | string | number, decimals: number) {
  return JSBI.multiply(JSBI.BigInt(num), JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(18 - decimals)))
}
