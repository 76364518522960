import { BrowserEvent, InterfaceElementName, SharedEventName } from '@uniswap/analytics-events'
import { useWeb3React } from '@web3-react/core'
import { TraceEvent } from 'analytics'
import { ReactComponent as PoolLogo } from 'assets/svg/pool.svg'
import { ReactComponent as RocketLogo } from 'assets/svg/rocket-launch.svg'
import { AnimateNumber } from 'components/AnimateNumber'
import { ButtonEmphasis, ButtonSize, ThemeButton } from 'components/Button'
import Column from 'components/Column'
import { Power } from 'components/Icons/Power'
import { Settings } from 'components/Icons/Settings'
import { AutoRow } from 'components/Row'
import { LoadingBubble } from 'components/Tokens/loading'
import { getConnection } from 'connection'
import { ChainId, WETH9 } from 'eth-mainnet-few-sdk-core-2'
import { useAllTokens } from 'hooks/Tokens'
import useENSName from 'hooks/useENSName'
import useStablecoinPrice from 'hooks/useStablecoinPrice'
import useNativeCurrency from 'lib/hooks/useNativeCurrency'
import { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import { useCurrencyBalances } from 'state/connection/hooks'
import { useAppDispatch } from 'state/hooks'
import { setRecentConnectionDisconnected } from 'state/user/reducer'
import styled from 'styled-components'
import { CopyHelper, ThemedText } from 'theme'
import { shortenAddress } from 'utils'

import StatusIcon from '../Identicon/StatusIcon'
import { useToggleAccountDrawer } from '.'
import IconButton, { IconHoverText, IconWithConfirmTextButton } from './IconButton'
import MiniPortfolio from './MiniPortfolio'
import { portfolioFadeInAnimation } from './MiniPortfolio/PortfolioRow'

const AuthenticatedHeaderWrapper = styled.div`
  padding: 20px 16px;
  display: flex;
  flex-direction: column;
  flex: 1;
`

const HeaderButton = styled(ThemeButton)`
  border-color: transparent;
  border-radius: 12px;
  border-style: solid;
  border-width: 1px;
  height: 40px;
  margin-top: 8px;
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  & > a,
  & > button {
    margin-right: 8px;
  }

  & > button:last-child {
    margin-right: 0px;
    ${IconHoverText}:last-child {
      left: 0px;
    }
  }
`

const StatusWrapper = styled.div`
  display: inline-block;
  width: 70%;
  max-width: 70%;
  padding-right: 8px;
  display: inline-flex;
`

const AccountNamesWrapper = styled.div`
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  gap: 2px;
`

const HeaderWrapper = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

const CopyText = styled(CopyHelper).attrs({
  iconSize: 14,
  iconPosition: 'right',
})``

const FadeInColumn = styled(Column)`
  ${portfolioFadeInAnimation}
`

const PortfolioDrawerContainer = styled(Column)`
  flex: 1;
`

export default function AuthenticatedHeader({ account, openSettings }: { account: string; openSettings: () => void }) {
  const { connector, chainId } = useWeb3React()
  const { ENSName } = useENSName(account)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const connection = getConnection(connector)
  const disconnect = useCallback(() => {
    connector.deactivate?.()
    connector.resetState()
    dispatch(setRecentConnectionDisconnected())
  }, [connector, dispatch])

  const WETH = WETH9[chainId ?? ChainId.BLAST]
  const USDPrice = useStablecoinPrice(WETH)
  const ethPrice = Number(USDPrice?.toFixed(2))
  const allTokens = useAllTokens()
  const native = useNativeCurrency(chainId)
  const currencies = useMemo(() => {
    if (allTokens && native) {
      return [native, ...Object.values(allTokens)]
    }
    return []
  }, [allTokens, native])
  const balance: any = useCurrencyBalances(account, currencies)
  const isLoading = useMemo(() => {
    if (balance.includes(undefined)) return true
    return false
  }, [balance])

  const totalBalance = useMemo(() => {
    if (!isLoading && ethPrice && USDPrice) {
      return (
        (Number(balance?.[0]?.toFixed(4)) + Number(balance?.[1]?.toFixed(4))) * ethPrice +
        Number(balance?.[2]?.toFixed(4))
      )
    }
    return 0
  }, [USDPrice, balance, ethPrice, isLoading])

  // TODO - new function without uniswap api
  // const { data: portfolioBalances } = useCachedPortfolioBalancesQuery({ account })
  // const portfolio = portfolioBalances?.portfolios?.[0]
  // const totalBalance = portfolio?.tokensTotalDenominatedValue?.value
  // const absoluteChange = portfolio?.tokensTotalDenominatedValueChange?.absolute?.value
  // const percentChange = portfolio?.tokensTotalDenominatedValueChange?.percentage?.value
  const [showDisconnectConfirm, setShowDisconnectConfirm] = useState(false)

  const toggleWalletDrawer = useToggleAccountDrawer()

  const navigateToEarn = useCallback(() => {
    toggleWalletDrawer()
    navigate('/earn')
  }, [navigate, toggleWalletDrawer])

  const navigateToPools = useCallback(() => {
    toggleWalletDrawer()
    navigate('/pools')
  }, [navigate, toggleWalletDrawer])

  return (
    <AuthenticatedHeaderWrapper>
      <HeaderWrapper>
        <StatusWrapper>
          <StatusIcon account={account} connection={connection} size={40} />
          {account && (
            <AccountNamesWrapper>
              <ThemedText.SubHeader>
                <CopyText toCopy={ENSName ?? account}>{ENSName ?? shortenAddress(account)}</CopyText>
              </ThemedText.SubHeader>
              {/* Displays smaller view of account if ENS name was rendered above */}
              {ENSName && (
                <ThemedText.BodySmall color="neutral2">
                  <CopyText toCopy={account}>{shortenAddress(account)}</CopyText>
                </ThemedText.BodySmall>
              )}
            </AccountNamesWrapper>
          )}
        </StatusWrapper>
        <IconContainer>
          <IconButton
            hideHorizontal={showDisconnectConfirm}
            data-testid="wallet-settings"
            onClick={openSettings}
            Icon={Settings}
          />
          <TraceEvent
            events={[BrowserEvent.onClick]}
            name={SharedEventName.ELEMENT_CLICKED}
            element={InterfaceElementName.DISCONNECT_WALLET_BUTTON}
          >
            <IconWithConfirmTextButton
              data-testid="wallet-disconnect"
              onConfirm={disconnect}
              onShowConfirm={setShowDisconnectConfirm}
              Icon={Power}
              text="Disconnect"
              dismissOnHoverOut
            />
          </TraceEvent>
        </IconContainer>
      </HeaderWrapper>
      <PortfolioDrawerContainer>
        {!isLoading ? (
          <FadeInColumn gap="xs">
            <ThemedText.HeadlineLarge fontWeight={535} data-testid="portfolio-total-balance">
              <AutoRow>
                $<AnimateNumber number={totalBalance} fixed={2} />
              </AutoRow>
            </ThemedText.HeadlineLarge>
            <AutoRow marginBottom="20px">
              <ThemedText.BodySecondary>ETH + WETH + USDB</ThemedText.BodySecondary>
            </AutoRow>
          </FadeInColumn>
        ) : (
          <Column gap="xs">
            <LoadingBubble height="44px" width="170px" />
            <LoadingBubble height="16px" width="100px" margin="4px 0 20px 0" />
          </Column>
        )}
        <HeaderButton
          data-testid="earn-blast-point"
          onClick={navigateToEarn}
          size={ButtonSize.medium}
          emphasis={ButtonEmphasis.high}
        >
          <RocketLogo width={18} height={18} />
          <ThemedText.DeprecatedWhite>Earned Blast Point</ThemedText.DeprecatedWhite>
        </HeaderButton>
        <HeaderButton
          size={ButtonSize.medium}
          emphasis={ButtonEmphasis.high}
          onClick={navigateToPools}
          data-testid="add-liquidity-pool"
        >
          <PoolLogo width={18} height={18} />
          <ThemedText.DeprecatedWhite>Add Liquidity Pool</ThemedText.DeprecatedWhite>
        </HeaderButton>
        <MiniPortfolio account={account} />
      </PortfolioDrawerContainer>
    </AuthenticatedHeaderWrapper>
  )
}
