import { ChainId } from 'eth-mainnet-few-sdk-core-2'

import MULTICALL_ABI from './abi.json'

const MULTICALL_NETWORKS: { [chainId: number]: string } = {
  [ChainId.MAINNET]: '0xeefBa1e63905eF1D7ACbA5a8513c70307C1cE441',
  [ChainId.GOERLI]: '0x77dCa2C955b15e9dE4dbBCf1246B4B85b651e50e',
  [ChainId.SEPOLIA]: '0xd9DeF4AE0F6e50C4Cc12e567798d24091Bc302Ff',
  [ChainId.OPTIMISM]: '0x77dCa2C955b15e9dE4dbBCf1246B4B85b651e50e',
  [ChainId.OPTIMISM_GOERLI]: '0x77dCa2C955b15e9dE4dbBCf1246B4B85b651e50e',
  [ChainId.ARBITRUM_ONE]: '0x77dCa2C955b15e9dE4dbBCf1246B4B85b651e50e',
  [ChainId.ARBITRUM_GOERLI]: '0x77dCa2C955b15e9dE4dbBCf1246B4B85b651e50e',
  [ChainId.POLYGON]: '0x77dCa2C955b15e9dE4dbBCf1246B4B85b651e50e',
  [ChainId.POLYGON_MUMBAI]: '0x77dCa2C955b15e9dE4dbBCf1246B4B85b651e50e',
  [ChainId.CELO]: '0x77dCa2C955b15e9dE4dbBCf1246B4B85b651e50e',
  [ChainId.CELO_ALFAJORES]: '0x77dCa2C955b15e9dE4dbBCf1246B4B85b651e50e',
  [ChainId.GNOSIS]: '0x77dCa2C955b15e9dE4dbBCf1246B4B85b651e50e',
  [ChainId.MOONBEAM]: '0x77dCa2C955b15e9dE4dbBCf1246B4B85b651e50e',
  [ChainId.BNB]: '0x77dCa2C955b15e9dE4dbBCf1246B4B85b651e50e',
  [ChainId.AVALANCHE]: '0x77dCa2C955b15e9dE4dbBCf1246B4B85b651e50e',
  [ChainId.BASE_GOERLI]: '0x77dCa2C955b15e9dE4dbBCf1246B4B85b651e50e',
  [ChainId.BASE]: '0x77dCa2C955b15e9dE4dbBCf1246B4B85b651e50e',
  [ChainId.BLAST_SEPOLIA]: '0x15749DF5439d429b0154bc7F9BF69Ef250d29c32',
  [ChainId.BLAST]: '0x3af30CD87525Cf73587E5218e3d9DD3A1357dcAa',
}

export { MULTICALL_ABI, MULTICALL_NETWORKS }
