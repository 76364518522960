import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const PageWrapper = styled(Container)`
  flex: 1;
  justify-content: center;
  gap: 50px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    justify-content: space-between;
    padding-top: 64px;
  }
`

const TermsStyle = styled.div`
  max-width: 650px;
  margin: 0px auto 10rem auto;
  padding: 0 1rem;
  display: grid;
  font-size: 1.25rem;
`

const TermsTitle = styled.h1`
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 2.25rem;
  line-height: 2.5rem;
  font-weight: 400;
  text-align: center;
`

const TermDates = styled.h3`
  margin: 1em 0px 0.5em;
  font-weight: bold;
`

const TermParagraph = styled.p`
  text-align: justify;
  opacity: 0.94;
  margin-bottom: 1em;
`

const TermH2 = styled.h2`
  margin: 1em 0px 0.4em;
  font-weight: bold;
  font-size: 1.2em;
`

const TermList = styled.ul`
  text-align: justify;
  list-style-type: circle;
  display: inline-block;
  margin-left: -1em;
`

const TermLineIetm = styled.li`
  margin: 0.5em 0px 0px 2em;
`

export default function PrivacyPolicy() {
  return (
    <PageWrapper>
      <Container>
        <TermsStyle>
          <TermsTitle>Ring Labs Privacy Policy</TermsTitle>
          <TermDates>Last modified: February 20, 2024</TermDates>
          <p>
            This Privacy Policy (the “Policy”) explains how BOTBOT LTD. (“Ring Labs”, the “Company”, “we”, “us” or
            “our”) collects, uses, and shares data in connection with the Ring web app (https://ring.exchange),
            <a>https://ring.exchange</a> website and all of our other properties, products, and services (the
            “Services”). Your use of the Services is subject to this Policy as well as our Terms of Service.
          </p>
          <TermH2>High Level Summary</TermH2>
          <TermList>
            <TermLineIetm>
              Ring Labs is an incorporated company based in the United States that operates https://ring.exchange/ among
              other products and services. Ring Labs complies with American laws and regulations.
            </TermLineIetm>
            <TermLineIetm>
              Ring Protocol is a censorship-resistant set of smart contracts deployed across various Layer 1 and Layer 2
              chains. It is not governed by Ring Labs.
            </TermLineIetm>
            <TermLineIetm>
              Ring Labs does not collect and store personal data, such as first name, last name, street address, date of
              birth, email address, or IP address, in connection with your use of the Services.
            </TermLineIetm>
            <TermLineIetm>
              Ring Labs collects non-identifiable data, such as public on-chain data, and limited off-chain data like
              device type, browser version, etc. This is to help drive production vision, not track users.
            </TermLineIetm>
            <TermLineIetm>
              If you specifically sign up to receive emails from us, we will store your email address to allow us to
              send you those emails. You can unsubscribe at any time. We will not attempt to link your email address to
              your wallet address, IP address, or other personal data.
            </TermLineIetm>
            <TermLineIetm>
              Ring Labs continues to explore methods to further protect consumers&apos; privacy, such as opt-out
              prompts, migrating to privacy-centric tooling and deploying proxies to anonymize network traffic.
            </TermLineIetm>
            <TermLineIetm>Users are empowered to explore client-side privacy techniques and tools.</TermLineIetm>
            <TermLineIetm>Any material changes to privacy will be reflected in an updated privacy policy.</TermLineIetm>
          </TermList>

          <TermH2>Data We Collect</TermH2>
          <p>
            Privacy is central to everything we do at the Company. And we&apos;ve enshrined transparency as one of our
            Company values. Accordingly, we aspire to be transparent about what little data we do collect. We do not
            maintain user accounts and do not collect and store personal data, such as your name or internet protocol
            (“IP”) address. When you interact with the Services, we collect only:
          </p>
          <TermList>
            <TermLineIetm>
              <strong>Publicly-available blockchain data.</strong> When you connect your non-custodial blockchain wallet
              to the Services, we collect and log your publicly-available blockchain address to learn more about your
              use of the Services and to screen your wallet for any prior illicit activity. We screen your wallet using
              intelligence provided by leading blockchain analytics providers. Note that blockchain addresses are
              publicly-available data that are not created or assigned by us or any central party, and by themselves are
              not personally identifying.
            </TermLineIetm>
            <TermLineIetm>
              <strong>Information from localStorage and other tracking technologies.</strong> We and our third-party
              services providers may access and collect information from localStorage, mobile deviceID, cookies, web
              beacons, and other similar technologies to provide and personalize the Services and features of the
              Services for you across sessions. For example, we may use this information to remember tokens you import,
              star, or add to your shopping bag. We may also use this information to learn about your preferences, your
              use of the Services, and our interactions with you. Information we collect from these technologies may
              include things such as browser type, referring/exit pages, operating system, device or browser language,
              and other device information. We group and analyze these user journeys collectively, in the aggregate, to
              improve our product user experience.
            </TermLineIetm>
            <TermLineIetm>
              <strong>Information from other sources.</strong> We may receive information about your wallet address or
              transactions made through the Services from our service providers in order to comply with our legal
              obligations and prevent the use of our Services in connection with fraudulent or other illicit activities.
            </TermLineIetm>
            <TermLineIetm>
              <strong>Survey or usability information.</strong> We may receive information about your wallet address or
              transactions made through the Services from our service providers in order to comply with our legal
              obligations and prevent the use of our Services in connection with fraudulent or other illicit activities.
            </TermLineIetm>
            <TermLineIetm>
              <strong>Correspondence.</strong> We will receive any communications and information you provide directly
              to us via email, customer support, social media, or another support channel (such as Twitter or Discord),
              or when you participate in any surveys or questionnaires.
            </TermLineIetm>
            <TermLineIetm>
              <strong>Biographical information.</strong> If you apply for a job with us, we collect all information
              provided through our Jobs form, including name, email phone, work and immigration status, and any other
              resume, cover letter, or free form text you include.
            </TermLineIetm>
            <TermLineIetm>
              <strong>Information you specifically provide us.</strong> If you specifically provide us with information
              (such as your email address), we may use that information for the purposes described when you provide it
              to us. We will not attempt to link any information you provide to your wallet address, IP address, or
              other personal data. You do not need to provide us with any personal data to use the Services.
            </TermLineIetm>
          </TermList>

          <TermH2>How We Use Data</TermH2>
          <p>
            We use the data we collect in accordance with your instructions, including any applicable terms in our
            <a href="https://ring.exchange/#/privacy-policy"> Terms of Service</a>, and as required by law. We may also
            use data for the following purposes:
          </p>
          <TermList>
            <TermLineIetm>
              <strong>Providing the Services.</strong> We use the data we collect to provide, maintain, customize and
              improve our Services and features of our Services.
            </TermLineIetm>
            <TermLineIetm>
              <strong>Customer support.</strong> We may use information to provide customer support for and answer
              inquiries about the Services.
            </TermLineIetm>
            <TermLineIetm>
              <strong>Safety and security.</strong> We may use data to protect against, investigate, and stop
              fraudulent, unauthorized, or illegal activity. We may also use it to address security risks, solve
              potential security issues such as bugs, enforce our agreements, and protect our users and Company.
            </TermLineIetm>
            <TermLineIetm>
              <strong>Legal compliance.</strong> We may use the information we collect as needed or requested by
              regulators, government entities, and law enforcement to comply with applicable laws and regulations.
            </TermLineIetm>
            <TermLineIetm>
              <strong>Aggregated data.</strong> We may use some of the information we collect or access to compile
              aggregated data that helps us learn more about how users use the Services and where we can improve your
              experience.
            </TermLineIetm>
          </TermList>

          <TermH2>How We Share Data</TermH2>
          <p>We may share or disclose the data we collect:</p>
          <TermList>
            <TermLineIetm>
              <strong>With service providers.</strong> We may share your information with our service providers and
              vendors to assist us in providing, delivering, and improving the Services. For example, we may share your
              wallet address with service providers like Infura and Cloudflare to provide technical infrastructure
              services, your wallet address with blockchain analytics providers to detect, prevent, and mitigate
              financial crime and other illicit or harmful activities, and your activity on our social media pages with
              our analytics provider to learn more about you interact with us and the Services.
            </TermLineIetm>
            <TermLineIetm>
              <strong>To comply with our legal obligations.</strong> We may share your data in the course of litigation,
              regulatory proceedings, compliance measures, and when compelled by subpoena, court order, or other legal
              procedure. We may also share data when we believe it is necessary to prevent harm to our users, our
              Company, or others, and to enforce our agreements and policies, including our
              <a href="https://ring.exchange/#/privacy-policy"> Terms of Service</a>.
            </TermLineIetm>
            <TermLineIetm>
              <strong>Safety and Security.</strong> We may share data to protect against, investigate, and stop
              fraudulent, unauthorized, or illegal activity. We may also use it to address security risks, solve
              potential security issues such as bugs, enforce our agreements, and protect our users, Company, and
              ecosystem.
            </TermLineIetm>
            <TermLineIetm>
              <strong>Business changes.</strong> We may transfer or share data to another entity in the event of a
              merger, acquisition, bankruptcy, dissolution, reorganization, asset or stock sale, or other business
              transaction.
            </TermLineIetm>
            <TermLineIetm>
              <strong>With your consent. </strong> We may share your information any other time you provide us with your
              consent to do so.
            </TermLineIetm>
          </TermList>
          <p>We do not share your information with any third parties for any marketing purposes whatsoever.</p>

          <TermH2>Third Party Cookies</TermH2>
          <p>
            We use services provided by Google and other third parties that use tracking technology such as cookies,
            deviceID, and localStorage, to collect information about your use of the Services and our interactions with
            you. You can opt out of having your online activity and device data collected through these third-party
            services, including by:
          </p>
          <TermList>
            <TermLineIetm>
              Blocking cookies in your browser by following the instructions in your browser settings. For more
              information about cookies, including how to see the cookies on your device, manage them, and delete them,
              visit www.allaboutcookies.org.
            </TermLineIetm>
            <TermLineIetm>
              Blocking or limiting the use of your advertising ID on your mobile device through the device settings.
            </TermLineIetm>
            <TermLineIetm>
              Using privacy plug-ins or browsers. Certain browsers and browser extensions can be configured to block
              third-party cookies and trackers.
            </TermLineIetm>
            <TermLineIetm>
              Using the platform opt-out provided by Google at https://adssettings.google.com. You can learn more about
              how Google uses your information by reviewing Google’s privacy policy at
              https://policies.google.com/privacy.
            </TermLineIetm>
            <TermLineIetm>
              Using advertising industry opt-out tools on each device or browser where you use the Services, available
              at http://optout.aboutads.info and http://optout.networkadvertising.org.
            </TermLineIetm>
          </TermList>

          <TermH2>Third-Party Links and Sites</TermH2>
          <p>
            We may integrate technologies operated or controlled by other parties into parts of the Services. For
            example, the Services may include links that hyperlink to websites, platforms, and other services not
            operated or controlled by us. Please note that when you interact with these other parties, including when
            you leave the Site, those parties may independently collect information about you and solicit information
            from you. You can learn more about how those parties collect and use your data by consulting their privacy
            policies and other terms.
          </p>

          <TermH2>Security</TermH2>
          <p>
            We implement and maintain reasonable administrative, physical, and technical security safeguards to help
            protect data from loss, theft, misuse, unauthorized access, disclosure, alteration, and destruction.
            Nevertheless, transmission via the internet is not completely secure and we cannot guarantee the security of
            information about you. You are responsible for all of your activity on the Services, including the security
            of your blockchain network addresses, cryptocurrency wallets, and their cryptographic keys.
          </p>
          <TermH2>Age Requirements</TermH2>
          <p>
            The Services are intended for a general audience and are not directed at children. We do not knowingly
            receive personal information (as defined by the U.S. Children’s Privacy Protection Act, or “COPPA”) from
            children. If you believe we have received personal information about a child under the age of 18, please
            contact us at hello@ringprotocol.com .
          </p>
          <TermH2>Additional Notice to California Residents (“CCPA Notice”)</TermH2>
          <p>
            The California Consumer Privacy Act of 2018 (“CCPA”) requires certain businesses to provide a CCPA Notice to
            California residents to explain how we collect, use, and share their personal information, and the rights
            and choices we offer California residents regarding our handling of their information.
          </p>
          <TermList>
            <TermLineIetm>
              <strong>Privacy Practices.</strong> We do not “sell” personal information as defined under the CCPA.
              Please review the “Sharing and Disclosure of Information” section above for further details about the
              categories of parties with whom we share information.
            </TermLineIetm>
            <TermLineIetm>
              <strong>Privacy Rights.</strong> The CCPA gives individuals the right to request information about how we
              have collected, used, and shared your personal information. It also gives you the right to request a copy
              of any information we may maintain about you. You may also ask us to delete any personal information that
              we may have received about you. Please note that the CCPA limits these rights, for example, by prohibiting
              us from providing certain sensitive information in response to access requests and limiting the
              circumstances under which we must comply with a deletion request. We will respond to requests for
              information, access, and deletion only to the extent we are able to associate, with a reasonable effort,
              the information we maintain with the identifying details you provide in your request. If we deny the
              request, we will communicate the decision to you. You are entitled to exercise the rights described above
              free from discrimination.
            </TermLineIetm>
            <TermLineIetm>
              <strong>Submitting a Request.</strong> You can submit a request for information, access, or deletion to
              hello@ringprotocol.com.
            </TermLineIetm>
            <TermLineIetm>
              <strong>Identity Verification.</strong> The CCPA requires us to collect and verify the identity of any
              individual submitting a request to access or delete personal information before providing a substantive
              response.
            </TermLineIetm>
            <TermLineIetm>
              <strong>Authorized Agents.</strong> California residents can designate an “authorized agent” to submit
              requests on their behalf. We will require the authorized agent to have a written authorization confirming
              their authority.
            </TermLineIetm>
          </TermList>

          <TermH2>Disclosures for European Union Data Subjects</TermH2>
          <TermParagraph>
            We process personal data for the purposes described in the section titled “How We Use Data” above. Our bases
            for processing your data include: (i) you have given consent to the process to us or our service provides
            for one or more specific purposes; (ii) processing is necessary for the performance of a contract with you;
            (iii) processing is necessary for compliance with a legal obligation; and/or (iv) processing is necessary
            for the purposes of the legitimate interested pursued by us or a third party, and your interests and
            fundamental rights and freedoms do not override those interests.
          </TermParagraph>
          <TermParagraph>
            Your rights under the General Data Protection Regulations (“GDPR”) include the right to (i) request access
            and obtain a copy of your personal data, (ii) request rectification or erasure of your personal data, (iii)
            object to or restrict the processing of your personal data; and (iv) request portability of your personal
            data. Additionally, you may withdraw your consent to our collection at any time. Nevertheless, we cannot
            edit or delete information that is stored on a particular blockchain. Information such as your transaction
            data, blockchain wallet address, and assets held by your address that may be related to the data we collect
            is beyond our control.
          </TermParagraph>
          <TermParagraph>
            To exercise any of your rights under the GDPR, please contact us at hello@ringprotocol.com. We may require
            additional information from you to process your request. Please note that we may retain information as
            necessary to fulfill the purpose for which it was collected and may continue to do so even after a data
            subject request in accordance with our legitimate interests, including to comply with our legal obligations,
            resolves disputes, prevent fraud, and enforce our agreements.
          </TermParagraph>

          <TermH2>Changes to this Policy</TermH2>
          <p>
            If we make material changes to this Policy, we will notify you via the Services. Nevertheless, your
            continued use of the Services reflects your periodic review of this Policy and other Company terms, and
            indicates your consent to them.
          </p>

          <TermH2>Contact Us</TermH2>
          <p>
            If you have any questions about this Policy or how we collect, use, or share your information, please
            contact us at hello@ringprotocol.com.
          </p>
        </TermsStyle>
      </Container>
    </PageWrapper>
  )
}
