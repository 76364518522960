import { useLocation } from 'react-router-dom'

export function useIsRingXPage() {
  const { pathname } = useLocation()
  return pathname.startsWith('/ringx')
}

export function useIsSwapPage() {
  const { pathname } = useLocation()
  return pathname.startsWith('/swap')
}
