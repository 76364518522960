// a list of tokens by chain
import { Currency, Token } from 'eth-mainnet-few-sdk-core-2'
import { ChainId } from 'eth-mainnet-few-sdk-core-2'

import {
  ARB,
  BTC_BSC,
  BUSD_BSC,
  CEUR_CELO,
  CEUR_CELO_ALFAJORES,
  CMC02_CELO,
  CUSD_CELO,
  CUSD_CELO_ALFAJORES,
  DAI,
  DAI_ARBITRUM_ONE,
  DAI_AVALANCHE,
  DAI_BSC,
  DAI_OPTIMISM,
  DAI_POLYGON,
  ETH_BSC,
  FEW_DAI,
  FEW_RGB,
  FEW_RING,
  FEW_RNG,
  FEW_RNG_BLAST,
  FEW_RNG_BLAST_SEPOLIA,
  FEW_UNI,
  FEW_USDB,
  FEW_USDC,
  FEW_USDT,
  FEW_WBTC,
  FEW_WRAPPED_NATIVE_CURRENCY,
  nativeOnChain,
  OP,
  PORTAL_ETH_CELO,
  PORTAL_USDC_CELO,
  USDB_BLAST,
  USDC_ARBITRUM,
  USDC_ARBITRUM_GOERLI,
  USDC_AVALANCHE,
  USDC_BASE,
  USDC_BSC,
  USDC_MAINNET,
  USDC_OPTIMISM,
  USDC_POLYGON,
  USDT,
  USDT_ARBITRUM_ONE,
  USDT_AVALANCHE,
  USDT_BLAST_SEPOLIA,
  USDT_BSC,
  USDT_OPTIMISM,
  USDT_POLYGON,
  WBTC,
  WBTC_ARBITRUM_ONE,
  WBTC_OPTIMISM,
  WBTC_POLYGON,
  WETH_AVALANCHE,
  WETH_POLYGON,
  WETH_POLYGON_MUMBAI,
  WRAPPED_NATIVE_CURRENCY,
} from './tokens'

type ChainTokenList = {
  readonly [chainId: number]: Token[]
}

type ChainCurrencyList = {
  readonly [chainId: number]: Currency[]
}

const WRAPPED_NATIVE_CURRENCIES_ONLY: ChainTokenList = Object.fromEntries(
  Object.entries(WRAPPED_NATIVE_CURRENCY)
    .map(([key, value]) => [key, [value]])
    .filter(Boolean)
)

const FEW_WRAPPED_NATIVE_CURRENCIES_ONLY: ChainTokenList = Object.fromEntries(
  Object.entries(FEW_WRAPPED_NATIVE_CURRENCY)
    .map(([key, value]) => [key, [value]])
    .filter(Boolean)
)

/**
 * Shows up in the currency select for swap and add liquidity
 */
export const COMMON_BASES: ChainCurrencyList = {
  [ChainId.MAINNET]: [
    nativeOnChain(ChainId.MAINNET),
    DAI,
    USDC_MAINNET,
    USDT,
    WBTC,
    WRAPPED_NATIVE_CURRENCY[ChainId.MAINNET] as Token,
  ],
  [ChainId.GOERLI]: [nativeOnChain(ChainId.GOERLI), WRAPPED_NATIVE_CURRENCY[ChainId.GOERLI] as Token],
  [ChainId.SEPOLIA]: [nativeOnChain(ChainId.SEPOLIA), WRAPPED_NATIVE_CURRENCY[ChainId.SEPOLIA] as Token],
  [ChainId.BLAST_SEPOLIA]: [
    nativeOnChain(ChainId.BLAST_SEPOLIA),
    WRAPPED_NATIVE_CURRENCY[ChainId.BLAST_SEPOLIA] as Token,
    USDT_BLAST_SEPOLIA,
  ],
  [ChainId.BLAST]: [nativeOnChain(ChainId.BLAST), WRAPPED_NATIVE_CURRENCY[ChainId.BLAST] as Token, USDB_BLAST],
  [ChainId.ARBITRUM_ONE]: [
    nativeOnChain(ChainId.ARBITRUM_ONE),
    ARB,
    DAI_ARBITRUM_ONE,
    USDC_ARBITRUM,
    USDT_ARBITRUM_ONE,
    WBTC_ARBITRUM_ONE,
    WRAPPED_NATIVE_CURRENCY[ChainId.ARBITRUM_ONE] as Token,
  ],
  [ChainId.ARBITRUM_GOERLI]: [
    nativeOnChain(ChainId.ARBITRUM_GOERLI),
    WRAPPED_NATIVE_CURRENCY[ChainId.ARBITRUM_GOERLI] as Token,
    USDC_ARBITRUM_GOERLI,
  ],
  [ChainId.OPTIMISM]: [nativeOnChain(ChainId.OPTIMISM), OP, DAI_OPTIMISM, USDC_OPTIMISM, USDT_OPTIMISM, WBTC_OPTIMISM],
  [ChainId.OPTIMISM_GOERLI]: [nativeOnChain(ChainId.OPTIMISM_GOERLI)],
  [ChainId.BASE]: [nativeOnChain(ChainId.BASE), WRAPPED_NATIVE_CURRENCY[ChainId.BASE] as Token, USDC_BASE],
  [ChainId.POLYGON]: [
    nativeOnChain(ChainId.POLYGON),
    WETH_POLYGON,
    USDC_POLYGON,
    DAI_POLYGON,
    USDT_POLYGON,
    WBTC_POLYGON,
  ],
  [ChainId.POLYGON_MUMBAI]: [
    nativeOnChain(ChainId.POLYGON_MUMBAI),
    WRAPPED_NATIVE_CURRENCY[ChainId.POLYGON_MUMBAI] as Token,
    WETH_POLYGON_MUMBAI,
  ],

  [ChainId.CELO]: [nativeOnChain(ChainId.CELO), CEUR_CELO, CUSD_CELO, PORTAL_ETH_CELO, PORTAL_USDC_CELO, CMC02_CELO],
  [ChainId.CELO_ALFAJORES]: [nativeOnChain(ChainId.CELO_ALFAJORES), CUSD_CELO_ALFAJORES, CEUR_CELO_ALFAJORES],

  [ChainId.BNB]: [nativeOnChain(ChainId.BNB), DAI_BSC, USDC_BSC, USDT_BSC, ETH_BSC, BTC_BSC, BUSD_BSC],
  [ChainId.AVALANCHE]: [
    nativeOnChain(ChainId.AVALANCHE),
    DAI_AVALANCHE,
    USDC_AVALANCHE,
    USDT_AVALANCHE,
    WETH_AVALANCHE,
  ],
}

export const FEW_SWAP_COMMON_BASES: ChainCurrencyList = {
  [ChainId.GOERLI]: [nativeOnChain(ChainId.GOERLI), WRAPPED_NATIVE_CURRENCY[ChainId.GOERLI] as Token],
  [ChainId.BLAST_SEPOLIA]: [
    nativeOnChain(ChainId.BLAST_SEPOLIA),
    WRAPPED_NATIVE_CURRENCY[ChainId.BLAST_SEPOLIA] as Token,
  ],
  [ChainId.BLAST]: [nativeOnChain(ChainId.BLAST), WRAPPED_NATIVE_CURRENCY[ChainId.BLAST] as Token],
}

export const RING_SWAP_COMMON_BASES: ChainCurrencyList = {
  [ChainId.GOERLI]: [nativeOnChain(ChainId.GOERLI), WRAPPED_NATIVE_CURRENCY[ChainId.GOERLI] as Token],
  [ChainId.BLAST_SEPOLIA]: [
    nativeOnChain(ChainId.BLAST_SEPOLIA),
    WRAPPED_NATIVE_CURRENCY[ChainId.BLAST_SEPOLIA] as Token,
  ],
  [ChainId.BLAST]: [nativeOnChain(ChainId.BLAST), WRAPPED_NATIVE_CURRENCY[ChainId.BLAST] as Token],
}

// used to construct the list of all pairs we consider by default in the frontend
// eslint-disable-next-line import/no-unused-modules
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  ...WRAPPED_NATIVE_CURRENCIES_ONLY,
  [ChainId.MAINNET]: [...WRAPPED_NATIVE_CURRENCIES_ONLY[ChainId.MAINNET], DAI, USDC_MAINNET, USDT, WBTC],
  [ChainId.BNB]: [
    ...WRAPPED_NATIVE_CURRENCIES_ONLY[ChainId.BNB],
    DAI_BSC,
    USDC_BSC,
    USDT_BSC,
    BTC_BSC,
    BUSD_BSC,
    ETH_BSC,
  ],
  [ChainId.AVALANCHE]: [
    ...WRAPPED_NATIVE_CURRENCIES_ONLY[ChainId.AVALANCHE],
    DAI_AVALANCHE,
    USDC_AVALANCHE,
    USDT_AVALANCHE,
    WETH_AVALANCHE,
  ],
  [ChainId.GOERLI]: [...WRAPPED_NATIVE_CURRENCIES_ONLY[ChainId.GOERLI], FEW_UNI[ChainId.GOERLI]],
  [ChainId.BLAST_SEPOLIA]: [...WRAPPED_NATIVE_CURRENCIES_ONLY[ChainId.BLAST_SEPOLIA], FEW_RNG_BLAST_SEPOLIA],
  [ChainId.BLAST]: [...WRAPPED_NATIVE_CURRENCIES_ONLY[ChainId.BLAST], FEW_RNG_BLAST],
}

// used to construct the list of all few pairs we consider by default in the frontend
export const FEW_BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  ...FEW_WRAPPED_NATIVE_CURRENCIES_ONLY,
  [ChainId.BLAST_SEPOLIA]: [
    ...FEW_WRAPPED_NATIVE_CURRENCIES_ONLY[ChainId.BLAST_SEPOLIA],
    FEW_DAI[ChainId.BLAST_SEPOLIA],
    FEW_USDC[ChainId.BLAST_SEPOLIA],
    FEW_USDT[ChainId.BLAST_SEPOLIA],
    FEW_RNG[ChainId.BLAST_SEPOLIA],
    FEW_RGB[ChainId.BLAST_SEPOLIA],
    FEW_RING[ChainId.BLAST_SEPOLIA],
  ],
  [ChainId.BLAST]: [
    ...FEW_WRAPPED_NATIVE_CURRENCIES_ONLY[ChainId.BLAST],
    FEW_USDB[ChainId.BLAST],
    FEW_RNG[ChainId.BLAST],
    FEW_RING[ChainId.BLAST],
    FEW_WBTC[ChainId.BLAST],
  ],
  [ChainId.SEPOLIA]: [
    ...FEW_WRAPPED_NATIVE_CURRENCIES_ONLY[ChainId.SEPOLIA],
    FEW_DAI[ChainId.SEPOLIA],
    FEW_USDC[ChainId.SEPOLIA],
    FEW_USDT[ChainId.SEPOLIA],
    FEW_RNG[ChainId.SEPOLIA],
    FEW_RING[ChainId.SEPOLIA],
  ],
}

// eslint-disable-next-line import/no-unused-modules
export const PINNED_PAIRS: { readonly [chainId: number]: [Token, Token][] } = {
  [ChainId.MAINNET]: [
    [
      new Token(ChainId.MAINNET, '0x5d3a536E4D6DbD6114cc1Ead35777bAB948E3643', 8, 'cDAI', 'Compound Dai'),
      new Token(ChainId.MAINNET, '0x39AA39c021dfbaE8faC545936693aC917d5E7563', 8, 'cUSDC', 'Compound USD Coin'),
    ],
    [USDC_MAINNET, USDT],
    [DAI, USDT],
  ],
}

export const FEW_WRAPPED_PINNED_PAIRS: { readonly [chainId: number]: [Token, Token][] } = {
  [ChainId.BLAST_SEPOLIA]: [
    [FEW_USDC[ChainId.BLAST_SEPOLIA], FEW_WRAPPED_NATIVE_CURRENCY[ChainId.BLAST_SEPOLIA]],
    [FEW_USDT[ChainId.BLAST_SEPOLIA], FEW_WRAPPED_NATIVE_CURRENCY[ChainId.BLAST_SEPOLIA]],
    [FEW_DAI[ChainId.BLAST_SEPOLIA], FEW_WRAPPED_NATIVE_CURRENCY[ChainId.BLAST_SEPOLIA]],
    [FEW_RGB[ChainId.BLAST_SEPOLIA], FEW_WRAPPED_NATIVE_CURRENCY[ChainId.BLAST_SEPOLIA]],
    [FEW_RING[ChainId.BLAST_SEPOLIA], FEW_WRAPPED_NATIVE_CURRENCY[ChainId.BLAST_SEPOLIA]],
  ],
  [ChainId.BLAST]: [
    [FEW_USDB[ChainId.BLAST], FEW_WRAPPED_NATIVE_CURRENCY[ChainId.BLAST]],
    [FEW_RING[ChainId.BLAST], FEW_WRAPPED_NATIVE_CURRENCY[ChainId.BLAST]],
    [FEW_WBTC[ChainId.BLAST], FEW_WRAPPED_NATIVE_CURRENCY[ChainId.BLAST]],
  ],
  [ChainId.SEPOLIA]: [
    [FEW_USDC[ChainId.SEPOLIA], FEW_WRAPPED_NATIVE_CURRENCY[ChainId.SEPOLIA]],
    [FEW_USDT[ChainId.SEPOLIA], FEW_WRAPPED_NATIVE_CURRENCY[ChainId.SEPOLIA]],
    [FEW_DAI[ChainId.SEPOLIA], FEW_WRAPPED_NATIVE_CURRENCY[ChainId.SEPOLIA]],
    [FEW_RING[ChainId.SEPOLIA], FEW_WRAPPED_NATIVE_CURRENCY[ChainId.SEPOLIA]],
  ],
}

export const RING_WRAPPED_PINNED_PAIRS: { readonly [chainId: number]: [Token, Token][] } = {
  [ChainId.BLAST_SEPOLIA]: [
    [FEW_USDC[ChainId.BLAST_SEPOLIA], FEW_RNG[ChainId.BLAST_SEPOLIA]],
    [FEW_USDT[ChainId.BLAST_SEPOLIA], FEW_RNG[ChainId.BLAST_SEPOLIA]],
    [FEW_DAI[ChainId.BLAST_SEPOLIA], FEW_RNG[ChainId.BLAST_SEPOLIA]],
    [FEW_WRAPPED_NATIVE_CURRENCY[ChainId.BLAST_SEPOLIA], FEW_RNG[ChainId.BLAST_SEPOLIA]],
  ],
  [ChainId.BLAST]: [
    [FEW_USDB[ChainId.BLAST], FEW_RNG[ChainId.BLAST]],
    [FEW_WRAPPED_NATIVE_CURRENCY[ChainId.BLAST], FEW_RNG[ChainId.BLAST]],
    [FEW_WRAPPED_NATIVE_CURRENCY[ChainId.BLAST], FEW_WBTC[ChainId.BLAST]],
  ],
  [ChainId.SEPOLIA]: [
    [FEW_USDC[ChainId.SEPOLIA], FEW_RNG[ChainId.SEPOLIA]],
    [FEW_USDT[ChainId.SEPOLIA], FEW_RNG[ChainId.SEPOLIA]],
    [FEW_DAI[ChainId.SEPOLIA], FEW_RNG[ChainId.SEPOLIA]],
    [FEW_WRAPPED_NATIVE_CURRENCY[ChainId.SEPOLIA], FEW_RNG[ChainId.SEPOLIA]],
  ],
}
