import { InterfaceTrade, QuoteMethod } from 'state/routing/types'
import { isUniswapXTrade } from 'state/routing/utils'
import { ThemedText } from 'theme'

import RingXRouterLabel from './RingXRouterLabel'
import UniswapXRouterLabel from './UniswapXRouterLabel'

export default function RouterLabel({ trade }: { trade: InterfaceTrade }) {
  if (isUniswapXTrade(trade)) {
    return (
      <UniswapXRouterLabel>
        <ThemedText.BodySmall>Uniswap X</ThemedText.BodySmall>
      </UniswapXRouterLabel>
    )
  }
  if (trade.quoteMethod === QuoteMethod.CLIENT_SIDE_FALLBACK) {
    return <ThemedText.BodySmall>RingSwap Client</ThemedText.BodySmall>
  }
  if (trade.quoteMethod === QuoteMethod.RING_X) {
    return (
      <RingXRouterLabel>
        <ThemedText.BodySmall>RingX</ThemedText.BodySmall>
      </RingXRouterLabel>
    )
  }

  return <ThemedText.BodySmall>RingSwap API</ThemedText.BodySmall>
}
