import { Trans } from '@lingui/macro'
import { BrowserEvent, InterfaceElementName, InterfacePageName, SharedEventName } from '@uniswap/analytics-events'
import { Trace, TraceEvent } from 'analytics'
import chiliIcon from 'assets/images/partner_chili.png'
import continueIcon from 'assets/images/partner_continue.png'
import manifoldIcon from 'assets/images/partner_manifold.png'
import maxIcon from 'assets/images/partner_max.png'
import synergisIcon from 'assets/images/partner_synergis.png'
import { AboutFooter } from 'components/About/AboutFooter'
import Card from 'components/About/Card'
import { MAIN_CARDS } from 'components/About/constants'
import ProtocolBanner from 'components/About/ProtocolBanner'
import { useAccountDrawer } from 'components/AccountDrawer'
import { BaseButton } from 'components/Button'
// import { RedirectPathToSwapOnly } from 'pages/Swap/redirects'
// import { parse } from 'qs'
import { useEffect, useRef } from 'react'
import { ArrowDownCircle } from 'react-feather'
import { useNavigate } from 'react-router-dom'
import { Link as NativeLink } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { BREAKPOINTS } from 'theme'
import { useIsDarkMode } from 'theme/components/ThemeToggle'
import { TRANSITION_DURATIONS } from 'theme/styles'
import { Z_INDEX } from 'theme/zIndex'

const PageContainer = styled.div`
  position: absolute;
  top: 0;
  padding: ${({ theme }) => theme.navHeight}px 0px 0px 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  scroll-behavior: smooth;
  overflow-x: hidden;
`

const Gradient = styled.div<{ isDarkMode: boolean }>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  width: 100%;
  min-height: 550px;
  ${({ isDarkMode }) =>
    isDarkMode
      ? css`
          background: linear-gradient(rgba(8, 10, 24, 0) 0%, rgb(8 10 24 / 100%) 45%);
        `
      : css`
          background: linear-gradient(rgba(255, 255, 255, 0) 0%, rgb(255 255 255 /100%) 45%);
        `};
  z-index: ${Z_INDEX.under_dropdown};
  pointer-events: none;
  height: ${({ theme }) => `calc(100vh - ${theme.mobileBottomBarHeight}px)`};
  @media screen and (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    height: 100vh;
  }
`

// const GlowContainer = styled.div`
//   position: absolute;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   top: 0;
//   bottom: 0;
//   width: 100%;
//   overflow-y: hidden;
//   height: ${({ theme }) => `calc(100vh - ${theme.mobileBottomBarHeight}px)`};
//   @media screen and (min-width: ${({ theme }) => theme.breakpoint.md}px) {
//     height: 100vh;
//   }
// `

// const Glow = styled.div`
//   position: absolute;
//   top: 68px;
//   bottom: 0;
//   background: radial-gradient(72.04% 72.04% at 50% 3.99%, #ff37eb 0%, rgba(166, 151, 255, 0) 100%);
//   filter: blur(72px);
//   border-radius: 24px;
//   max-width: 480px;
//   width: 100%;
//   height: 100%;
// `

const ContentContainer = styled.div<{ isDarkMode: boolean }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 0 0 40px;
  max-width: min(720px, 90%);
  min-height: 535px;
  z-index: ${Z_INDEX.under_dropdown};
  transition: ${({ theme }) => `${theme.transition.duration.medium} ${theme.transition.timing.ease} opacity`};
  height: ${({ theme }) => `calc(100vh - ${theme.navHeight + theme.mobileBottomBarHeight}px)`};
  pointer-events: none;
  * {
    pointer-events: auto;
  }
`

const TitleText = styled.h1<{ isDarkMode: boolean }>`
  color: transparent;
  font-size: 36px;
  line-height: 44px;
  font-weight: 535;
  text-align: center;
  margin: 0 0 24px;
  ${({ isDarkMode }) =>
    isDarkMode
      ? css`
          background: radial-gradient(101.8% 4091.31% at 0% 0%, #4673fa 0%, #9646fa 100%);
        `
      : css`
          background: radial-gradient(101.8% 4091.31% at 0% 0%, #4673fa 0%, #9646fa 100%);
        `};
  background-clip: text;
  -webkit-background-clip: text;

  @media screen and (min-width: ${BREAKPOINTS.sm}px) {
    font-size: 48px;
    line-height: 56px;
  }

  @media screen and (min-width: ${BREAKPOINTS.md}px) {
    font-size: 64px;
    line-height: 72px;
  }
`

const SubText = styled.div`
  color: ${({ theme }) => theme.neutral2};
  font-size: 16px;
  line-height: 24px;
  font-weight: 535;
  text-align: center;
  max-width: 500px;
  margin: 0 0 32px;

  @media screen and (min-width: ${BREAKPOINTS.md}px) {
    font-size: 20px;
    line-height: 28px;
  }
`

const SubTextContainer = styled.div`
  display: flex;
  justify-content: center;
`

const LogoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1440px;
`

const SubLogoContainer = styled.div`
  margin: 0 40px 32px 0px;
`

const LandingButton = styled(BaseButton)`
  padding: 16px 0px;
  border-radius: 24px;
`

const ButtonCTA = styled(LandingButton)`
  background: radial-gradient(101.8% 4091.31% at 0% 0%, #4673fa 0%, #9646fa 100%);
  border: none;
  color: ${({ theme }) => theme.white};
  transition: ${({ theme }) => `all ${theme.transition.duration.medium} ${theme.transition.timing.ease}`};

  &:hover {
    box-shadow: 0px 0px 16px 0px #ff00c7;
  }
`

const ButtonCTAText = styled.p`
  margin: 0px;
  font-size: 16px;
  font-weight: 535;
  white-space: nowrap;

  @media screen and (min-width: ${BREAKPOINTS.sm}px) {
    font-size: 20px;
  }
`

const ActionsContainer = styled.span`
  max-width: 300px;
  width: 100%;
  pointer-events: auto;
`

const LearnMoreContainer = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.neutral3};
  cursor: pointer;
  font-size: 20px;
  font-weight: 535;
  margin: 36px 0;
  display: flex;
  visibility: hidden;
  pointer-events: auto;
  @media screen and (min-width: ${BREAKPOINTS.sm}px) {
    visibility: visible;
  }

  transition: ${({ theme }) => `${theme.transition.duration.medium} ${theme.transition.timing.ease} opacity`};

  &:hover {
    opacity: 0.6;
  }
`

const LearnMoreArrow = styled(ArrowDownCircle)`
  margin-left: 14px;
  size: 20px;
`

const AboutContentContainer = styled.div<{ isDarkMode: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 24px 5rem;
  width: 100%;
  ${({ isDarkMode }) =>
    isDarkMode
      ? css`
          background: linear-gradient(179.82deg, rgba(0, 0, 0, 0) 0.16%, #050026 99.85%);
        `
      : css`
          background: linear-gradient(179.82deg, rgba(255, 255, 255, 0) 0.16%, #eaeaea 99.85%);
        `};
  @media screen and (min-width: ${BREAKPOINTS.md}px) {
    padding: 0 96px 5rem;
  }
`

const CardGrid = styled.div<{ cols: number }>`
  display: grid;
  gap: 12px;
  width: 100%;
  padding: 24px 0 0;
  max-width: 1440px;
  scroll-margin: ${({ theme }) => `${theme.navHeight}px 0 0`};

  grid-template-columns: 1fr;
  @media screen and (min-width: ${BREAKPOINTS.sm}px) {
    // At this screen size, we show up to 2 columns.
    grid-template-columns: ${({ cols }) =>
      Array.from(Array(cols === 2 ? 2 : 1))
        .map(() => '1fr')
        .join(' ')};
    gap: 32px;
  }

  @media screen and (min-width: ${BREAKPOINTS.lg}px) {
    // at this screen size, always show the max number of columns
    grid-template-columns: ${({ cols }) =>
      Array.from(Array(cols))
        .map(() => '1fr')
        .join(' ')};
    gap: 32px;
  }
`

const LandingSwapContainer = styled.div`
  height: ${({ theme }) => `calc(100vh - ${theme.mobileBottomBarHeight}px)`};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
`

// const SwapCss = css`
//   * {
//     pointer-events: none;
//   }

//   &:hover {
//     transform: translateY(-4px);
//     transition: ${({ theme }) => `transform ${theme.transition.duration.medium} ${theme.transition.timing.ease}`};
//   }
// `

const LinkCss = css`
  text-decoration: none;
  max-width: 480px;
  width: 100%;
`

// const LandingSwap = styled(Swap)`
//   ${SwapCss}
//   &:hover {
//     border: 1px solid ${({ theme }) => theme.accent1};
//   }
// `

const Link = styled(NativeLink)`
  ${LinkCss}
`

export default function Landing() {
  const isDarkMode = useIsDarkMode()
  const cardsRef = useRef<HTMLDivElement>(null)
  // const selectedWallet = useAppSelector((state) => state.user.selectedWallet)
  const cards = MAIN_CARDS

  const [accountDrawerOpen] = useAccountDrawer()
  const navigate = useNavigate()
  useEffect(() => {
    if (accountDrawerOpen) {
      setTimeout(() => {
        navigate('/swap')
      }, TRANSITION_DURATIONS.fast)
    }
  }, [accountDrawerOpen, navigate])

  // const queryParams = parse(useLocation().search, { ignoreQueryPrefix: true })
  // if (selectedWallet && !queryParams.intro) {
  //   return <RedirectPathToSwapOnly />
  // }

  return (
    <Trace page={InterfacePageName.LANDING_PAGE} shouldLogImpression>
      <PageContainer data-testid="landing-page">
        <LandingSwapContainer>
          {/* <TraceEvent
            events={[BrowserEvent.onClick]}
            name={SharedEventName.ELEMENT_CLICKED}
            element={InterfaceElementName.LANDING_PAGE_SWAP_ELEMENT}
          >
            <Link to="/swap">
              <LandingSwap />
            </Link>
          </TraceEvent> */}
        </LandingSwapContainer>
        <Gradient isDarkMode={isDarkMode} />
        {/* <GlowContainer>
          <Glow />
        </GlowContainer> */}
        <ContentContainer isDarkMode={isDarkMode}>
          <TitleText isDarkMode={isDarkMode}>
            <Trans>Ring Exchange</Trans>
          </TitleText>
          <SubTextContainer>
            <SubText>
              <Trans>
                Build on Blast. A New Era in Decentralized Exchange Revolutionizing Asset Utilization on Blast.
              </Trans>
            </SubText>
          </SubTextContainer>
          <SubTextContainer>
            <SubText>
              <Trans>BACKED BY</Trans>
            </SubText>
          </SubTextContainer>
          <LogoContainer>
            <SubLogoContainer>
              <a href="https://twitter.com/ManifoldTrading" target="_blank" rel="noreferrer">
                <img src={manifoldIcon} style={{ width: '100%', height: '100$' }} alt="" />
              </a>
            </SubLogoContainer>
            <SubLogoContainer>
              <a href="https://twitter.com/ContinueFund" target="_blank" rel="noreferrer">
                <img src={continueIcon} style={{ width: '100%', height: '100$' }} alt="" />
              </a>
            </SubLogoContainer>
            <SubLogoContainer>
              <a href="https://twitter.com/Synergiscap" target="_blank" rel="noreferrer">
                <img src={synergisIcon} style={{ width: '100%', height: '100$' }} alt="" />
              </a>
            </SubLogoContainer>
            <SubLogoContainer>
              <a href="https://twitter.com/mox_capital" target="_blank" rel="noreferrer">
                <img src={maxIcon} style={{ width: '100%', height: '100$' }} alt="" />
              </a>
            </SubLogoContainer>
            <SubLogoContainer>
              <a href="https://twitter.com/boredchili" target="_blank" rel="noreferrer">
                <img src={chiliIcon} style={{ width: '100%', height: '100$' }} alt="" />
              </a>
            </SubLogoContainer>
          </LogoContainer>

          <ActionsContainer>
            <TraceEvent
              events={[BrowserEvent.onClick]}
              name={SharedEventName.ELEMENT_CLICKED}
              element={InterfaceElementName.CONTINUE_BUTTON}
            >
              <ButtonCTA as={Link} to="/swap">
                <ButtonCTAText>
                  <Trans>Get started</Trans>
                </ButtonCTAText>
              </ButtonCTA>
            </TraceEvent>
          </ActionsContainer>
          <LearnMoreContainer
            onClick={() => {
              cardsRef?.current?.scrollIntoView({ behavior: 'smooth' })
            }}
          >
            <Trans>Learn more</Trans>
            <LearnMoreArrow />
          </LearnMoreContainer>
        </ContentContainer>
        <AboutContentContainer isDarkMode={isDarkMode}>
          <CardGrid cols={cards.length} ref={cardsRef}>
            {cards.map(({ darkIcon, lightIcon, ...card }) => (
              <Card {...card} icon={isDarkMode ? darkIcon : lightIcon} key={card.title} />
            ))}
          </CardGrid>
          <ProtocolBanner />
          <AboutFooter />
        </AboutContentContainer>
      </PageContainer>
    </Trace>
  )
}

// const DownloadWalletLink = styled.a`
//   display: inline-flex;
//   gap: 8px;
//   margin-top: 24px;
//   color: ${({ theme }) => theme.neutral2};
//   text-decoration: none;
//   font-size: 16px;
//   line-height: 24px;
//   font-weight: 535;
//   text-align: center;

//   :hover {
//     color: ${({ theme }) => theme.neutral3};
//   }
// `
