import { Trans } from '@lingui/macro'
import { useWeb3React } from '@web3-react/core'
import { AnimateNumber } from 'components/AnimateNumber'
import Row, { RowBetween } from 'components/Row'
import { MinerIcon } from 'components/Settings/MinerButton'
import { SWAP_INCENTIVE_ADDRESS, WRAPPED_NATIVE_CURRENCY } from 'constants/tokens'
import { ChainId, CurrencyAmount, Token } from 'eth-mainnet-few-sdk-core-2'
import { useSwapIncentiveContract } from 'hooks/useContract'
import JSBI from 'jsbi'
import { useSingleCallResult } from 'lib/hooks/multicall'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { ThemedText } from 'theme'
import { fetchPointData, PointData } from 'utils/fetchPoint'

import { ReactComponent as BlastPoint } from '../../assets/svg/blast_point.svg'
import { ReactComponent as GoldPoint } from '../../assets/svg/gold_point.svg'
import { formatTvl } from './index'

export default function Point() {
  const [data, setData] = useState<PointData | undefined>(undefined)
  const { chainId, account } = useWeb3React()
  const swapIncentiveContract = useSwapIncentiveContract(SWAP_INCENTIVE_ADDRESS[chainId ?? ChainId.BLAST])

  const { loading: tradeRewardLoading, result: tradeRewardResult } = useSingleCallResult(
    swapIncentiveContract,
    'getUserReward',
    [account]
  )

  const tradeReward = useMemo(() => {
    if (tradeRewardLoading) {
      return 0
    } else {
      const currencyAmount: CurrencyAmount<Token> = CurrencyAmount.fromRawAmount(
        WRAPPED_NATIVE_CURRENCY[chainId ?? ChainId.BLAST] as Token,
        JSBI.BigInt(tradeRewardResult?.[0] ?? 0)
      )
      return JSBI.divide(currencyAmount.quotient, JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(18))).toString()
    }
  }, [chainId, tradeRewardLoading, tradeRewardResult])

  const getData = useCallback(async () => {
    const { data, error } = await fetchPointData()
    if (error) {
      console.log(error)
    }
    setData(data)
  }, [])

  useEffect(() => {
    getData()
    const intervalId = setInterval(async () => {
      getData()
    }, 12000)

    return () => {
      clearInterval(intervalId)
    }
  }, [getData])

  return (
    <>
      <RowBetween padding="4px 0">
        <ThemedText.DeprecatedWhite>
          <Trans>Total Blast Point</Trans>
        </ThemedText.DeprecatedWhite>
        <ThemedText.DeprecatedWhite>
          <Row gap="sm">
            {formatTvl(data ? Number(data.pointsAccrued.toFixed(0)) : 0)}
            <BlastPoint width={20} height={20} />
          </Row>
        </ThemedText.DeprecatedWhite>
      </RowBetween>
      <RowBetween padding="4px 0">
        <ThemedText.DeprecatedWhite>
          <Trans>Total Gold Point</Trans>
        </ThemedText.DeprecatedWhite>
        <ThemedText.DeprecatedWhite>
          <Row gap="sm">
            {formatTvl(data ? Number(data.goldAccrued.toFixed(0)) : 0)}
            <GoldPoint width={20} height={20} />
          </Row>
        </ThemedText.DeprecatedWhite>
      </RowBetween>
      {account && (
        <RowBetween padding="4px 0">
          <ThemedText.DeprecatedWhite>
            <Trans>Your Trading Rewards</Trans>
          </ThemedText.DeprecatedWhite>
          <ThemedText.DeprecatedWhite>
            <Row gap="sm">
              <AnimateNumber number={data ? Number(tradeReward) : 0} />
              <MinerIcon width={20} height={20} />
            </Row>
          </ThemedText.DeprecatedWhite>
        </RowBetween>
      )}
    </>
  )
}
