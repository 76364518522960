import type { TransactionResponse } from '@ethersproject/providers'
import { Trans } from '@lingui/macro'
import { useWeb3React } from '@web3-react/core'
import FixedStakingRewardsJSON from 'abis/FixedStakingRewards.json'
import { RING, RING_CLAIMER_ADDRESS } from 'constants/tokens'
import { ChainId, Currency, CurrencyAmount } from 'eth-mainnet-few-sdk-core-2'
import JSBI from 'jsbi'
import { ReactNode, useState } from 'react'
import styled from 'styled-components'
import { calculateGasMargin } from 'utils/calculateGasMargin'

import { useClaimerContract, useContract } from '../../../hooks/useContract'
import { StakingInfo, StakingTokenInfo } from '../../../state/stake/hooks'
import { useTransactionAdder } from '../../../state/transactions/hooks'
import { TransactionType } from '../../../state/transactions/types'
import { CloseIcon, ThemedText } from '../../../theme'
import { ButtonError } from '../../Button'
import { AutoColumn } from '../../Column'
import Modal from '../../Modal'
import { LoadingView, SubmittedView } from '../../ModalViews'
import { RowBetween } from '../../Row'

const { abi: FEW_STAKING_REWARDS_ABI } = FixedStakingRewardsJSON

function useFewStakingContract(stakingAddress?: string, withSignerIfPossible?: boolean) {
  return useContract(stakingAddress, FEW_STAKING_REWARDS_ABI, withSignerIfPossible)
}

const ContentWrapper = styled(AutoColumn)`
  width: 100%;
  padding: 1rem;
`

interface StakingModalProps {
  isOpen: boolean
  onDismiss: () => void
  stakingInfo: StakingInfo | StakingTokenInfo
  claimAmount: CurrencyAmount<Currency>
}

export default function ClaimRewardModal({ isOpen, onDismiss, stakingInfo, claimAmount }: StakingModalProps) {
  const { account, chainId } = useWeb3React()

  // monitor call to help UI loading state
  const addTransaction = useTransactionAdder()
  const [hash, setHash] = useState<string | undefined>()
  const [attempting, setAttempting] = useState(false)

  function wrappedOnDismiss() {
    setHash(undefined)
    setAttempting(false)
    onDismiss()
  }

  const stakingContract = useFewStakingContract(stakingInfo.stakingRewardAddress)
  const claimerContract = useClaimerContract(RING_CLAIMER_ADDRESS[chainId ?? ChainId.BLAST])

  async function onGetReward() {
    if (stakingContract && stakingInfo?.stakedAmount && account) {
      setAttempting(true)
      await stakingContract
        .getReward(stakingInfo.index, { gasLimit: 350000 })
        .then((response: TransactionResponse) => {
          addTransaction(response, {
            type: TransactionType.RING_CLAIM,
            recipient: account,
            tokenAddress: RING[chainId ?? ChainId.BLAST].address,
            amount: claimAmount.divide(JSBI.BigInt(10)).toExact(),
          })
          setHash(response.hash)
        })
        .catch((error: any) => {
          setAttempting(false)
          console.log(error)
        })
    }
  }

  async function onClaimReward() {
    if (claimerContract && stakingInfo?.stakedAmount && account) {
      const estimate = claimerContract.estimateGas.claim
      const method: (...args: any) => Promise<TransactionResponse> = claimerContract.claim
      const args = [stakingInfo.index]

      setAttempting(true)
      await estimate(...args, {})
        .then((estimatedGasLimit) =>
          method(...args, {
            ...{},
            gasLimit: calculateGasMargin(estimatedGasLimit),
          }).then((response) => {
            setAttempting(false)

            addTransaction(response, {
              type: TransactionType.RING_CLAIM,
              recipient: account,
              tokenAddress: RING[chainId ?? ChainId.BLAST].address,
              amount: claimAmount.divide(JSBI.BigInt(10)).toExact(),
            })

            setHash(response.hash)
          })
        )
        .catch((error: any) => {
          setAttempting(false)
          // we only care if the error is something _other_ than the user rejected the tx
          if (error?.code !== 4001) {
            console.error(error)
          }
        })
    }
  }

  let error: ReactNode | undefined
  if (!account) {
    error = <Trans>Connect Wallet</Trans>
  }
  if (!stakingInfo?.stakedAmount) {
    error = error ?? <Trans>Enter an amount</Trans>
  }

  const governanceToken = RING[chainId ?? ChainId.BLAST]

  return (
    <Modal isOpen={isOpen} onDismiss={wrappedOnDismiss} maxHeight={90}>
      {!attempting && !hash && (
        <ContentWrapper gap="lg">
          <RowBetween>
            <ThemedText.DeprecatedMediumHeader>
              <Trans>Claim</Trans>
            </ThemedText.DeprecatedMediumHeader>
            <CloseIcon onClick={wrappedOnDismiss} />
          </RowBetween>
          {stakingInfo?.earnedAmount && stakingInfo?.claimedAmount && (
            <AutoColumn justify="center" gap="md">
              <ThemedText.HeadlineLarge>
                {stakingInfo?.earnedAmount
                  .subtract(stakingInfo?.claimedAmount.wrapped)
                  ?.divide(JSBI.BigInt(10))
                  .toSignificant(6)}
              </ThemedText.HeadlineLarge>
              <ThemedText.DeprecatedBody>
                <Trans>Unclaimed {governanceToken.symbol}</Trans>
              </ThemedText.DeprecatedBody>
            </AutoColumn>
          )}
          <ThemedText.DeprecatedSubHeader style={{ textAlign: 'center' }}>
            <Trans>When you claim point tokens, it will automatically convert them into ring tokens.</Trans>
          </ThemedText.DeprecatedSubHeader>
          <ButtonError
            disabled={!!error}
            error={!!error && !!stakingInfo?.stakedAmount}
            onClick={chainId === ChainId.BLAST_SEPOLIA ? onGetReward : onClaimReward}
          >
            {error ?? <Trans>Claim</Trans>}
          </ButtonError>
        </ContentWrapper>
      )}
      {attempting && !hash && (
        <LoadingView onDismiss={wrappedOnDismiss}>
          <AutoColumn gap="md" justify="center">
            <ThemedText.DeprecatedBody fontSize={20}>
              <Trans>
                Claiming{' '}
                {stakingInfo?.earnedAmount
                  .subtract(stakingInfo?.claimedAmount.wrapped)
                  ?.divide(JSBI.BigInt(10))
                  .toSignificant(6)}{' '}
                {governanceToken.symbol}
              </Trans>
            </ThemedText.DeprecatedBody>
          </AutoColumn>
        </LoadingView>
      )}
      {hash && (
        <SubmittedView onDismiss={wrappedOnDismiss} hash={hash}>
          <AutoColumn gap="md" justify="center">
            <ThemedText.DeprecatedLargeHeader>
              <Trans>Transaction Submitted</Trans>
            </ThemedText.DeprecatedLargeHeader>
            <ThemedText.DeprecatedBody fontSize={20}>
              <Trans>Claimed {governanceToken.symbol}!</Trans>
            </ThemedText.DeprecatedBody>
          </AutoColumn>
        </SubmittedView>
      )}
    </Modal>
  )
}
