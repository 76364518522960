import { MaxUint256 } from '@ethersproject/constants'
import type { TransactionResponse } from '@ethersproject/providers'
import { InterfaceEventName } from '@uniswap/analytics-events'
import { useWeb3React } from '@web3-react/core'
import { sendAnalyticsEvent } from 'analytics'
import { ChainId, Currency, CurrencyAmount, Token } from 'eth-mainnet-few-sdk-core-2'
import { ADDRESS_ZERO } from 'eth-mainnet-few-v3-sdk-2'
import { FEW_WRAPPED_TOKEN_FACTORY_ADDRESS } from 'eth-mainnet-universal-router-sdk-3'
import { useFewFactoryContract, useTokenContract } from 'hooks/useContract'
import { useGetFewWrappedToken, useTokenAllowance } from 'hooks/useTokenAllowance'
import { getTokenAddress } from 'lib/utils/analytics'
import { useCallback, useMemo } from 'react'
import { calculateGasMargin } from 'utils/calculateGasMargin'

export enum ApprovalState {
  UNKNOWN = 'UNKNOWN',
  NOT_APPROVED = 'NOT_APPROVED',
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
}

export enum FewTokenState {
  UNKNOWN = 'UNKNOWN',
  NOT_EXIST = 'NOT_EXIST',
  CREATING = 'CREATING',
  CREATED = 'CREATED',
}

function useApprovalStateForSpender(
  amountToApprove: CurrencyAmount<Currency> | undefined,
  spender: string | undefined,
  useIsPendingApproval: (token?: Token, spender?: string) => boolean
): ApprovalState {
  const { account } = useWeb3React()
  const token = amountToApprove?.currency?.isToken ? amountToApprove.currency : undefined

  const { tokenAllowance } = useTokenAllowance(token, account ?? undefined, spender)

  const pendingApproval = useIsPendingApproval(token, spender)

  return useMemo(() => {
    if (!amountToApprove || !spender) return ApprovalState.UNKNOWN
    if (amountToApprove.currency.isNative) return ApprovalState.APPROVED
    // we might not have enough data to know whether or not we need to approve
    if (!tokenAllowance) return ApprovalState.UNKNOWN

    // amountToApprove will be defined if tokenAllowance is
    return tokenAllowance.lessThan(amountToApprove)
      ? pendingApproval
        ? ApprovalState.PENDING
        : ApprovalState.NOT_APPROVED
      : ApprovalState.APPROVED
  }, [amountToApprove, pendingApproval, spender, tokenAllowance])
}

export function useApproval(
  amountToApprove: CurrencyAmount<Currency> | undefined,
  spender: string | undefined,
  useIsPendingApproval: (token?: Token, spender?: string) => boolean
): [
  ApprovalState,
  () => Promise<
    | { response: TransactionResponse; tokenAddress: string; spenderAddress: string; amount: CurrencyAmount<Currency> }
    | undefined
  >
] {
  const { chainId } = useWeb3React()
  const token = amountToApprove?.currency?.isToken ? amountToApprove.currency : undefined

  // check the current approval status
  const approvalState = useApprovalStateForSpender(amountToApprove, spender, useIsPendingApproval)

  const tokenContract = useTokenContract(token?.address)

  const approve = useCallback(async () => {
    function logFailure(error: Error | string): undefined {
      console.warn(`${token?.symbol || 'Token'} approval failed:`, error)
      return
    }

    // Bail early if there is an issue.
    if (approvalState !== ApprovalState.NOT_APPROVED) {
      return logFailure('approve was called unnecessarily')
    } else if (!chainId) {
      return logFailure('no chainId')
    } else if (!token) {
      return logFailure('no token')
    } else if (!tokenContract) {
      return logFailure('tokenContract is null')
    } else if (!amountToApprove) {
      return logFailure('missing amount to approve')
    } else if (!spender) {
      return logFailure('no spender')
    }

    let useExact = false
    const estimatedGas = await tokenContract.estimateGas.approve(spender, MaxUint256).catch(() => {
      // general fallback for tokens which restrict approval amounts
      useExact = true
      return tokenContract.estimateGas.approve(spender, amountToApprove.quotient.toString())
    })

    return tokenContract
      .approve(spender, useExact ? amountToApprove.quotient.toString() : MaxUint256, {
        gasLimit: calculateGasMargin(estimatedGas),
      })
      .then((response) => {
        const eventProperties = {
          chain_id: chainId,
          token_symbol: token?.symbol,
          token_address: getTokenAddress(token),
        }
        sendAnalyticsEvent(InterfaceEventName.APPROVE_TOKEN_TXN_SUBMITTED, eventProperties)
        return {
          response,
          tokenAddress: token.address,
          spenderAddress: spender,
          amount: amountToApprove,
        }
      })
      .catch((error: Error) => {
        logFailure(error)
        throw error
      })
  }, [approvalState, token, tokenContract, amountToApprove, spender, chainId])

  return [approvalState, approve]
}

function useFewTokenStateForSpender(
  amountToApprove: CurrencyAmount<Currency> | undefined,
  spender: string | undefined,
  useIsPendingApproval: (token?: Token, spender?: string) => boolean
): FewTokenState {
  const token = amountToApprove?.currency?.isToken ? amountToApprove.currency : undefined

  const { fewWrappedTokenAddress } = useGetFewWrappedToken(token)
  const pendingApproval = useIsPendingApproval(token, spender)

  return useMemo(() => {
    if (!amountToApprove || !spender) return FewTokenState.UNKNOWN
    if (amountToApprove.currency.isNative) return FewTokenState.CREATED
    // we might not have enough data to know whether or not we need to approve
    if (!fewWrappedTokenAddress) return FewTokenState.UNKNOWN

    // amountToApprove will be defined if fewWrappedTokenAddress is
    return fewWrappedTokenAddress === ADDRESS_ZERO
      ? pendingApproval
        ? FewTokenState.CREATING
        : FewTokenState.NOT_EXIST
      : FewTokenState.CREATED
  }, [amountToApprove, pendingApproval, spender, fewWrappedTokenAddress])
}

export function useCreateToken(
  amountToApprove: CurrencyAmount<Currency> | undefined,
  spender: string | undefined,
  useIsPendingApproval: (token?: Token, spender?: string) => boolean
): [
  FewTokenState,
  () => Promise<
    | { response: TransactionResponse; tokenAddress: string; spenderAddress: string; amount: CurrencyAmount<Currency> }
    | undefined
  >
] {
  const { chainId } = useWeb3React()
  const token = amountToApprove?.currency?.isToken ? amountToApprove.currency : undefined

  // check the current approval status
  const createFewTokenState = useFewTokenStateForSpender(amountToApprove, spender, useIsPendingApproval)

  const fewFactoryContract = useFewFactoryContract(FEW_WRAPPED_TOKEN_FACTORY_ADDRESS(chainId ?? ChainId.BLAST))

  const approve = useCallback(async () => {
    function logFailure(error: Error | string): undefined {
      console.warn(`${token?.symbol || 'Token'} approval failed:`, error)
      return
    }

    // Bail early if there is an issue.
    if (createFewTokenState !== FewTokenState.NOT_EXIST) {
      return logFailure('approve was called unnecessarily')
    } else if (!chainId) {
      return logFailure('no chainId')
    } else if (!token) {
      return logFailure('no token')
    } else if (!fewFactoryContract) {
      return logFailure('fewFactoryContract is null')
    } else if (!amountToApprove) {
      return logFailure('missing amount to approve')
    } else if (!spender) {
      return logFailure('no spender')
    }

    // let useExact = false
    const estimatedGas = await fewFactoryContract.estimateGas.createToken(token.address).catch(() => {
      // general fallback for tokens which restrict approval amounts
      // useExact = true
      return fewFactoryContract.estimateGas.createToken(token.address)
    })

    return fewFactoryContract
      .createToken(token.address, {
        gasLimit: calculateGasMargin(estimatedGas),
      })
      .then((response) => {
        const eventProperties = {
          chain_id: chainId,
          token_symbol: token?.symbol,
          token_address: getTokenAddress(token),
        }
        sendAnalyticsEvent(InterfaceEventName.APPROVE_TOKEN_TXN_SUBMITTED, eventProperties)
        return {
          response,
          tokenAddress: token.address,
          spenderAddress: spender,
          amount: amountToApprove,
        }
      })
      .catch((error: Error) => {
        logFailure(error)
        throw error
      })
  }, [createFewTokenState, chainId, token, fewFactoryContract, amountToApprove, spender])

  return [createFewTokenState, approve]
}
