import type { TransactionResponse } from '@ethersproject/providers'
import { Trans } from '@lingui/macro'
// import StakingRewardsJson from '@uniswap/liquidity-staker/build/StakingRewards.json'
import { useWeb3React } from '@web3-react/core'
import FixedStakingRewardsJSON from 'abis/FixedStakingRewards.json'
import { ChainId } from 'eth-mainnet-few-sdk-core-2'
import { ReactNode, useState } from 'react'
import styled from 'styled-components'
import { calculateGasMargin } from 'utils/calculateGasMargin'

import { useContract } from '../../../hooks/useContract'
import { StakingTokenInfo } from '../../../state/stake/hooks'
import { useTransactionAdder } from '../../../state/transactions/hooks'
import { TransactionType } from '../../../state/transactions/types'
// import { useActiveWeb3React } from '../../../hooks/web3'
import { CloseIcon, ThemedText } from '../../../theme'
import { ButtonError } from '../../Button'
import { AutoColumn } from '../../Column'
import FormattedCurrencyAmount from '../../FormattedCurrencyAmount'
import Modal from '../../Modal'
import { LoadingView, SubmittedView } from '../../ModalViews'
import { RowBetween } from '../../Row'

// const { abi: STAKING_REWARDS_ABI } = StakingRewardsJson
const { abi: FEW_STAKING_REWARDS_ABI } = FixedStakingRewardsJSON

// function useStakingContract(stakingAddress?: string, withSignerIfPossible?: boolean) {
//   return useContract(stakingAddress, STAKING_REWARDS_ABI, withSignerIfPossible)
// }

function useFewStakingContract(stakingAddress?: string, withSignerIfPossible?: boolean) {
  return useContract(stakingAddress, FEW_STAKING_REWARDS_ABI, withSignerIfPossible)
}

const ContentWrapper = styled(AutoColumn)`
  width: 100%;
  padding: 1rem;
`

interface StakingModalProps {
  isOpen: boolean
  onDismiss: () => void
  stakingInfo: StakingTokenInfo
}

export default function SingleUnstakingModal({ isOpen, onDismiss, stakingInfo }: StakingModalProps) {
  const { account } = useWeb3React()

  // monitor call to help UI loading state
  const addTransaction = useTransactionAdder()
  const [hash, setHash] = useState<string | undefined>()
  const [attempting, setAttempting] = useState(false)

  let newPool
  let governanceToken

  if (stakingInfo.earnedAmount.currency.chainId === ChainId.BLAST) {
    newPool = 'mainnet_pool'
    governanceToken = 'POINT'
  } else if (
    stakingInfo.stakingRewardAddress === '0x366Ac78214aFE145Ca35d4A6513F4eD9e8909Fe8' ||
    stakingInfo.stakingRewardAddress === '0x3f6d0620d655Dc618Cb956cc2073644f92736616'
  ) {
    newPool = 'newPool'
    governanceToken = 'POINT'
  } else {
    newPool = undefined
    governanceToken = 'RGB'
  }

  function wrappedOnDismiss() {
    setHash(undefined)
    setAttempting(false)
    onDismiss()
  }

  const stakingContract = useFewStakingContract(stakingInfo.stakingRewardAddress)

  async function onWithdraw() {
    if (!stakingContract) throw new Error('no stakingContract')
    if (!stakingInfo?.stakedAmount) throw new Error('no stakedAmount')

    const index = stakingInfo.index

    let estimate, method: (...args: any) => Promise<TransactionResponse>, args: Array<string | string[] | number>
    if (stakingInfo.originalToken.isNative) {
      estimate = stakingContract.estimateGas.withdrawETH
      method = stakingContract.withdrawETH
      args = [index, `0x${stakingInfo.stakedAmount.quotient.toString(16)}`]
    } else {
      estimate = stakingContract.estimateGas.withdraw
      method = stakingContract.withdraw
      args = [index, `0x${stakingInfo.stakedAmount.quotient.toString(16)}`]
    }

    setAttempting(true)
    await estimate(...args, {})
      .then((estimatedGasLimit) =>
        method(...args, {
          ...{},
          gasLimit: calculateGasMargin(estimatedGasLimit),
        }).then((response) => {
          setAttempting(false)

          addTransaction(response, {
            type: TransactionType.WITHDRAW_SINGLE_TOKEN_STAKING,
            tokenAddress: stakingInfo.token.wrapped.address,
          })

          setHash(response.hash)
        })
      )
      .catch((error) => {
        setAttempting(false)
        // we only care if the error is something _other_ than the user rejected the tx
        if (error?.code !== 4001) {
          console.error(error)
        }
      })
  }

  async function onWithdrawAndClaim() {
    if (stakingContract && stakingInfo?.stakedAmount) {
      setAttempting(true)
      await stakingContract
        .exit(stakingInfo.index, { gasLimit: 300000 })
        .then((response: TransactionResponse) => {
          addTransaction(response, {
            type: TransactionType.WITHDRAW_SINGLE_TOKEN_STAKING,
            tokenAddress: stakingInfo.token.wrapped.address,
          })
          setHash(response.hash)
        })
        .catch((error: any) => {
          setAttempting(false)
          console.log(error)
        })
    }
  }

  let error: ReactNode | undefined
  if (!account) {
    error = <Trans>Connect a wallet</Trans>
  }
  if (!stakingInfo?.stakedAmount) {
    error = error ?? <Trans>Enter an amount</Trans>
  }

  return (
    <Modal isOpen={isOpen} onDismiss={wrappedOnDismiss} maxHeight={90}>
      {!attempting && !hash && (
        <ContentWrapper gap="lg">
          <RowBetween>
            <ThemedText.DeprecatedMediumHeader>
              <Trans>Withdraw</Trans>
            </ThemedText.DeprecatedMediumHeader>
            <CloseIcon onClick={wrappedOnDismiss} />
          </RowBetween>
          {stakingInfo?.stakedAmount && (
            <AutoColumn justify="center" gap="md">
              <ThemedText.HeadlineLarge>
                <FormattedCurrencyAmount currencyAmount={stakingInfo.stakedAmount} />
              </ThemedText.HeadlineLarge>
              <ThemedText.DeprecatedBody>
                <Trans>Deposited liquidity</Trans>
              </ThemedText.DeprecatedBody>
            </AutoColumn>
          )}
          {stakingInfo?.earnedAmount && !newPool && (
            <AutoColumn justify="center" gap="md">
              <ThemedText.HeadlineLarge>
                <FormattedCurrencyAmount currencyAmount={stakingInfo?.earnedAmount} />
              </ThemedText.HeadlineLarge>
              <ThemedText.DeprecatedBody>
                <Trans>Unclaimed {governanceToken}</Trans>
              </ThemedText.DeprecatedBody>
            </AutoColumn>
          )}
          {!newPool && (
            <ThemedText.DeprecatedSubHeader style={{ textAlign: 'center' }}>
              <Trans>When you withdraw, your RGB is claimed and your liquidity is removed from the mining pool.</Trans>
            </ThemedText.DeprecatedSubHeader>
          )}
          {!newPool && (
            <ButtonError disabled={!!error} error={!!error && !!stakingInfo?.stakedAmount} onClick={onWithdrawAndClaim}>
              {error ?? <Trans>Withdraw & Claim</Trans>}
            </ButtonError>
          )}
          {newPool && (
            <ThemedText.DeprecatedSubHeader style={{ textAlign: 'center' }}>
              <Trans>When you withdraw, your liquidity is removed from the mining pool.</Trans>
            </ThemedText.DeprecatedSubHeader>
          )}
          {newPool && (
            <ButtonError disabled={!!error} error={!!error && !!stakingInfo?.stakedAmount} onClick={onWithdraw}>
              {error ?? <Trans>Withdraw</Trans>}
            </ButtonError>
          )}
        </ContentWrapper>
      )}
      {attempting && !hash && (
        <LoadingView onDismiss={wrappedOnDismiss}>
          <AutoColumn gap="md" justify="center">
            <ThemedText.DeprecatedBody fontSize={20}>
              <Trans>
                Withdrawing {stakingInfo?.stakedAmount?.toSignificant(4)} {`${stakingInfo.originalToken.symbol}`}
              </Trans>
            </ThemedText.DeprecatedBody>
            {!newPool && (
              <ThemedText.DeprecatedBody fontSize={20}>
                <Trans>Claiming {stakingInfo?.earnedAmount?.toSignificant(4)} RGB</Trans>
              </ThemedText.DeprecatedBody>
            )}
          </AutoColumn>
        </LoadingView>
      )}
      {hash && (
        <SubmittedView onDismiss={wrappedOnDismiss} hash={hash}>
          <AutoColumn gap="md" justify="center">
            <ThemedText.DeprecatedLargeHeader>
              <Trans>Transaction Submitted</Trans>
            </ThemedText.DeprecatedLargeHeader>
            <ThemedText.DeprecatedBody fontSize={20}>
              <Trans>Withdrew {stakingInfo.originalToken.symbol}!</Trans>
            </ThemedText.DeprecatedBody>
            {!newPool && (
              <ThemedText.DeprecatedBody fontSize={20}>
                <Trans>Claimed RGB!</Trans>
              </ThemedText.DeprecatedBody>
            )}
          </AutoColumn>
        </SubmittedView>
      )}
    </Modal>
  )
}
