import { createApi, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react'
import { Protocol } from 'eth-mainnet-few-router-sdk-3'
// import { TradeType } from 'blast-mainnet-few-sdk-core-2'
// import { isUniswapXSupportedChain } from 'constants/chains'
import { getClientSideQuote } from 'lib/hooks/routing/clientSideSmartOrderRouter'
import ms from 'ms'

import {
  GetQuoteArgs,
  QuoteMethod,
  QuoteState,
  // RouterPreference,
  // RoutingConfig,
  // SwapRouterNativeAssets,
  TradeResult,
  // URAQuoteType,
} from './types'
import { getRouter, transformRoutesToTrade } from './utils'

const UNISWAP_API_URL = process.env.REACT_APP_UNISWAP_API_URL
if (UNISWAP_API_URL === undefined) {
  throw new Error(`UNISWAP_API_URL must be a defined environment variable`)
}

const CLIENT_PARAMS = {
  protocols: [Protocol.V2, Protocol.V3, Protocol.MIXED],
}

// const fewProtocols: Protocol[] = [Protocol.V2, Protocol.RING, Protocol.MIXED]

// // routing API quote query params: https://github.com/Uniswap/routing-api/blob/main/lib/handlers/quote/schema/quote-schema.ts
// const DEFAULT_FEW_QUERY_PARAMS = {
//   protocols: fewProtocols,
// }

function getQuoteLatencyMeasure(mark: PerformanceMark): PerformanceMeasure {
  performance.mark('quote-fetch-end')
  return performance.measure('quote-fetch-latency', mark.name, 'quote-fetch-end')
}

// function getFewRoutingAPIConfig(args: GetQuoteArgs): RoutingConfig {
//   const { account, tradeType, tokenOutAddress, tokenInChainId, uniswapXForceSyntheticQuotes, routerPreference } = args

//   const uniswapx = {
//     useSyntheticQuotes: uniswapXForceSyntheticQuotes,
//     // Protocol supports swap+send to different destination address, but
//     // for now recipient === swapper
//     recipient: account,
//     swapper: account,
//     routingType: URAQuoteType.DUTCH_LIMIT,
//   }

//   const classic = {
//     ...DEFAULT_FEW_QUERY_PARAMS,
//     routingType: URAQuoteType.CLASSIC,
//   }

//   const tokenOutIsNative = Object.values(SwapRouterNativeAssets).includes(tokenOutAddress as SwapRouterNativeAssets)

//   // UniswapX doesn't support native out, exact-out, or non-mainnet trades (yet),
//   // so even if the user has selected UniswapX as their router preference, force them to receive a Classic quote.
//   if (
//     routerPreference !== RouterPreference.X ||
//     tokenOutIsNative ||
//     tradeType === TradeType.EXACT_OUTPUT ||
//     !isUniswapXSupportedChain(tokenInChainId)
//   ) {
//     return [classic]
//   }

//   return [uniswapx, classic]
// }

export const fewRoutingApi = createApi({
  reducerPath: 'fewRoutingApi',
  baseQuery: fetchBaseQuery({
    baseUrl: UNISWAP_API_URL,
  }),
  endpoints: (build) => ({
    getQuote: build.query<TradeResult, GetQuoteArgs>({
      async onQueryStarted(args: GetQuoteArgs, { queryFulfilled }) {
        try {
          await queryFulfilled
        } catch (error: unknown) {
          if (error && typeof error === 'object' && 'error' in error) {
            const queryError = (error as Record<'error', FetchBaseQueryError>).error
            if (typeof queryError.status === 'number') {
              console.log(queryError)
            }
            console.log(queryError)
          } else {
            throw error
          }
        }
      },
      async queryFn(args) {
        const quoteStartMark = performance.mark(`quote-fetch-start-${Date.now()}`)
        // try {
        //   const { tokenInAddress, tokenInChainId, tokenOutAddress, tokenOutChainId, amount, tradeType } = args
        //   const type = isExactInput(tradeType) ? 'EXACT_INPUT' : 'EXACT_OUTPUT'

        //   const fewTokenInAddress =
        //     tokenInAddress === 'ETH'
        //       ? getWrappedTokenCreate2Address(
        //           WRAPPED_NATIVE_CURRENCY[tokenInChainId]?.address ?? WETH9[tokenInChainId].address,
        //           tokenInChainId
        //         )
        //       : getWrappedTokenCreate2Address(tokenInAddress, tokenInChainId)

        //   const fewTokenOutAddress =
        //     tokenOutAddress === 'ETH'
        //       ? getWrappedTokenCreate2Address(
        //           WRAPPED_NATIVE_CURRENCY[tokenInChainId]?.address ?? WETH9[tokenInChainId].address,
        //           tokenInChainId
        //         )
        //       : getWrappedTokenCreate2Address(tokenOutAddress, tokenInChainId)

        //   const requestBody = {
        //     tokenInChainId,
        //     tokenIn: fewTokenInAddress,
        //     tokenOutChainId,
        //     tokenOut: fewTokenOutAddress,
        //     amount,
        //     type,
        //     configs: getFewRoutingAPIConfig(args),
        //   }

        //   const response = await fetch({
        //     method: 'POST',
        //     url: '/quote',
        //     body: JSON.stringify(requestBody),
        //   })

        //   if (response.error) {
        //     try {
        //       // cast as any here because we do a runtime check on it being an object before indexing into .errorCode
        //       const errorData = response.error.data as { errorCode?: string; detail?: string }
        //       // NO_ROUTE should be treated as a valid response to prevent retries.
        //       if (
        //         typeof errorData === 'object' &&
        //         (errorData?.errorCode === 'NO_ROUTE' || errorData?.detail === 'No quotes available')
        //       ) {
        //         sendAnalyticsEvent('No quote received from routing API', {
        //           requestBody,
        //           response,
        //           routerPreference: args.routerPreference,
        //         })
        //         return {
        //           data: { state: QuoteState.NOT_FOUND, latencyMs: getQuoteLatencyMeasure(quoteStartMark).duration },
        //         }
        //       }
        //     } catch {
        //       throw response.error
        //     }
        //   }

        //   const uraQuoteResponse = response.data as URAQuoteResponse
        //   const tradeResult = await transformRoutesToTrade(args, uraQuoteResponse, QuoteMethod.ROUTING_API)
        //   return { data: { ...tradeResult, latencyMs: getQuoteLatencyMeasure(quoteStartMark).duration } }
        // } catch (error: any) {
        //   console.warn(
        //     `GetQuote failed on Unified Routing API, falling back to client: ${
        //       error?.message ?? error?.detail ?? error
        //     }`
        //   )
        // }
        try {
          const router = getRouter(args.tokenInChainId)
          const quoteResult = await getClientSideQuote(args, router, CLIENT_PARAMS)
          if (quoteResult.state === QuoteState.SUCCESS) {
            const trade = await transformRoutesToTrade(args, quoteResult.data, QuoteMethod.CLIENT_SIDE_FALLBACK)
            return {
              data: { ...trade, latencyMs: getQuoteLatencyMeasure(quoteStartMark).duration },
            }
          } else {
            return { data: { ...quoteResult, latencyMs: getQuoteLatencyMeasure(quoteStartMark).duration } }
          }
        } catch (error: any) {
          console.warn(`GetQuote failed on client: ${error}`)
          return {
            error: { status: 'CUSTOM_ERROR', error: error?.detail ?? error?.message ?? error },
          }
        }
      },
      keepUnusedDataFor: ms(`10s`),
      extraOptions: {
        maxRetries: 0,
      },
    }),
  }),
})

export const { useGetQuoteQuery } = fewRoutingApi
export const useGetFewQuoteQueryState = fewRoutingApi.endpoints.getQuote.useQueryState
