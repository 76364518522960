/* eslint-disable import/no-unused-modules */
import Modal from 'components/Modal'
import Row, { RowBetween } from 'components/Row'
import { UniIcon } from 'nft/components/icons'
import { X } from 'react-feather'
import { useModalIsOpen, useToggleInstallDappModal } from 'state/application/hooks'
import { ApplicationModal } from 'state/application/reducer'
import styled from 'styled-components'

import installApp from '../../assets/images/install-app.png'

const ModalWrapper = styled(RowBetween)`
  display: flex;
  flex-direction: column;
  padding: 20px 16px 16px;
`

const Image = styled.img`
  max-width: 510px;
  width: 100%;
`

const Header = styled(Row)`
  padding: 0px 0px 16px;
  font-weight: 535;
  font-size: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.surface3};
`

const CloseButton = styled.button`
  cursor: pointer;
  background: transparent;
  border: none;
  color: ${({ theme }) => theme.neutral1};
`

export default function InstallDappModal() {
  const open = useModalIsOpen(ApplicationModal.INSTALL_DAPP)
  const toggleModal = useToggleInstallDappModal()

  return (
    <Modal isOpen={open} onDismiss={toggleModal}>
      <ModalWrapper>
        <Header justify="space-between">
          <Row className="select-none">
            <UniIcon className="w-12 h-12" />
            Install Ring App
          </Row>
          <CloseButton onClick={toggleModal}>
            <X size={24} />
          </CloseButton>
        </Header>
        <div className="w-full py-3 px-2 select-none">
          <div>
            To continue, install this app on your device to easily access it anytime by adding to your homescreen. No
            app store or download required.
          </div>
          <div className="flex items-center gap-2 my-2">
            1. Tap on
            <svg
              className="w-6 h-6"
              viewBox="0 0 1024 1024"
              xmlns="http://www.w3.org/2000/svg"
              width="200"
              height="200"
            >
              <path
                d="M731.428571 341.333333h73.142858a73.142857 73.142857 0 0 1 73.142857 73.142857v414.476191a73.142857 73.142857 0 0 1-73.142857 73.142857H219.428571a73.142857 73.142857 0 0 1-73.142857-73.142857V414.47619a73.142857 73.142857 0 0 1 73.142857-73.142857h73.142858v73.142857H219.428571v414.476191h585.142858V414.47619h-73.142858v-73.142857zM518.460952 93.671619l172.373334 172.373333-51.687619 51.736381-84.601905-84.577523v348.306285h-73.142857V234.22781l-83.626667 83.577904-51.712-51.712 172.373333-172.397714z"
                fill="#2563eb"
              ></path>
            </svg>
          </div>
          <div className="my-4 h-20 max-w-max">
            <Image src={installApp} alt="ios ring app install info" />
          </div>
          <div className="flex items-center mt-12 mb-2">
            <div>2. Then select</div>
            <div className="rounded-lg bg-[linear-gradient(139.57deg,_#FF79C9_4.35%,_#FFB8E2_96.44%)] text-white text-xs px-2 py-1 font-bold ml-2">
              Add to Homescreen
            </div>
          </div>
        </div>
      </ModalWrapper>
    </Modal>
  )
}
