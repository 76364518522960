import { Row } from 'nft/components/Flex'
import styled, { css } from 'styled-components'
import { ExternalLink } from 'theme/components'

import { Wiggle } from '../../animations'
import { Discord, Github, Twitter } from '../../Icons'

const SocialIcon = styled(Wiggle)`
  flex: 0;
  fill: ${(props) => props.theme.neutral1};
  cursor: pointer;
  transition: fill;
  transition-duration: 0.2s;
  &:hover {
    fill: ${(props) => props.$hoverColor};
  }
`

const MenuItemStyles = css`
  padding: 0;
  margin: 0;
  text-align: left;
  font-family: Basel;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: ${({ theme }) => theme.neutral2};
  stroke: none;
  transition: color 0.1s ease-in-out;
  text-decoration: none;
  &:hover {
    color: ${({ theme }) => theme.neutral1};
    opacity: 1;
  }
`

const StyledExternalLink = styled(ExternalLink)`
  ${MenuItemStyles}
`

export function Socials({ iconSize }: { iconSize?: string }) {
  return (
    <Row gap="24">
      <SocialIcon $hoverColor="#5F51FF">
        <StyledExternalLink href="https://discord.com/invite/TefBNDZBQP">
          <Discord size={iconSize} fill="inherit" />
        </StyledExternalLink>
      </SocialIcon>
      <SocialIcon $hoverColor="#20BAFF">
        <StyledExternalLink href="https://twitter.com/ProtocolRing">
          <Twitter size={iconSize} fill="inherit" />
        </StyledExternalLink>
      </SocialIcon>
      <SocialIcon $hoverColor="#00C32B">
        <StyledExternalLink href="https://github.com/RingProtocol">
          <Github size={iconSize} fill="inherit" />
        </StyledExternalLink>
      </SocialIcon>
    </Row>
  )
}
