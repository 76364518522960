import { t } from '@lingui/macro'
import { MinerSettings } from 'components/Icons/MinerSettings'
import { SwapIcon } from 'components/Icons/SwapIcon'
import Row from 'components/Row'
import { Currency } from 'eth-mainnet-few-sdk-core-2'
import styled from 'styled-components'
import { ThemedText } from 'theme'

const Icon = styled(MinerSettings)`
  height: 20px;
  width: 20px;
  > * {
    fill: ${({ theme }) => theme.neutral2};
  }
`

export const MinerIcon = styled(MinerSettings)`
  height: 20px;
  width: 20px;
  > * {
    fill: ${({ theme }) => theme.yellowVibrant};
  }
`

const IconSwap = styled(SwapIcon)`
  height: 12px;
  width: 12px;
  margin: auto 2px;
  > * {
    fill: ${({ theme }) => theme.yellowVibrant};
  }
`

const Button = styled.button<{ isActive: boolean }>`
  border: none;
  background-color: transparent;
  margin: 0;
  margin-right: 6px;
  padding: 0;
  cursor: pointer;
  outline: none;
  :not([disabled]):hover {
    opacity: 0.7;
  }
  ${({ isActive }) => isActive && `opacity: 0.7`}
`

const IconContainer = styled(Row)`
  padding: 6px 12px;
  border-radius: 16px;
`

const IconContainerWithSlippage = styled(IconContainer)<{ displayWarning?: boolean }>`
  div {
    color: ${({ theme }) => theme.yellowVibrant};
  }
  background-color: ${({ theme }) => theme.surface2};
`

const ButtonContent = ({
  isMiner,
  isSupportNetwork,
  currencies,
}: {
  isMiner?: boolean
  isSupportNetwork?: boolean
  currencies?: {
    INPUT?: Currency | null
    OUTPUT?: Currency | null
  }
}) => {
  return isSupportNetwork && isMiner ? (
    <IconContainerWithSlippage data-testid="miner-icon-with-setting" gap="sm">
      <ThemedText.BodySmall paddingY="2px">
        <Row>
          {currencies?.INPUT?.symbol}
          <IconSwap />
          {currencies?.OUTPUT?.symbol}
        </Row>
      </ThemedText.BodySmall>

      <MinerIcon />
    </IconContainerWithSlippage>
  ) : (
    <IconContainer>{isMiner && isSupportNetwork ? <MinerIcon /> : <Icon />}</IconContainer>
  )
}

export default function MinerButton({
  disabled,
  onClick,
  isActive,
  isMiner,
  currencies,
}: {
  disabled: boolean
  onClick: () => void
  isActive: boolean
  isMiner?: boolean
  currencies?: {
    INPUT?: Currency | null
    OUTPUT?: Currency | null
  }
}) {
  return (
    <Button
      disabled={disabled}
      onClick={() => {
        onClick()
      }}
      isActive={isActive}
      id="open-miner-dialog-button"
      data-testid="open-miner-dialog-button"
      aria-label={t`Miner Settings`}
    >
      <ButtonContent isMiner={isMiner} isSupportNetwork={!disabled} currencies={currencies} />
    </Button>
  )
}
