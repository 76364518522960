import { useWeb3React } from '@web3-react/core'
import { OffchainActivityModal } from 'components/AccountDrawer/MiniPortfolio/Activity/OffchainActivityModal'
import UniwalletModal from 'components/AccountDrawer/UniwalletModal'
// import BaseAnnouncementBanner from 'components/Banner/BaseAnnouncementBanner'
import ConnectedAccountBlocked from 'components/ConnectedAccountBlocked'
import InstallDappModal from 'components/InstallDappModal'
import useAccountRiskCheck from 'hooks/useAccountRiskCheck'
import { useModalIsOpen } from 'state/application/hooks'
import { ApplicationModal } from 'state/application/reducer'

export default function TopLevelModals() {
  // const addressClaimOpen = useModalIsOpen(ApplicationModal.ADDRESS_CLAIM)
  // const addressClaimToggle = useToggleModal(ApplicationModal.ADDRESS_CLAIM)
  const blockedAccountModalOpen = useModalIsOpen(ApplicationModal.BLOCKED_ACCOUNT)
  const { account } = useWeb3React()
  useAccountRiskCheck(account)
  const accountBlocked = Boolean(blockedAccountModalOpen && account)

  return (
    <>
      {/* <AddressClaimModal isOpen={addressClaimOpen} onDismiss={addressClaimToggle} /> */}
      <ConnectedAccountBlocked account={account} isOpen={accountBlocked} />
      <UniwalletModal />
      <InstallDappModal />
      {/* <BaseAnnouncementBanner /> */}
      <OffchainActivityModal />
    </>
  )
}
