/* eslint-disable import/no-unused-modules */
import Row from 'components/Row'
import { UniIcon } from 'nft/components/icons'
import { useOpenModal } from 'state/application/hooks'
import { ApplicationModal } from 'state/application/reducer'
import styled from 'styled-components'

const StyledButton = styled.button`
  background: ${({ theme }) => theme.surface1};
  border-radius: 20px;
  cursor: pointer;
  &:hover {
    background: ${({ theme }) => theme.surface2};
  }
`

export const WiggleIcon = styled.div`
  flex: 0;
  height: auto;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`
export function InstallAppButton() {
  const openModal = useOpenModal(ApplicationModal.INSTALL_DAPP)

  return (
    <StyledButton onClick={openModal}>
      <div className="w-full">
        <WiggleIcon className="py-1">
          <Row>
            <UniIcon className="w-12 h-12" />
            <div className="text-left">
              <div className="">Install Ring App</div>
              <div className="text-xs">Easily access for device</div>
            </div>
          </Row>
        </WiggleIcon>
      </div>
    </StyledButton>
  )
}
