import { BigNumber } from '@ethersproject/bignumber'
import type { TransactionResponse } from '@ethersproject/providers'
import { Trans } from '@lingui/macro'
import { useWeb3React } from '@web3-react/core'
import { POINT, TOKEN_CONVERTER_ADDRESS } from 'constants/tokens'
import { ChainId, Currency, CurrencyAmount, Token } from 'eth-mainnet-few-sdk-core-2'
import { useCallback, useState } from 'react'
import styled from 'styled-components'
import { calculateGasMargin } from 'utils/calculateGasMargin'

import { ApprovalState, useApproveCallback } from '../../../hooks/useApproveCallback'
import { useTokenConverterContract } from '../../../hooks/useContract'
import useTransactionDeadline from '../../../hooks/useTransactionDeadline'
import { useDerivedRedeemInfo } from '../../../state/stake/hooks'
import { useTransactionAdder } from '../../../state/transactions/hooks'
import { TransactionType } from '../../../state/transactions/types'
import { CloseIcon, ThemedText } from '../../../theme'
import { formatCurrencyAmount } from '../../../utils/formatCurrencyAmount'
import { maxAmountSpend } from '../../../utils/maxAmountSpend'
import { ButtonConfirmed, ButtonError } from '../../Button'
import { AutoColumn } from '../../Column'
import CurrencyInputPanel from '../../CurrencyInputPanel'
import Modal from '../../Modal'
import { LoadingView, SubmittedView } from '../../ModalViews'
import ProgressCircles from '../../ProgressSteps'
import { RowBetween } from '../../Row'

const ContentWrapper = styled(AutoColumn)`
  width: 100%;
  padding: 1rem;
`

interface WrapTokenModalProps {
  isOpen: boolean
  onDismiss: () => void
  userBalances?: CurrencyAmount<Token | Currency>
}

export default function RedeemModal({ isOpen, onDismiss, userBalances }: WrapTokenModalProps) {
  const { provider, account, chainId } = useWeb3React()

  // track and parse user input
  const [typedValue, setTypedValue] = useState('')
  const { parsedAmount, error } = useDerivedRedeemInfo(typedValue, POINT[chainId ?? ChainId.BLAST], userBalances)

  // state for pending and submitted txn views
  const addTransaction = useTransactionAdder()
  const [attempting, setAttempting] = useState<boolean>(false)
  const [hash, setHash] = useState<string | undefined>()
  function wrappedOnDismiss() {
    setHash(undefined)
    setAttempting(false)
    onDismiss()
  }

  // approval data for stake
  const deadline = useTransactionDeadline()
  const [approval, approveCallback] = useApproveCallback(
    parsedAmount,
    TOKEN_CONVERTER_ADDRESS[chainId ?? ChainId.BLAST]
  )

  const tokenConverter = useTokenConverterContract(TOKEN_CONVERTER_ADDRESS[chainId ?? ChainId.BLAST])

  async function onRedeem() {
    if (!account || !tokenConverter || !parsedAmount || !deadline) throw new Error('no fewETHWrapperContract')

    // let estimate: any,
    //   method: (...args: any) => Promise<TransactionResponse>,
    //   args: Array<string | string[] | number>,
    //   value: string | null
    const estimate = tokenConverter.estimateGas.redeem
    const method: (...args: any) => Promise<TransactionResponse> = tokenConverter.redeem
    const recepient = account
    const args: Array<string | string[] | number> = [`0x${parsedAmount.quotient.toString(16)}`, recepient]
    const value: string | null = null

    setAttempting(true)
    if (approval === ApprovalState.APPROVED) {
      await estimate(...args, {})
        .then((estimatedGasLimit: BigNumber) =>
          method(...args, {
            ...(value ? { value } : {}),
            gasLimit: calculateGasMargin(estimatedGasLimit),
          }).then((response) => {
            setAttempting(false)

            addTransaction(response, {
              type: TransactionType.REDEEM,
              tokenAddress: POINT[chainId ?? ChainId.BLAST].address,
              amount: parsedAmount.quotient.toString(),
              recipient: account,
            })
            setHash(response.hash)
          })
        )
        .catch((error: any) => {
          setAttempting(false)
          // we only care if the error is something _other_ than the user rejected the tx
          if (error?.code !== 4001) {
            console.error(error)
          }
        })
    } else {
      setAttempting(false)
      throw new Error('Attempting to stake without approval or a signature. Please contact support.')
    }
  }

  // wrapped onUserInput to clear signatures
  const onUserInput = useCallback((typedValue: string) => {
    setTypedValue(typedValue)
  }, [])

  // used for max input button
  const maxAmountInput = maxAmountSpend(userBalances)
  const atMaxAmount = Boolean(maxAmountInput && parsedAmount?.equalTo(maxAmountInput))
  const handleMax = useCallback(() => {
    maxAmountInput && onUserInput(maxAmountInput.toExact())
  }, [maxAmountInput, onUserInput])

  async function onAttemptToApprove() {
    if (!provider || !deadline) throw new Error('missing dependencies')
    if (!parsedAmount) throw new Error('missing liquidity amount')

    try {
      await approveCallback()
    } catch (error) {
      setAttempting(false)
      console.log(error)
    }
  }

  return (
    <Modal isOpen={isOpen} onDismiss={wrappedOnDismiss} maxHeight={90}>
      {!attempting && !hash && (
        <ContentWrapper gap="lg">
          <RowBetween>
            <ThemedText.DeprecatedMediumHeader>
              <Trans>Redeem</Trans>
            </ThemedText.DeprecatedMediumHeader>
            <CloseIcon onClick={wrappedOnDismiss} />
          </RowBetween>
          <CurrencyInputPanel
            value={typedValue}
            onUserInput={onUserInput}
            onMax={handleMax}
            showMaxButton={!atMaxAmount}
            currency={POINT[chainId ?? ChainId.BLAST]}
            label=""
            renderBalance={(amount) => <Trans>Available to convert: {formatCurrencyAmount(amount, 4)}</Trans>}
            id="stake-liquidity-token"
          />

          <RowBetween>
            <ButtonConfirmed
              mr="0.5rem"
              onClick={onAttemptToApprove}
              confirmed={approval === ApprovalState.APPROVED}
              disabled={approval !== ApprovalState.NOT_APPROVED}
            >
              <Trans>Approve</Trans>
            </ButtonConfirmed>
            <ButtonError
              disabled={!!error || approval !== ApprovalState.APPROVED}
              error={!!error && !!parsedAmount}
              onClick={onRedeem}
            >
              {error ?? <Trans>Redeem</Trans>}
            </ButtonError>
          </RowBetween>
          <ProgressCircles steps={[approval === ApprovalState.APPROVED]} disabled={true} />
        </ContentWrapper>
      )}
      {attempting && !hash && (
        <LoadingView onDismiss={wrappedOnDismiss}>
          <AutoColumn gap="md" justify="center">
            <ThemedText.DeprecatedLargeHeader>
              <Trans>Redeem POINT</Trans>
            </ThemedText.DeprecatedLargeHeader>
            <ThemedText.DeprecatedBody fontSize={20}>
              <Trans>{parsedAmount?.toSignificant(4)} POINT</Trans>
            </ThemedText.DeprecatedBody>
          </AutoColumn>
        </LoadingView>
      )}
      {hash && (
        <SubmittedView onDismiss={wrappedOnDismiss} hash={hash}>
          <AutoColumn gap="md" justify="center">
            <ThemedText.DeprecatedLargeHeader>
              <Trans>Transaction Submitted</Trans>
            </ThemedText.DeprecatedLargeHeader>
            <ThemedText.DeprecatedBody fontSize={20}>
              <Trans>Redeem {parsedAmount?.toSignificant(4)} POINT</Trans>
            </ThemedText.DeprecatedBody>
          </AutoColumn>
        </SubmittedView>
      )}
    </Modal>
  )
}
