import { Trans } from '@lingui/macro'
import { ThemedText } from 'theme'

import RingXRouterLabel, { RingXRouterLabelProps } from '../RouterLabel/RingXRouterLabel'

type RingXBrandMarkProps = Omit<RingXRouterLabelProps, 'children' | 'fontWeight'> & {
  fontWeight?: 'bold'
}

export default function RingXBrandMark({ fontWeight, ...props }: RingXBrandMarkProps): JSX.Element {
  return (
    <RingXRouterLabel {...props}>
      <ThemedText.BodySecondary
        fontSize="inherit"
        {...(fontWeight === 'bold' && {
          fontWeight: '535',
        })}
      >
        <Trans>RingX</Trans>
      </ThemedText.BodySecondary>
    </RingXRouterLabel>
  )
}
