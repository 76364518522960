import { CustomUserProperties, getBrowser, SharedEventName } from '@uniswap/analytics-events'
import { sendAnalyticsEvent, Trace, user } from 'analytics'
import Loader from 'components/Icons/LoadingSpinner'
import TopLevelModals from 'components/TopLevelModals'
import { useFeatureFlagsIsLoaded } from 'featureFlags'
import { lazy, Suspense, useEffect, useState } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { useRouterPreference } from 'state/user/hooks'
import styled from 'styled-components'
import { SpinnerSVG } from 'theme/components'
import { useIsDarkMode } from 'theme/components/ThemeToggle'
import { flexRowNoWrap } from 'theme/styles'
import { Z_INDEX } from 'theme/zIndex'
import { getCurrentPageFromLocation } from 'utils/urlRoutes'
import { getCLS, getFCP, getFID, getLCP, Metric } from 'web-vitals'

import { useAnalyticsReporter } from '../components/analytics'
import ErrorBoundary from '../components/ErrorBoundary'
import { PageTabs } from '../components/NavBar'
import NavBar from '../components/NavBar'
import Polling from '../components/Polling'
import Popups from '../components/Popups'
import DarkModeQueryParamReader from '../theme/components/DarkModeQueryParamReader'
import AddLiquidity from './AddLiquidity'
import { RedirectDuplicateTokenIds } from './AddLiquidity/redirects'
// import AddLiquidity from './AddLiquidity'
// import { RedirectDuplicateTokenIds } from './AddLiquidity/redirects'
// import { RedirectDuplicateTokenIdsV2 } from './AddLiquidityV2/redirects'
import { RedirectDuplicateTokenIdsFew } from './AddLiquidityFew/redirects'
import { RedirectDuplicateTokenIdsFewToken } from './AddLiquidityFewToken/redirects'
import { RedirectDuplicateTokenIdsRing } from './AddLiquidityRing/redirects'
import Airdrop from './Airdrop/index'
import Earn from './Earn'
import Manage from './Earn/Manage'
import SingleManage from './Earn/SingleManage'
import FewPoolFinder from './FewPoolFinder'
import HomePage from './HomePage'
// import LaunchPad from './LaunchPad'
import NotFound from './NotFound'
import Pool from './Pool'
// import Pool from './Pool'
// import PositionPage from './Pool/PositionPage'
// import PoolV2 from './Pool/v2'
import PoolFew from './Pool/few'
import PositionPage from './Pool/PositionPage'
import PoolRing from './Pool/ring'
import PrivacyPolicy from './PrivacyPolicy'
import Redeem from './Redeem/index'
// import RemoveLiquidity from './RemoveLiquidity'
import RemoveV2Liquidity from './RemoveLiquidity/Few'
import RemoveV2FewTokensLiquidity from './RemoveLiquidity/FewTokens'
import RemoveLiquidityRing from './RemoveLiquidity/Ring'
import RemoveLiquidityV3 from './RemoveLiquidity/V3'
import RingPoolFinder from './RingPoolFinder'
import RingX from './RingX'
import Terms from './ServiceTerms'
import Swap from './Swap'
import { RedirectPathToSwapOnly } from './Swap/redirects'
// import Wrap from './Wrap/index'
import Wrap from './Wrap'

const Vote = lazy(() => import('./Vote'))

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  padding: ${({ theme }) => theme.navHeight}px 0px 5rem 0px;
  align-items: center;
  flex: 1;
`

const MobileBottomBar = styled.div`
  z-index: ${Z_INDEX.sticky};
  position: fixed;
  display: flex;
  bottom: 0;
  right: 0;
  left: 0;
  width: calc(100vw - 16px);
  justify-content: space-between;
  padding: 0px 4px;
  height: ${({ theme }) => theme.mobileBottomBarHeight}px;
  background: ${({ theme }) => theme.surface1};
  border: 1px solid ${({ theme }) => theme.surface3};
  margin: 8px;
  border-radius: 20px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    display: none;
  }
`

const HeaderWrapper = styled.div<{ transparent?: boolean }>`
  ${flexRowNoWrap};
  background-color: ${({ theme, transparent }) => !transparent && theme.surface1};
  border-bottom: ${({ theme, transparent }) => !transparent && `1px solid ${theme.surface3}`};
  width: 100%;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: ${Z_INDEX.dropdown};
`

// this is the same svg defined in assets/images/blue-loader.svg
// it is defined here because the remote asset may not have had time to load when this file is executing
const LazyLoadSpinner = () => (
  <SpinnerSVG width="94" height="94" viewBox="0 0 94 94" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M92 47C92 22.1472 71.8528 2 47 2C22.1472 2 2 22.1472 2 47C2 71.8528 22.1472 92 47 92"
      stroke="#2172E5"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SpinnerSVG>
)

export default function App() {
  const isLoaded = useFeatureFlagsIsLoaded()

  const { pathname } = useLocation()
  // const location = useLocation()
  const currentPage = getCurrentPageFromLocation(pathname)
  const isDarkMode = useIsDarkMode()
  const [routerPreference] = useRouterPreference()
  const [scrolledState, setScrolledState] = useState(false)

  useAnalyticsReporter()

  useEffect(() => {
    window.scrollTo(0, 0)
    setScrolledState(false)
  }, [pathname])

  useEffect(() => {
    // User properties *must* be set before sending corresponding event properties,
    // so that the event contains the correct and up-to-date user properties.
    user.set(CustomUserProperties.USER_AGENT, navigator.userAgent)
    user.set(CustomUserProperties.BROWSER, getBrowser())
    user.set(CustomUserProperties.SCREEN_RESOLUTION_HEIGHT, window.screen.height)
    user.set(CustomUserProperties.SCREEN_RESOLUTION_WIDTH, window.screen.width)

    // Service Worker analytics
    const isServiceWorkerInstalled = Boolean(window.navigator.serviceWorker?.controller)
    const isServiceWorkerHit = Boolean((window as any).__isDocumentCached)
    const serviceWorkerProperty = isServiceWorkerInstalled ? (isServiceWorkerHit ? 'hit' : 'miss') : 'uninstalled'

    const pageLoadProperties = { service_worker: serviceWorkerProperty }
    sendAnalyticsEvent(SharedEventName.APP_LOADED, pageLoadProperties)
    const sendWebVital =
      (metric: string) =>
      ({ delta }: Metric) =>
        sendAnalyticsEvent(SharedEventName.WEB_VITALS, { ...pageLoadProperties, [metric]: delta })
    getCLS(sendWebVital('cumulative_layout_shift'))
    getFCP(sendWebVital('first_contentful_paint_ms'))
    getFID(sendWebVital('first_input_delay_ms'))
    getLCP(sendWebVital('largest_contentful_paint_ms'))
  }, [])

  useEffect(() => {
    user.set(CustomUserProperties.DARK_MODE, isDarkMode)
  }, [isDarkMode])

  useEffect(() => {
    user.set(CustomUserProperties.ROUTER_PREFERENCE, routerPreference)
  }, [routerPreference])

  useEffect(() => {
    const scrollListener = () => {
      setScrolledState(window.scrollY > 0)
    }
    window.addEventListener('scroll', scrollListener)
    return () => window.removeEventListener('scroll', scrollListener)
  }, [])

  const isHeaderTransparent = !scrolledState
  // const isLandingScreen = location.search === '?intro=true' || location.pathname === '/'

  return (
    <ErrorBoundary>
      <DarkModeQueryParamReader />
      <Trace page={currentPage}>
        <HeaderWrapper transparent={isHeaderTransparent}>
          <NavBar blur={isHeaderTransparent} />
        </HeaderWrapper>
        <BodyWrapper>
          <Popups />
          <Polling />
          <TopLevelModals />
          <Suspense fallback={<Loader />}>
            {isLoaded ? (
              <Routes>
                <Route path="/" element={<HomePage />} />

                {/* <Route path="stake" element={<Stake />} />
                  <Route path="/stake/:poolAddress" element={<Manage />} /> */}

                <Route path="earn" element={<Earn />} />
                <Route path="earn/:currencyIdA" element={<SingleManage />} />
                <Route path="earn/:currencyIdA/:newPool" element={<SingleManage />} />
                <Route path="earn/:currencyIdA/:currencyIdB" element={<Manage />} />
                <Route path="earn/:currencyIdA/:currencyIdB/:newPool" element={<Manage />} />

                <Route path="wrap" element={<Wrap />} />
                <Route path="airdrop" element={<Airdrop />} />
                <Route path="Redeem" element={<Redeem />} />

                <Route
                  path="vote/*"
                  element={
                    <Suspense fallback={<LazyLoadSpinner />}>
                      <Vote />
                    </Suspense>
                  }
                />
                <Route path="create-proposal" element={<Navigate to="/vote/create-proposal" replace />} />
                <Route path="send" element={<RedirectPathToSwapOnly />} />
                <Route path="swap" element={<Swap />} />
                <Route path="ringx" element={<RingX />} />
                {/* <Route path="launchpad" element={<LaunchPad />} /> */}

                <Route path="pools" element={<PoolFew />} />
                <Route path="pools/few/find" element={<FewPoolFinder />} />
                <Route path="pools/few" element={<PoolFew />} />
                <Route path="pools/fewtoken" element={<PoolFew />} />
                <Route path="pools/v2/find" element={<FewPoolFinder />} />
                <Route path="pools/v2" element={<PoolFew />} />
                <Route path="pools/ring/find" element={<RingPoolFinder />} />
                <Route path="pools/ring" element={<PoolRing />} />
                <Route path="pools/v3" element={<Pool />} />
                <Route path="pools/v3/:tokenId" element={<PositionPage />} />
                <Route path="add/v2" element={<RedirectDuplicateTokenIdsFew />}>
                  <Route path=":currencyIdA" />
                  <Route path=":currencyIdA/:currencyIdB" />
                </Route>
                <Route path="add/few" element={<RedirectDuplicateTokenIdsFew />}>
                  <Route path=":currencyIdA" />
                  <Route path=":currencyIdA/:currencyIdB" />
                </Route>
                <Route path="add/ring" element={<RedirectDuplicateTokenIdsRing />}>
                  <Route path=":currencyIdA" />
                  <Route path=":currencyIdA/:currencyIdB" />
                </Route>
                <Route path="add/fewtoken" element={<RedirectDuplicateTokenIdsFewToken />}>
                  <Route path=":currencyIdA" />
                  <Route path=":currencyIdA/:currencyIdB" />
                </Route>
                {/* this is workaround since react-router-dom v6 doesn't support optional parameters any more */}
                <Route path="add" element={<RedirectDuplicateTokenIds />}>
                  <Route path=":currencyIdA" />
                  <Route path=":currencyIdA/:currencyIdB" />
                  <Route path=":currencyIdA/:currencyIdB/:feeAmount" />
                </Route>

                <Route path="increase" element={<AddLiquidity />}>
                  <Route path=":currencyIdA" />
                  <Route path=":currencyIdA/:currencyIdB" />
                  <Route path=":currencyIdA/:currencyIdB/:feeAmount" />
                  <Route path=":currencyIdA/:currencyIdB/:feeAmount/:tokenId" />
                </Route>

                <Route path="remove/:currencyIdA/:currencyIdB" element={<RemoveV2Liquidity />} />
                <Route path="remove/V2/:currencyIdA/:currencyIdB" element={<RemoveV2Liquidity />} />
                <Route path="remove/few/:currencyIdA/:currencyIdB" element={<RemoveV2Liquidity />} />
                <Route path="remove/v2/:currencyIdA/:currencyIdB" element={<RemoveV2Liquidity />} />
                <Route path="remove/fewtoken/:currencyIdA/:currencyIdB" element={<RemoveV2FewTokensLiquidity />} />
                <Route path="remove/ring/:currencyIdA/:currencyIdB" element={<RemoveLiquidityRing />} />
                <Route path="remove/v3/:tokenId" element={<RemoveLiquidityV3 />} />

                <Route path="/terms-of-service" element={<Terms />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />

                <Route path="*" element={<Navigate to="/not-found" replace />} />
                <Route path="/not-found" element={<NotFound />} />
              </Routes>
            ) : (
              <Loader />
            )}
          </Suspense>
        </BodyWrapper>
        <MobileBottomBar>
          <PageTabs />
        </MobileBottomBar>
      </Trace>
    </ErrorBoundary>
  )
}
