import { InterfaceElementName } from '@uniswap/analytics-events'
import { DollarSign, Terminal } from 'react-feather'
import styled from 'styled-components'
import { lightTheme } from 'theme/colors'

import darkDollarImgSrc from './images/aboutDollarDark.png'
import darkTerminalImgSrc from './images/aboutTerminalDark.png'
import nftCardImgSrc from './images/nftCard.png'
import swapCardImgSrc from './images/swapCard.png'

const StyledCardLogo = styled.img`
  min-width: 20px;
  min-height: 20px;
  max-height: 48px;
  max-width: 48px;
`

export const MAIN_CARDS = [
  {
    to: '/swap',
    title: 'Ring Swap',
    description: 'Swap, earn, and build on the leading decentralized crypto trading protocol.',
    cta: 'Trade Tokens',
    darkBackgroundImgSrc: nftCardImgSrc,
    lightBackgroundImgSrc: nftCardImgSrc,
    lightIcon: <Terminal color={lightTheme.neutral3} size={48} />,
    darkIcon: <StyledCardLogo src={darkTerminalImgSrc} alt="Developers" />,
    elementName: InterfaceElementName.ABOUT_PAGE_NFTS_CARD,
  },
  // {
  //   to: '/launchpad',
  //   title: 'Ring Launchpad',
  //   description: 'Receive Ring Governance Token (RGB) airdrops with every swap.',
  //   cta: 'Trading As Mining',
  //   darkBackgroundImgSrc: swapCardImgSrc,
  //   lightBackgroundImgSrc: swapCardImgSrc,
  //   lightIcon: <StyledCardLogo src={lightArrowImgSrc} alt="Analytics" />,
  //   darkIcon: <StyledCardLogo src={darkArrowImgSrc} alt="Analytics" />,
  //   elementName: InterfaceElementName.ABOUT_PAGE_SWAP_CARD,
  // },
  {
    to: '/pools',
    title: 'Ring Earn',
    description: 'Providing liquidity to pools on Ring Swap and earn fees on swaps.',
    cta: 'Provide Liquidity',
    darkBackgroundImgSrc: swapCardImgSrc,
    lightBackgroundImgSrc: swapCardImgSrc,
    lightIcon: <DollarSign color={lightTheme.neutral3} size={48} />,
    darkIcon: <StyledCardLogo src={darkDollarImgSrc} alt="Earn" />,
    elementName: InterfaceElementName.ABOUT_PAGE_SWAP_CARD,
  },
]

// export const MORE_CARDS = []
