import { Trans } from '@lingui/macro'
import { InterfaceEventName } from '@uniswap/analytics-events'
import { useWeb3React } from '@web3-react/core'
import { sendAnalyticsEvent } from 'analytics'
import { ChainId, Currency } from 'eth-mainnet-few-sdk-core-2'
import useNativeCurrency from 'lib/hooks/useNativeCurrency'
import { formatToDecimal, getTokenAddress } from 'lib/utils/analytics'
import tryParseCurrencyAmount from 'lib/utils/tryParseCurrencyAmount'
import { useMemo, useState } from 'react'

import { FEW_ETH_WRAPPER_ADDRESSES, FEW_WRAPPED_NATIVE_CURRENCY, WRAPPED_NATIVE_CURRENCY } from '../constants/tokens'
import { useCurrencyBalance } from '../state/connection/hooks'
import { useTransactionAdder } from '../state/transactions/hooks'
import { TransactionType } from '../state/transactions/types'
import { ApprovalState, useApproveCallback } from './useApproveCallback'
import { useFewETHWrapperContract, useFewTokenContract, useFewV1RouterContract, useWETHContract } from './useContract'
import { isFewToken } from './useWrappedToken'

export enum WrapType {
  NOT_APPLICABLE,
  WRAP,
  UNWRAP,
}

const NOT_APPLICABLE = { wrapType: WrapType.NOT_APPLICABLE }

enum WrapInputError {
  NO_ERROR, // must be equal to 0 so all other errors are truthy
  ENTER_NATIVE_AMOUNT,
  ENTER_WRAPPED_AMOUNT,
  INSUFFICIENT_NATIVE_BALANCE,
  INSUFFICIENT_WRAPPED_BALANCE,
}

export function WrapErrorText({ wrapInputError }: { wrapInputError: WrapInputError }) {
  const { chainId } = useWeb3React()
  const native = useNativeCurrency(chainId)
  const wrapped = native?.wrapped

  switch (wrapInputError) {
    case WrapInputError.NO_ERROR:
      return null
    case WrapInputError.ENTER_NATIVE_AMOUNT:
      return <Trans>Enter {native?.symbol} amount</Trans>
    case WrapInputError.ENTER_WRAPPED_AMOUNT:
      return <Trans>Enter {wrapped?.symbol} amount</Trans>

    case WrapInputError.INSUFFICIENT_NATIVE_BALANCE:
      return <Trans>Insufficient {native?.symbol} balance</Trans>
    case WrapInputError.INSUFFICIENT_WRAPPED_BALANCE:
      return <Trans>Insufficient {wrapped?.symbol} balance</Trans>
  }
}

// eslint-disable-next-line import/no-unused-modules
export function FewWrapErrorText({
  wrapInputError,
  wrapToken,
}: {
  wrapInputError: WrapInputError
  wrapToken?: string
}) {
  const { chainId } = useWeb3React()
  const native = useNativeCurrency(chainId)

  switch (wrapInputError) {
    case WrapInputError.NO_ERROR:
      return null
    case WrapInputError.ENTER_NATIVE_AMOUNT:
      return <Trans>Enter {native?.symbol} amount</Trans>
    case WrapInputError.ENTER_WRAPPED_AMOUNT:
      return <Trans>Enter {wrapToken} amount</Trans>

    case WrapInputError.INSUFFICIENT_NATIVE_BALANCE:
      return <Trans>Insufficient {native?.symbol} balance</Trans>
    case WrapInputError.INSUFFICIENT_WRAPPED_BALANCE:
      return <Trans>Insufficient {wrapToken} balance</Trans>
  }
}

/**
 * Given the selected input and output currency, return a wrap callback
 * @param inputCurrency the selected input currency
 * @param outputCurrency the selected output currency
 * @param typedValue the user input value
 */
export default function useWrapCallback(
  inputCurrency: Currency | undefined | null,
  outputCurrency: Currency | undefined | null,
  typedValue: string | undefined
): {
  wrapType: WrapType
  execute?: () => Promise<string | undefined>
  inputError?: WrapInputError
  approvalFewWrappedToken?: ApprovalState
  approveFewWrappedTokeCallback?: () => Promise<void>
} {
  const { chainId, account } = useWeb3React()
  const wethContract = useWETHContract()
  const fewETHWrapperContract = useFewETHWrapperContract(FEW_ETH_WRAPPER_ADDRESSES[chainId ?? ChainId.BLAST])
  const fewV1Router = useFewV1RouterContract()

  const balance = useCurrencyBalance(account ?? undefined, inputCurrency ?? undefined)
  // we can always parse the amount typed as the input currency, since wrapping is 1:1
  const inputAmount = useMemo(
    () => tryParseCurrencyAmount(typedValue, inputCurrency ?? undefined),
    [inputCurrency, typedValue]
  )
  const addTransaction = useTransactionAdder()

  // This allows an async error to propagate within the React lifecycle.
  // Without rethrowing it here, it would not show up in the UI - only the dev console.
  const [error, setError] = useState<Error>()
  if (error) throw error

  const outputCurrencyWrappedTokenContract = useFewTokenContract(outputCurrency?.wrapped.address)
  const inputCurrencyWrappedTokenContract = useFewTokenContract(inputCurrency?.wrapped.address)

  const [approvalFewWrappedToken, approveFewWrappedTokeCallback] = useApproveCallback(
    inputAmount,
    outputCurrency?.wrapped.address
  )

  return useMemo(() => {
    if (!wethContract || !fewETHWrapperContract || !fewV1Router || !chainId || !inputCurrency || !outputCurrency)
      return NOT_APPLICABLE
    const weth = WRAPPED_NATIVE_CURRENCY[chainId]
    const fwWETH = FEW_WRAPPED_NATIVE_CURRENCY[chainId]
    const inputCurrencyIsFewToken = isFewToken(inputCurrency.wrapped)
    const outputCurrencyIsFewToken = isFewToken(outputCurrency.wrapped)

    if (!weth || !fwWETH || (!weth && !inputCurrencyIsFewToken && !outputCurrencyIsFewToken)) return NOT_APPLICABLE

    const hasInputAmount = Boolean(inputAmount?.greaterThan('0'))
    const sufficientBalance = inputAmount && balance && !balance.lessThan(inputAmount)

    const eventProperties = {
      token_in_address: getTokenAddress(inputCurrency),
      token_out_address: getTokenAddress(outputCurrency),
      token_in_symbol: inputCurrency.symbol,
      token_out_symbol: outputCurrency.symbol,
      chain_id: inputCurrency.chainId,
      amount: inputAmount ? formatToDecimal(inputAmount, inputAmount?.currency.decimals) : undefined,
    }

    if (inputCurrency.isNative && weth.equals(outputCurrency)) {
      return {
        wrapType: WrapType.WRAP,
        execute:
          sufficientBalance && inputAmount
            ? async () => {
                const network = await wethContract.provider.getNetwork()
                if (
                  network.chainId !== chainId ||
                  wethContract.address !== WRAPPED_NATIVE_CURRENCY[network.chainId]?.address
                ) {
                  sendAnalyticsEvent(InterfaceEventName.WRAP_TOKEN_TXN_INVALIDATED, {
                    ...eventProperties,
                    contract_address: wethContract.address,
                    contract_chain_id: network.chainId,
                    type: WrapType.WRAP,
                  })
                  const error = new Error(`Invalid WETH contract
Please file a bug detailing how this happened - https://github.com/Uniswap/interface/issues/new?labels=bug&template=bug-report.md&title=Invalid%20WETH%20contract`)
                  setError(error)
                  throw error
                }
                const txReceipt = await wethContract.deposit({ value: `0x${inputAmount.quotient.toString(16)}` })
                addTransaction(txReceipt, {
                  type: TransactionType.WRAP,
                  unwrapped: false,
                  currencyAmountRaw: inputAmount?.quotient.toString(),
                  chainId,
                })
                sendAnalyticsEvent(InterfaceEventName.WRAP_TOKEN_TXN_SUBMITTED, {
                  ...eventProperties,
                  type: WrapType.WRAP,
                })
                return txReceipt.hash
              }
            : undefined,
        inputError: sufficientBalance
          ? undefined
          : hasInputAmount
          ? WrapInputError.INSUFFICIENT_NATIVE_BALANCE
          : WrapInputError.ENTER_NATIVE_AMOUNT,
      }
    } else if (weth.equals(inputCurrency) && outputCurrency.isNative) {
      return {
        wrapType: WrapType.UNWRAP,
        execute:
          sufficientBalance && inputAmount
            ? async () => {
                try {
                  const txReceipt = await wethContract.withdraw(`0x${inputAmount.quotient.toString(16)}`)
                  addTransaction(txReceipt, {
                    type: TransactionType.WRAP,
                    unwrapped: true,
                    currencyAmountRaw: inputAmount?.quotient.toString(),
                    chainId,
                  })
                  sendAnalyticsEvent(InterfaceEventName.WRAP_TOKEN_TXN_SUBMITTED, {
                    ...eventProperties,
                    type: WrapType.UNWRAP,
                  })
                  return txReceipt.hash
                } catch (error) {
                  console.error('Could not withdraw', error)
                  throw error
                }
              }
            : undefined,
        inputError: sufficientBalance
          ? undefined
          : hasInputAmount
          ? WrapInputError.INSUFFICIENT_WRAPPED_BALANCE
          : WrapInputError.ENTER_WRAPPED_AMOUNT,
      }
    } else if (
      !inputCurrencyIsFewToken &&
      outputCurrencyIsFewToken &&
      outputCurrency.name === `Few Wrapped ${inputCurrency.name}` &&
      outputCurrency.symbol === `fw${inputCurrency.symbol}`
    ) {
      return {
        wrapType: WrapType.WRAP,
        execute:
          sufficientBalance && inputAmount && outputCurrencyWrappedTokenContract
            ? async () => {
                const network = await outputCurrencyWrappedTokenContract.provider.getNetwork()
                if (
                  network.chainId !== chainId ||
                  outputCurrencyWrappedTokenContract.address !== outputCurrency.wrapped.address
                ) {
                  sendAnalyticsEvent(InterfaceEventName.WRAP_TOKEN_TXN_INVALIDATED, {
                    ...eventProperties,
                    contract_address: outputCurrencyWrappedTokenContract.address,
                    contract_chain_id: network.chainId,
                    type: WrapType.WRAP,
                  })
                  const error = new Error(`Invalid Few Wrapped Token contract
Please file a bug detailing how this happened - https://github.com/Uniswap/interface/issues/new?labels=bug&template=bug-report.md&title=Invalid%20WETH%20contract`)
                  setError(error)
                  throw error
                }
                const txReceipt = await outputCurrencyWrappedTokenContract.wrap(
                  `0x${inputAmount.quotient.toString(16)}`
                )
                addTransaction(txReceipt, {
                  type: TransactionType.FEW_WRAP,
                  CurrencyId: inputCurrency.wrapped.address,
                  FewCurrencyId: outputCurrency.wrapped.address,
                  unwrapped: false,
                  currencyAmountRaw: inputAmount?.quotient.toString(),
                  chainId,
                })
                sendAnalyticsEvent(InterfaceEventName.WRAP_TOKEN_TXN_SUBMITTED, {
                  ...eventProperties,
                  type: WrapType.WRAP,
                })
                return txReceipt.hash
              }
            : undefined,
        inputError: sufficientBalance
          ? undefined
          : hasInputAmount
          ? WrapInputError.INSUFFICIENT_WRAPPED_BALANCE
          : WrapInputError.ENTER_WRAPPED_AMOUNT,
        approvalFewWrappedToken,
        approveFewWrappedTokeCallback,
      }
    } else if (inputCurrency.isNative && fwWETH.equals(outputCurrency)) {
      return {
        wrapType: WrapType.WRAP,
        execute:
          sufficientBalance && inputAmount && fewETHWrapperContract && account
            ? async () => {
                const network = await fewETHWrapperContract.provider.getNetwork()
                if (network.chainId !== chainId) {
                  sendAnalyticsEvent(InterfaceEventName.WRAP_TOKEN_TXN_INVALIDATED, {
                    ...eventProperties,
                    contract_address: fewETHWrapperContract.address,
                    contract_chain_id: network.chainId,
                    type: WrapType.WRAP,
                  })
                  const error = new Error(`Invalid Few Wrapped Token contract
Please file a bug detailing how this happened - https://github.com/Uniswap/interface/issues/new?labels=bug&template=bug-report.md&title=Invalid%20WETH%20contract`)
                  setError(error)
                  throw error
                }
                const txReceipt = await fewETHWrapperContract.wrapETHToFWWETH(account, {
                  value: `0x${inputAmount.quotient.toString(16)}`,
                })
                addTransaction(txReceipt, {
                  type: TransactionType.FEW_WRAP,
                  CurrencyId: 'ETH',
                  FewCurrencyId: outputCurrency.wrapped.address,
                  unwrapped: false,
                  currencyAmountRaw: inputAmount?.quotient.toString(),
                  chainId,
                })
                sendAnalyticsEvent(InterfaceEventName.WRAP_TOKEN_TXN_SUBMITTED, {
                  ...eventProperties,
                  type: WrapType.WRAP,
                })
                return txReceipt.hash
              }
            : undefined,
        inputError: sufficientBalance
          ? undefined
          : hasInputAmount
          ? WrapInputError.INSUFFICIENT_NATIVE_BALANCE
          : WrapInputError.ENTER_NATIVE_AMOUNT,
        approvalFewWrappedToken,
        approveFewWrappedTokeCallback,
      }
    } else if (
      inputCurrencyIsFewToken &&
      !outputCurrencyIsFewToken &&
      inputCurrency.name === `Few Wrapped ${outputCurrency.name}` &&
      inputCurrency.symbol === `fw${outputCurrency.symbol}`
    ) {
      return {
        wrapType: WrapType.UNWRAP,
        execute:
          sufficientBalance && inputAmount && inputCurrencyWrappedTokenContract
            ? async () => {
                try {
                  const txReceipt = await inputCurrencyWrappedTokenContract.unwrap(
                    `0x${inputAmount.quotient.toString(16)}`
                  )
                  addTransaction(txReceipt, {
                    type: TransactionType.FEW_WRAP,
                    CurrencyId: outputCurrency.wrapped.address,
                    FewCurrencyId: inputCurrency.wrapped.address,
                    unwrapped: true,
                    currencyAmountRaw: inputAmount?.quotient.toString(),
                    chainId,
                  })
                  sendAnalyticsEvent(InterfaceEventName.WRAP_TOKEN_TXN_SUBMITTED, {
                    ...eventProperties,
                    type: WrapType.UNWRAP,
                  })
                  return txReceipt.hash
                } catch (error) {
                  console.error('Could not withdraw', error)
                  throw error
                }
              }
            : undefined,
        inputError: sufficientBalance
          ? undefined
          : hasInputAmount
          ? WrapInputError.INSUFFICIENT_WRAPPED_BALANCE
          : WrapInputError.ENTER_WRAPPED_AMOUNT,
      }
    } else if (fwWETH.equals(inputCurrency) && outputCurrency.isNative) {
      return {
        wrapType: WrapType.UNWRAP,
        execute:
          sufficientBalance && inputAmount && fewETHWrapperContract && account
            ? async () => {
                try {
                  const txReceipt = await fewETHWrapperContract.unwrapFWWETHToETH(
                    `0x${inputAmount.quotient.toString(16)}`,
                    account
                  )
                  addTransaction(txReceipt, {
                    type: TransactionType.FEW_WRAP,
                    CurrencyId: 'ETH',
                    FewCurrencyId: inputCurrency.wrapped.address,
                    unwrapped: true,
                    currencyAmountRaw: inputAmount?.quotient.toString(),
                    chainId,
                  })
                  sendAnalyticsEvent(InterfaceEventName.WRAP_TOKEN_TXN_SUBMITTED, {
                    ...eventProperties,
                    type: WrapType.UNWRAP,
                  })
                  return txReceipt.hash
                } catch (error) {
                  console.error('Could not withdraw', error)
                  throw error
                }
              }
            : undefined,
        inputError: sufficientBalance
          ? undefined
          : hasInputAmount
          ? WrapInputError.INSUFFICIENT_WRAPPED_BALANCE
          : WrapInputError.ENTER_WRAPPED_AMOUNT,
      }
    } else {
      return NOT_APPLICABLE
    }
  }, [
    wethContract,
    fewETHWrapperContract,
    fewV1Router,
    chainId,
    inputCurrency,
    outputCurrency,
    inputAmount,
    balance,
    addTransaction,
    outputCurrencyWrappedTokenContract,
    approvalFewWrappedToken,
    approveFewWrappedTokeCallback,
    account,
    inputCurrencyWrappedTokenContract,
  ])
}
