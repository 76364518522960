import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const PageWrapper = styled(Container)`
  flex: 1;
  justify-content: center;
  gap: 50px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    justify-content: space-between;
    padding-top: 64px;
  }
`

const TermsStyle = styled.div`
  max-width: 650px;
  margin: 0px auto 10rem auto;
  padding: 0 1rem;
  display: grid;
  font-size: 1.25rem;
`

const TermsTitle = styled.h1`
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 2.25rem;
  line-height: 2.5rem;
  font-weight: 400;
  text-align: center;
`

const TermDates = styled.h3`
  margin: 1em 0px 0.5em;
  font-weight: bold;
`

const TermParagraph = styled.p`
  text-align: justify;
  opacity: 0.94;
  margin-bottom: 1em;
`

const TermH3 = styled.h3`
  margin: 1em 0px 0.4em;
  font-weight: bold;
  font-size: 1.2em;
`

const TermH4 = styled.h4`
  margin: 1em 0px 0.5em;
  font-weight: bold;
`

const TermList = styled.ul`
  text-align: justify;
  list-style-type: circle;
  display: inline-block;
  margin-left: -1em;
`

const TermLineIetm = styled.li`
  margin: 0.5em 0px 0px 2em;
`

export default function ServiceTerms() {
  return (
    <PageWrapper>
      <Container>
        <TermsStyle>
          <TermsTitle>Ring Labs Terms of Service</TermsTitle>
          <TermDates>Last modified: February 20, 2024</TermDates>
          <TermParagraph>
            These Terms of Service (the &quot;Agreement&quot;) explain the terms and conditions by which you may access
            and use the Products provided by Ring Labs (referred to herein as &quot;Ring Labs&quot;, &quot;we&quot;,
            &quot;our&quot;, or &quot;us&quot;). The Products shall include, but shall not necessarily be limited to,
            <a> https://ring.exchange</a>, a website-hosted user interface (the &quot;Interface&quot; or
            &quot;App&quot;), You must read this Agreement carefully as it governs your use of the Products. By
            accessing or using any of the Products, you signify that you have read, understand, and agree to be bound by
            this Agreement in its entirety. If you do not agree, you are not authorized to access or use any of our
            Products and should not use our Products.
          </TermParagraph>
          <TermParagraph>
            To access or use any of our Products, you must be able to form a legally binding contract with us.
            Accordingly, you represent that you are at least the age of majority in your jurisdiction (e.g., 18 years
            old in the United States) and have the full right, power, and authority to enter into and comply with the
            terms and conditions of this Agreement on behalf of yourself and any company or legal entity for which you
            may access or use the Interface. If you are entering into this Agreement on behalf of an entity, you
            represent to us that you have the legal authority to bind such entity.
          </TermParagraph>
          <TermParagraph>
            You further represent that you are not (a) the subject of economic or trade sanctions administered or
            enforced by any governmental authority or otherwise designated on any list of prohibited or restricted
            parties (including but not limited to the list maintained by the Office of Foreign Assets Control of the
            U.S. Department of the Treasury) or (b) a citizen, resident, or organized in a jurisdiction or territory
            that is the subject of comprehensive country-wide, territory-wide, or regional economic sanctions by the
            United States. Finally, you represent that your access and use of any of our Products will fully comply with
            all applicable laws and regulations, and that you will not access or use any of our Products to conduct,
            promote, or otherwise facilitate any illegal activity.
          </TermParagraph>
          <TermParagraph>
            NOTICE: This Agreement contains important information, including a binding arbitration provision and a class
            action waiver, both of which impact your rights as to how disputes are resolved. Our Products are only
            available to you — and you should only access any of our Products — if you agree completely with these
            terms.
          </TermParagraph>
          <TermH3>1. Our Products</TermH3>
          <TermH4>1.1 The Interface</TermH4>
          <TermParagraph>
            The Interface provides a web or mobile-based means of access to a decentralized protocol on various public
            blockchains, including but not limited to Ethereum, that allows users to trade certain compatible digital
            assets (the &quot;Ring protocol&quot; or the &quot;Protocol&quot;).
          </TermParagraph>
          <TermParagraph>
            The Interface is distinct from the Protocol and is one, but not the exclusive, means of accessing the
            Protocol. The Protocol itself has three versions, designated as v2, each of which comprises open-source or
            source-available self-executing smart contracts that are deployed on various public blockchains, such as
            Ethereum. Ring Labs does not control or operate any version of the Protocol on any blockchain network. By
            using the Interface, you understand that you are not buying or selling digital assets from us and that we do
            not operate any liquidity pools on the Protocol or control trade execution on the Protocol. When traders pay
            fees for trades, those fees accrue to liquidity providers for the Protocol. As a general matter, Ring Labs
            is not a liquidity provider into Protocol liquidity pools and liquidity providers are independent third
            parties. The Protocol was initially deployed on the Ethereum blockchain, and has since been deployed on
            several other blockchain networks including by parties other than Ring Labs. Deployments on other networks
            typically make use of cross-chain bridges, which allow assets native to one blockchain to be transferred to
            another blockchain. Please note that digital assets that have been &quot;bridged&quot; or
            &quot;wrapped&quot; to operate on other blockchain networks (including to blockchains compatible with the
            Ethereum Virtual Machine that are designed to ensure the Ethereum blockchain can effectively process more
            transactions or other blockchains that are frequently referred to as &quot;Layer 2&quot; solutions) are
            distinct from the original Ethereum mainnet asset.
          </TermParagraph>
          <TermParagraph>
            To access the Interface, you must use a non-custodial wallet software, which allows you to interact with
            public blockchains. Your relationship with that non-custodial wallet provider is governed by the applicable
            terms of service (with respect to this Agreement and with respect to a third party wallet, the applicable
            terms of service of such third party). We do not have custody or control over the contents of your wallet
            and have no ability to retrieve or transfer its contents. By connecting your wallet to our Interface, you
            agree to be bound by this Agreement and all of the terms incorporated herein by reference.
          </TermParagraph>
          <TermH4>1.2 Other Products</TermH4>
          <TermParagraph>
            We may from time to time in the future offer additional products, and such additional products shall be
            considered a Product as used herein, regardless of whether such product is specifically defined in this
            Agreement.
          </TermParagraph>
          <TermH4>1.3 Third Party Services and Content</TermH4>
          <TermParagraph>
            When you use any of our Products, you may also be using the products, services or content of one or more
            third parties. Your use of such third party products, services or content may be subject to separate
            policies, terms of use and fees of these third parties, and you agree to abide by and be responsible for
            such policies, terms of use and fees, as applicable.{' '}
          </TermParagraph>
          <TermH3>2. Modifications of this Agreement or our Products</TermH3>
          <TermH4>2.1 Modifications of this Agreement</TermH4>
          <TermParagraph>
            We reserve the right, in our sole discretion, to modify this Agreement from time to time. If we make any
            material modifications, we will notify you by updating the date at the top of the Agreement and by
            maintaining a current version of the Agreement at <a>https://ring.exchange/#/terms-of-service</a>. All
            modifications will be effective when they are posted, and your continued accessing or use of any of the
            Products will serve as confirmation of your acceptance of those modifications. If you do not agree with any
            modifications to this Agreement, you must immediately stop accessing and using all of our Products.
          </TermParagraph>
          <TermH4>2.2 Modifications of our Products</TermH4>
          <TermParagraph>
            We reserve the following rights, which do not constitute obligations of ours: (a) with or without notice to
            you, to modify, substitute, eliminate or add to any of the Products; (b) to review, modify, filter, disable,
            delete and remove any and all content and information from any of the Products.
          </TermParagraph>
          <TermH3>3. Intellectual Property Rights</TermH3>
          <TermH4>3.1 IP Rights Generally</TermH4>
          <TermParagraph>
            We own all intellectual property and other rights in each of our Products and its respective contents,
            including, but not limited to, software, text, images, trademarks, service marks, copyrights, patents,
            designs, and its &quot;look and feel.&quot; This intellectual property is available under the terms of our
            copyright licenses and our Trademark Guidelines. Subject to the terms of this Agreement, we grant you a
            limited, revocable, non-exclusive, non-sublicensable, non-transferable license to access and use our
            Products solely in accordance with this Agreement. You agree that you will not use, modify, distribute,
            tamper with, reverse engineer, disassemble or decompile any of our Products for any purpose other than as
            expressly permitted pursuant to this Agreement. Except as set forth in this Agreement, we grant you no
            rights to any of our Products, including any intellectual property rights.
          </TermParagraph>
          <TermParagraph>
            You understand and acknowledge that the Protocol is not a Product and we do not control the Protocol.
          </TermParagraph>
          <TermParagraph>
            By using any of our Products, you grant us a worldwide, non-exclusive, sublicensable, royalty-free license
            to use, copy, modify, and display any content, including but not limited to text, materials, images, files,
            communications, comments, feedback, suggestions, ideas, concepts, questions, data, or otherwise, that you
            post on or through any of our Products for our current and future business purposes, including to provide,
            promote, and improve the services. This includes any digital file, art, or other material linked to or
            associated with any NFTs that are displayed. You grant to us a non-exclusive, transferable, worldwide,
            perpetual, irrevocable, fully-paid, royalty-free license, with the right to sublicense, under any and all
            intellectual property rights that you own or control to use, copy, modify, create derivative works based
            upon any suggestions or feedback for any purpose.
          </TermParagraph>
          <TermParagraph>
            You represent and warrant that you have, or have obtained, all rights, licenses, consents, permissions,
            power and/or authority necessary to grant the rights granted herein for any material that you list, post,
            promote, or display on or through any of our Products. You represent and warrant that such content does not
            contain material subject to copyright, trademark, publicity rights, or other intellectual property rights,
            unless you have necessary permission or are otherwise legally entitled to post the material and to grant us
            the license described above, and that the content does not violate any laws.
          </TermParagraph>
          <TermH4>3.2 Third-Party Resources and Promotions</TermH4>
          <TermParagraph>
            Our Products may contain references or links to third-party resources, including, but not limited to,
            information, materials, products, or services, that we do not own or control. In addition, third parties may
            offer promotions related to your access and use of our Products. We do not approve, monitor, endorse,
            warrant or assume any responsibility for any such resources or promotions. If you access any such resources
            or participate in any such promotions, you do so at your own risk, and you understand that this Agreement
            does not apply to your dealings or relationships with any third parties. You expressly relieve us of any and
            all liability arising from your use of any such resources or participation in any such promotions.
          </TermParagraph>
          <TermH4>3.3 Additional Rights</TermH4>
          <TermParagraph>
            We reserve the right to cooperate with any law enforcement, court or government investigation or order or
            third party requesting or directing that we disclose information or content or information that you provide.
          </TermParagraph>
          <TermH3>4. Your Responsibilities</TermH3>

          <TermH4>4.1 Prohibited Activity</TermH4>
          <TermParagraph>
            You agree not to engage in, or attempt to engage in, any of the following categories of prohibited activity
            in relation to your access and use of the Interface:
          </TermParagraph>
          <TermList>
            <TermLineIetm>
              Intellectual Property Infringement. Activity that infringes on or violates any copyright, trademark,
              service mark, patent, right of publicity, right of privacy, or other proprietary or intellectual property
              rights under the law.
            </TermLineIetm>
            <TermLineIetm>
              Cyberattack. Activity that seeks to interfere with or compromise the integrity, security, or proper
              functioning of any computer, server, network, personal device, or other information technology system,
              including, but not limited to, the deployment of viruses and denial of service attacks.
            </TermLineIetm>
            <TermLineIetm>
              Fraud and Misrepresentation. Activity that seeks to defraud us or any other person or entity, including,
              but not limited to, providing any false, inaccurate, or misleading information in order to unlawfully
              obtain the property of another.
            </TermLineIetm>
            <TermLineIetm>
              Market Manipulation. Activity that violates any applicable law, rule, or regulation concerning the
              integrity of trading markets, including, but not limited to, the manipulative tactics commonly known as
              &quot;rug pulls&quot;, pumping and dumping, and wash trading.
            </TermLineIetm>
            <TermLineIetm>
              Securities and Derivatives Violations. Activity that violates any applicable law, rule, or regulation
              concerning the trading of securities or derivatives, including, but not limited to, the unregistered
              offering of securities and the offering of leveraged and margined commodity products to retail customers
              in the United States.
            </TermLineIetm>
            <TermLineIetm>
              Sale of Stolen Property. Buying, selling, or transferring of stolen items, fraudulently obtained items,
              items taken without authorization, and/or any other illegally obtained items.
            </TermLineIetm>
            <TermLineIetm>
              Data Mining or Scraping. Activity that involves data mining, robots, scraping, or similar data gathering
              or extraction methods of content or information from any of our Products.
            </TermLineIetm>
            <TermLineIetm>
              Objectionable Content. Activity that involves soliciting information from anyone under the age of 18 or
              that is otherwise harmful, threatening, abusive, harassing, tortious, excessively violent, defamatory,
              vulgar, obscene, pornographic, libelous, invasive of another&apos;s privacy, hateful, discriminatory, or
              otherwise objectionable.
            </TermLineIetm>
            <TermLineIetm>
              Any Other Unlawful Conduct. Activity that violates any applicable law, rule, or regulation of the United
              States or another relevant jurisdiction, including, but not limited to, the restrictions and regulatory
              requirements imposed by U.S. law.
            </TermLineIetm>
          </TermList>
          <TermH4>4.2 Trading</TermH4>
          <TermParagraph>
            You agree and understand that: (a) all trades you submit through any of our Products are considered
            unsolicited, which means that they are solely initiated by you; (b) you have not received any investment
            advice from us in connection with any trades, including those you place via our Auto Routing API; and (c) we
            do not conduct a suitability review of any trades you submit.
          </TermParagraph>
          <TermH4>4.3 Non-Custodial and No Fiduciary Duties</TermH4>
          <TermParagraph>
            Each of the Products is a purely non-custodial application, meaning we do not ever have custody, possession,
            or control of your digital assets at any time. It further means you are solely responsible for the custody
            of the cryptographic private keys to the digital asset wallets you hold and you should never share your
            wallet credentials or seed phrase with anyone. We accept no responsibility for, or liability to you, in
            connection with your use of a wallet and make no representations or warranties regarding how any of our
            Products will operate with any specific wallet. Likewise, you are solely responsible for any associated
            wallet and we are not liable for any acts or omissions by you in connection with or as a result of your
            wallet being compromised.
          </TermParagraph>
          <TermParagraph>
            This Agreement is not intended to, and does not, create or impose any fiduciary duties on us. To the fullest
            extent permitted by law, you acknowledge and agree that we owe no fiduciary duties or liabilities to you or
            any other party, and that to the extent any such duties or liabilities may exist at law or in equity, those
            duties and liabilities are hereby irrevocably disclaimed, waived, and eliminated. You further agree that the
            only duties and obligations that we owe you are those set out expressly in this Agreement.
          </TermParagraph>
          <TermH4>4.4 Compliance and Tax Obligations</TermH4>
          <TermParagraph>
            One or more of our Products may not be available or appropriate for use in your jurisdiction. By accessing
            or using any of our Products, you agree that you are solely and entirely responsible for compliance with all
            laws and regulations that may apply to you.
          </TermParagraph>
          <TermParagraph>
            Specifically, your use of our Products or the Protocol may result in various tax consequences, such as
            income or capital gains tax, value-added tax, goods and services tax, or sales tax in certain jurisdictions.
          </TermParagraph>
          <TermParagraph>
            It is your responsibility to determine whether taxes apply to any transactions you initiate or receive and,
            if so, to report and/or remit the correct tax to the appropriate tax authority.
          </TermParagraph>
          <TermH4>4.5 Gas Fees</TermH4>
          <TermParagraph>
            Blockchain transactions require the payment of transaction fees to the appropriate network (&quot;Gas
            Fees&quot;). Except as otherwise expressly set forth in the terms of another offer by Ring Labs, you will be
            solely responsible to pay the Gas Fees for any transaction that you initiate via any of our Products.
          </TermParagraph>
          <TermH4>4.6 Release of Claims</TermH4>
          <TermParagraph>
            You expressly agree that you assume all risks in connection with your access and use of any of our Products.
            You further expressly waive and release us from any and all liability, claims, causes of action, or damages
            arising from or in any way relating to your use of any of our Products. If you are a California resident,
            you waive the benefits and protections of California Civil Code § 1542, which provides: &quot;[a] general
            release does not extend to claims that the creditor or releasing party does not know or suspect to exist in
            his or her favor at the time of executing the release and that, if known by him or her, would have
            materially affected his or her settlement with the debtor or released party.&quot;
          </TermParagraph>
          <TermH3>5. DISCLAIMERS</TermH3>
          <TermH4>5.1 ASSUMPTION OF RISK -- GENERALLY</TermH4>
          <TermParagraph>
            BY ACCESSING AND USING ANY OF OUR PRODUCTS, YOU REPRESENT THAT YOU ARE FINANCIALLY AND TECHNICALLY
            SOPHISTICATED ENOUGH TO UNDERSTAND THE INHERENT RISKS ASSOCIATED WITH USING CRYPTOGRAPHIC AND
            BLOCKCHAIN-BASED SYSTEMS, AND THAT YOU HAVE A WORKING KNOWLEDGE OF THE USAGE AND INTRICACIES OF DIGITAL
            ASSETS SUCH AS ETHER (ETH), SO-CALLED STABLECOINS, AND OTHER DIGITAL TOKENS SUCH AS THOSE FOLLOWING THE
            ETHEREUM TOKEN STANDARD (ERC-20).
          </TermParagraph>
          <TermParagraph>
            IN PARTICULAR, YOU UNDERSTAND THAT THE MARKETS FOR THESE DIGITAL ASSETS ARE NASCENT AND HIGHLY VOLATILE DUE
            TO RISK FACTORS INCLUDING, BUT NOT LIMITED TO, ADOPTION, SPECULATION, TECHNOLOGY, SECURITY, AND REGULATION.
            YOU UNDERSTAND THAT ANYONE CAN CREATE A TOKEN, INCLUDING FAKE VERSIONS OF EXISTING TOKENS AND TOKENS THAT
            FALSELY CLAIM TO REPRESENT PROJECTS, AND ACKNOWLEDGE AND ACCEPT THE RISK THAT YOU MAY MISTAKENLY TRADE THOSE
            OR OTHER TOKENS. SO-CALLED STABLECOINS MAY NOT BE AS STABLE AS THEY PURPORT TO BE, MAY NOT BE FULLY OR
            ADEQUATELY COLLATERALIZED, AND MAY BE SUBJECT TO PANICS AND RUNS.
          </TermParagraph>
          <TermParagraph>
            FURTHER, YOU UNDERSTAND THAT SMART CONTRACT TRANSACTIONS AUTOMATICALLY EXECUTE AND SETTLE, AND THAT
            BLOCKCHAIN-BASED TRANSACTIONS ARE IRREVERSIBLE WHEN CONFIRMED. YOU ACKNOWLEDGE AND ACCEPT THAT THE COST AND
            SPEED OF TRANSACTING WITH CRYPTOGRAPHIC AND BLOCKCHAIN-BASED SYSTEMS SUCH AS ETHEREUM ARE VARIABLE AND MAY
            INCREASE DRAMATICALLY AT ANY TIME. YOU FURTHER ACKNOWLEDGE AND ACCEPT THE RISK OF SELECTING TO TRADE IN
            EXPERT MODES, WHICH CAN EXPOSE YOU TO POTENTIALLY SIGNIFICANT PRICE SLIPPAGE AND HIGHER COSTS.
          </TermParagraph>
          <TermParagraph>
            IF YOU ACT AS A LIQUIDITY PROVIDER TO THE PROTOCOL THROUGH THE INTERFACE, YOU UNDERSTAND THAT YOUR DIGITAL
            ASSETS MAY LOSE SOME OR ALL OF THEIR VALUE WHILE THEY ARE SUPPLIED TO THE PROTOCOL THROUGH THE INTERFACE DUE
            TO THE FLUCTUATION OF PRICES OF TOKENS IN A TRADING PAIR OR LIQUIDITY POOL.
          </TermParagraph>
          <TermParagraph>
            FINALLY, YOU UNDERSTAND THAT WE DO NOT CREATE, OWN, OR OPERATE CROSS-CHAIN BRIDGES AND WE DO NOT MAKE ANY
            REPRESENTATION OR WARRANTY ABOUT THE SAFETY OR SOUNDNESS OF ANY CROSS-CHAIN BRIDGE, INCLUDING ITS USE FOR
            Ring GOVERNANCE.
          </TermParagraph>
          <TermParagraph>
            IN SUMMARY, YOU ACKNOWLEDGE THAT WE ARE NOT RESPONSIBLE FOR ANY OF THESE VARIABLES OR RISKS, DO NOT OWN OR
            CONTROL THE PROTOCOL, AND CANNOT BE HELD LIABLE FOR ANY RESULTING LOSSES THAT YOU EXPERIENCE WHILE ACCESSING
            OR USING ANY OF OUR PRODUCTS. ACCORDINGLY, YOU UNDERSTAND AND AGREE TO ASSUME FULL RESPONSIBILITY FOR ALL OF
            THE RISKS OF ACCESSING AND USING THE INTERFACE TO INTERACT WITH THE PROTOCOL.**
          </TermParagraph>
          <TermH4>5.2 NO WARRANTIES</TermH4>
          <TermParagraph>
            EACH OF OUR PRODUCTS ARE PROVIDED ON AN &quot;AS IS&quot; AND &quot;AS AVAILABLE&quot; BASIS. TO THE FULLEST
            EXTENT PERMITTED BY LAW, WE DISCLAIM ANY REPRESENTATIONS AND WARRANTIES OF ANY KIND, WHETHER EXPRESS,
            IMPLIED, OR STATUTORY, INCLUDING, BUT NOT LIMITED TO, THE WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A
            PARTICULAR PURPOSE. YOU ACKNOWLEDGE AND AGREE THAT YOUR USE OF EACH OF OUR PRODUCTS IS AT YOUR OWN RISK. WE
            DO NOT REPRESENT OR WARRANT THAT ACCESS TO ANY OF OUR PRODUCTS WILL BE CONTINUOUS, UNINTERRUPTED, TIMELY, OR
            SECURE; THAT THE INFORMATION CONTAINED IN ANY OF OUR PRODUCTS WILL BE ACCURATE, RELIABLE, COMPLETE, OR
            CURRENT; OR THAT ANY OF OUR PRODUCTS WILL BE FREE FROM ERRORS, DEFECTS, VIRUSES, OR OTHER HARMFUL ELEMENTS.
            NO ADVICE, INFORMATION, OR STATEMENT THAT WE MAKE SHOULD BE TREATED AS CREATING ANY WARRANTY CONCERNING ANY
            OF OUR PRODUCTS. WE DO NOT ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY ADVERTISEMENTS, OFFERS, OR
            STATEMENTS MADE BY THIRD PARTIES CONCERNING ANY OF OUR PRODUCTS.
          </TermParagraph>
          <TermParagraph>
            SIMILARLY, THE PROTOCOL IS PROVIDED &quot;AS IS&quot;, AT YOUR OWN RISK, AND WITHOUT WARRANTIES OF ANY KIND.
            ALTHOUGH WE CONTRIBUTED TO THE INITIAL CODE FOR THE PROTOCOL, WE DO NOT PROVIDE, OWN, OR CONTROL THE
            PROTOCOL, WHICH IS RUN AUTONOMOUSLY WITHOUT ANY HEADCOUNT BY SMART CONTRACTS DEPLOYED ON VARIOUS
            BLOCKCHAINS. UPGRADES AND MODIFICATIONS TO THE PROTOCOL ARE GENERALLY MANAGED IN A COMMUNITY-DRIVEN WAY BY
            HOLDERS OF THE UNI GOVERNANCE TOKEN. NO DEVELOPER OR ENTITY INVOLVED IN CREATING THE PROTOCOL WILL BE LIABLE
            FOR ANY CLAIMS OR DAMAGES WHATSOEVER ASSOCIATED WITH YOUR USE, INABILITY TO USE, OR YOUR INTERACTION WITH
            OTHER USERS OF, THE PROTOCOL, INCLUDING ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE OR
            CONSEQUENTIAL DAMAGES, OR LOSS OF PROFITS, CRYPTOCURRENCIES, TOKENS, OR ANYTHING ELSE OF VALUE. WE DO NOT
            ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY ADVERTISEMENTS, OFFERS, OR STATEMENTS MADE BY THIRD
            PARTIES CONCERNING ANY OF OUR PRODUCTS.
          </TermParagraph>
          <TermParagraph>
            ANY PAYMENTS OR FINANCIAL TRANSACTIONS THAT YOU ENGAGE IN WILL BE PROCESSED VIA AUTOMATED SMART CONTRACTS.
            ONCE EXECUTED, WE HAVE NO CONTROL OVER THESE PAYMENTS OR TRANSACTIONS, NOR DO WE HAVE THE ABILITY TO REVERSE
            ANY PAYMENTS OR TRANSACTIONS.
          </TermParagraph>
          <TermH4>5.4 NO INVESTMENT ADVICE</TermH4>
          <TermParagraph>
            WE MAY PROVIDE INFORMATION ABOUT TOKENS IN OUR PRODUCTS SOURCED FROM THIRD-PARTY DATA PARTNERS THROUGH
            FEATURES SUCH AS RARITY SCORES, TOKEN EXPLORER OR TOKEN LISTS (WHICH INCLUDES THE Ring LABS DEFAULT TOKEN
            LIST AND Ring LABS EXPANDED LIST HOSTED AT TOKENLISTS.ORG). WE MAY ALSO PROVIDE WARNING LABELS FOR CERTAIN
            TOKENS. THE PROVISION OF INFORMATIONAL MATERIALS DOES NOT MAKE TRADES IN THOSE TOKENS SOLICITED; WE ARE NOT
            ATTEMPTING TO INDUCE YOU TO MAKE ANY PURCHASE AS A RESULT OF INFORMATION PROVIDED. ALL SUCH INFORMATION
            PROVIDED BY ANY OF OUR PRODUCTS IS FOR INFORMATIONAL PURPOSES ONLY AND SHOULD NOT BE CONSTRUED AS INVESTMENT
            ADVICE OR A RECOMMENDATION THAT A PARTICULAR TOKEN IS A SAFE OR SOUND INVESTMENT. YOU SHOULD NOT TAKE, OR
            REFRAIN FROM TAKING, ANY ACTION BASED ON ANY INFORMATION CONTAINED IN ANY OF OUR PRODUCTS. BY PROVIDING
            TOKEN INFORMATION FOR YOUR CONVENIENCE, WE DO NOT MAKE ANY INVESTMENT RECOMMENDATIONS TO YOU OR OPINE ON THE
            MERITS OF ANY TRANSACTION OR OPPORTUNITY. YOU ALONE ARE RESPONSIBLE FOR DETERMINING WHETHER ANY INVESTMENT,
            INVESTMENT STRATEGY OR RELATED TRANSACTION IS APPROPRIATE FOR YOU BASED ON YOUR PERSONAL INVESTMENT
            OBJECTIVES, FINANCIAL CIRCUMSTANCES, AND RISK TOLERANCE.
          </TermParagraph>
          <TermH3>6. Indemnification</TermH3>
          <TermParagraph>
            You agree to hold harmless, release, defend, and indemnify us and our officers, directors, employees,
            contractors, agents, affiliates, and subsidiaries from and against all claims, damages, obligations, losses,
            liabilities, costs, and expenses arising from: (a) your access and use of any of our Products; (b) your
            violation of any term or condition of this Agreement, the right of any third party, or any other applicable
            law, rule, or regulation; (c) any other party&apos;s access and use of any of our Products with your
            assistance or using any device or account that you own or control; and (d) any dispute between you and (i)
            any other user of any of the Products or (ii) any of your own customers or users.
          </TermParagraph>
          <TermH3>7. Limitation of Liability</TermH3>
          <TermParagraph>
            UNDER NO CIRCUMSTANCES SHALL WE OR ANY OF OUR OFFICERS, DIRECTORS, EMPLOYEES, CONTRACTORS, AGENTS,
            AFFILIATES, OR SUBSIDIARIES BE LIABLE TO YOU FOR ANY INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL,
            OR EXEMPLARY DAMAGES, INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA, OR
            OTHER INTANGIBLE PROPERTY, ARISING OUT OF OR RELATING TO ANY ACCESS OR USE OF OR INABILITY TO ACCESS OR USE
            ANY OF THE PRODUCTS, NOR WILL WE BE RESPONSIBLE FOR ANY DAMAGE, LOSS, OR INJURY RESULTING FROM HACKING,
            TAMPERING, OR OTHER UNAUTHORIZED ACCESS OR USE OF ANY OF THE PRODUCTS OR THE INFORMATION CONTAINED WITHIN
            IT, WHETHER SUCH DAMAGES ARE BASED IN CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, OR OTHERWISE, ARISING
            OUT OF OR IN CONNECTION WITH AUTHORIZED OR UNAUTHORIZED USE OF ANY OF THE PRODUCTS, EVEN IF AN AUTHORIZED
            REPRESENTATIVE OF Ring LABS HAS BEEN ADVISED OF OR KNEW OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH
            DAMAGES. WE ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY: (A) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT;
            (B) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM ANY ACCESS OR USE OF THE
            INTERFACE; (C) UNAUTHORIZED ACCESS OR USE OF ANY SECURE SERVER OR DATABASE IN OUR CONTROL, OR THE USE OF ANY
            INFORMATION OR DATA STORED THEREIN; (D) INTERRUPTION OR CESSATION OF FUNCTION RELATED TO ANY OF THE
            PRODUCTS; (E) BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE THAT MAY BE TRANSMITTED TO OR THROUGH THE INTERFACE;
            (F) ERRORS OR OMISSIONS IN, OR LOSS OR DAMAGE INCURRED AS A RESULT OF THE USE OF, ANY CONTENT MADE AVAILABLE
            THROUGH ANY OF THE PRODUCTS; AND (G) THE DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT OF ANY THIRD PARTY.
          </TermParagraph>
          <TermParagraph>
            WE HAVE NO LIABILITY TO YOU OR TO ANY THIRD PARTY FOR ANY CLAIMS OR DAMAGES THAT MAY ARISE AS A RESULT OF
            ANY PAYMENTS OR TRANSACTIONS THAT YOU ENGAGE IN VIA ANY OF OUR PRODUCTS, OR ANY OTHER PAYMENT OR
            TRANSACTIONS THAT YOU CONDUCT VIA ANY OF OUR PRODUCTS. EXCEPT AS EXPRESSLY PROVIDED FOR HEREIN, WE DO NOT
            PROVIDE REFUNDS FOR ANY PURCHASES THAT YOU MIGHT MAKE ON OR THROUGH ANY OF OUR PRODUCTS.
          </TermParagraph>
          <TermParagraph>
            WE MAKE NO WARRANTIES OR REPRESENTATIONS, EXPRESS OR IMPLIED, ABOUT LINKED THIRD PARTY SERVICES, THE THIRD
            PARTIES THEY ARE OWNED AND OPERATED BY, THE INFORMATION CONTAINED ON THEM, ASSETS AVAILABLE THROUGH THEM, OR
            THE SUITABILITY, PRIVACY, OR SECURITY OF THEIR PRODUCTS OR SERVICES. YOU ACKNOWLEDGE SOLE RESPONSIBILITY FOR
            AND ASSUME ALL RISK ARISING FROM YOUR USE OF THIRD-PARTY SERVICES, THIRD-PARTY WEBSITES, APPLICATIONS, OR
            RESOURCES. WE SHALL NOT BE LIABLE UNDER ANY CIRCUMSTANCES FOR DAMAGES ARISING OUT OF OR IN ANY WAY RELATED
            TO SOFTWARE, PRODUCTS, SERVICES, AND/OR INFORMATION OFFERED OR PROVIDED BY THIRD-PARTIES AND ACCESSED
            THROUGH ANY OF OUR PRODUCTS.
          </TermParagraph>
          <TermParagraph>
            SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OF LIABILITY FOR PERSONAL INJURY, OR OF INCIDENTAL OR
            CONSEQUENTIAL DAMAGES, SO THIS LIMITATION MAY NOT APPLY TO YOU. IN NO EVENT SHALL OUR TOTAL LIABILITY TO YOU
            FOR ALL DAMAGES (OTHER THAN AS MAY BE REQUIRED BY APPLICABLE LAW IN CASES INVOLVING PERSONAL INJURY) EXCEED
            THE AMOUNT OF ONE HUNDRED U.S. DOLLARS ($100.00 USD) OR ITS EQUIVALENT IN THE LOCAL CURRENCY OF THE
            APPLICABLE JURISDICTION.
          </TermParagraph>
          <TermParagraph>THE FOREGOING DISCLAIMER WILL NOT APPLY TO THE EXTENT PROHIBITED BY LAW.</TermParagraph>

          <TermH3>8. Governing Law, Dispute Resolution and Class Action Waivers</TermH3>
          <TermH4>8.1 Governing Law</TermH4>
          <TermParagraph>
            You agree that the laws of the State of New York, without regard to principles of conflict of laws, govern
            this Agreement and any Dispute between you and us. You further agree that each of our Products shall be
            deemed to be based solely in the State of New York, and that although a Product may be available in other
            jurisdictions, its availability does not give rise to general or specific personal jurisdiction in any forum
            outside the State of New York. The parties acknowledge that this Agreement evidences interstate commerce.
            Any arbitration conducted pursuant to this Agreement shall be governed by the Federal Arbitration Act. You
            agree that the federal and state courts of New York County, New York are the proper forum for any appeals of
            an arbitration award or for court proceedings in the event that this Agreement&quot;s binding arbitration
            clause is found to be unenforceable.
          </TermParagraph>
          <TermH4>8.2 Dispute Resolution</TermH4>
          <TermParagraph>
            We will use our best efforts to resolve any potential disputes through informal, good faith negotiations. If
            a potential dispute arises, you must contact us by sending an email to hello@ringprotocol.com so that we can
            attempt to resolve it without resorting to formal dispute resolution. If we aren&quot;t able to reach an
            informal resolution within sixty days of your email, then you and we both agree to resolve the potential
            dispute according to the process set forth below.
          </TermParagraph>
          <TermParagraph>
            Any claim or controversy arising out of or relating to any of our Products, this Agreement, or any other
            acts or omissions for which you may contend that we are liable, including, but not limited to, any claim or
            controversy as to arbitrability (&quot;Dispute&quot;), shall be finally and exclusively settled by
            arbitration under the JAMS Optional Expedited Arbitration Procedures. You understand that you are required
            to resolve all Disputes by binding arbitration. The arbitration shall be held on a confidential basis before
            a single arbitrator, who shall be selected pursuant to JAMS rules. The arbitration will be held in New York,
            New York, unless you and we both agree to hold it elsewhere. Unless we agree otherwise, the arbitrator may
            not consolidate your claims with those of any other party. Any judgment on the award rendered by the
            arbitrator may be entered in any court of competent jurisdiction. If for any reason a claim by law or equity
            must proceed in court rather than in arbitration you agree to waive any right to a jury trial and any claim
            may be brought only in a Federal District Court or a New York state court located in New York County, New
            York.
          </TermParagraph>
          <TermH4>8.3 Class Action and Jury Trial Waiver</TermH4>
          <TermParagraph>
            You must bring any and all Disputes against us in your individual capacity and not as a plaintiff in or
            member of any purported class action, collective action, private attorney general action, or other
            representative proceeding. This provision applies to class arbitration. You and we both agree to waive the
            right to demand a trial by jury.
          </TermParagraph>
          <TermH3>9. Miscellaneous</TermH3>
          <TermH4>9.1 Entire Agreement</TermH4>
          <TermParagraph>
            These terms constitute the entire agreement between you and us with respect to the subject matter hereof.
            This Agreement supersedes any and all prior or contemporaneous written and oral agreements, communications
            and other understandings (if any) relating to the subject matter of the terms.
          </TermParagraph>
          <TermH4>9.2 Assignment</TermH4>
          <TermParagraph>
            You may not assign or transfer this Agreement, by operation of law or otherwise, without our prior written
            consent. Any attempt by you to assign or transfer this Agreement without our prior written consent shall be
            null and void. We may freely assign or transfer this Agreement. Subject to the foregoing, this Agreement
            will bind and inure to the benefit of the parties, their successors and permitted assigns.
          </TermParagraph>

          <TermH4>9.3 Rewards</TermH4>
          <TermParagraph>
            In connection with your historic or current use of one or more of our Products, we may provide you certain
            incentives, prizes or rewards for completing certain activities, such as completing a certain number of
            transactions (&quot;User Rewards&quot;). Details regarding the criteria for earning a reward will be
            described within the applicable Product or official Ring Labs documentation. Upon satisfaction of the
            criteria for obtaining a reward and subject to your compliance with the associated rewards terms, this
            Agreement, and applicable law — to be determined exclusively by Ring Labs — we will use commercially
            reasonable efforts to promptly transfer the earned reward to the digital wallet that you designate or have
            connected to the applicable Product. We reserve the right to change, modify, discontinue or cancel any
            rewards programs (including the frequency and criteria for earning such User Rewards), at any time and
            without notice to you.
          </TermParagraph>
          <TermH4>9.4 Not Registered with the SEC or Any Other Agency</TermH4>
          <TermParagraph>
            We are not registered with the U.S. Securities and Exchange Commission as a national securities exchange or
            in any other capacity. You understand and acknowledge that we do not broker trading orders on your behalf.
            We also do not facilitate the execution or settlement of your trades, which occur entirely on public
            distributed blockchains like Ethereum. As a result, we do not (and cannot) guarantee market best pricing or
            best execution through our Products or when using our Auto Routing feature, which routes trades across
            liquidity pools on the Protocol only. Any references in a Product to &quot;best price&quot; does not
            constitute a representation or warranty about pricing available through such Product, on the Protocol, or
            elsewhere.
          </TermParagraph>
          <TermH4>9.5. Notice</TermH4>
          <TermParagraph>
            We may provide any notice to you under this Agreement using commercially reasonable means, including using
            public communication channels. Notices we provide by using public communication channels will be effective
            upon posting.
          </TermParagraph>
          <TermH4>9.6 Severability</TermH4>
          <TermParagraph>
            If any provision of this Agreement shall be determined to be invalid or unenforceable under any rule, law,
            or regulation of any local, state, or federal government agency, such provision will be changed and
            interpreted to accomplish the objectives of the provision to the greatest extent possible under any
            applicable law and the validity or enforceability of any other provision of this Agreement shall not be
            affected.
          </TermParagraph>
        </TermsStyle>
      </Container>
    </PageWrapper>
  )
}
