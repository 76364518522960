import { ITokenInfoInResponse } from '@swapnet-xyz/sdk'
import { Protocol } from 'eth-mainnet-few-router-sdk-3'
import { ChainId, Currency, Percent, Token, TradeType } from 'eth-mainnet-few-sdk-core-2'
import { FeeAmount, Pool } from 'eth-mainnet-few-v3-sdk-2'
import { ClassicTrade, RingXTrade } from 'state/routing/types'

export interface RoutingDiagramEntry {
  percent: Percent
  path: [Currency, Currency, FeeAmount][]
  protocol: Protocol
}

const V2_DEFAULT_FEE_TIER = 3000

/**
 * Loops through all routes on a trade and returns an array of diagram entries.
 */
export default function getRoutingDiagramEntries(trade: ClassicTrade): RoutingDiagramEntry[] {
  return trade.swaps.map(({ route: { path: tokenPath, pools, protocol }, inputAmount, outputAmount }) => {
    const portion =
      trade.tradeType === TradeType.EXACT_INPUT
        ? inputAmount.divide(trade.inputAmount)
        : outputAmount.divide(trade.outputAmount)
    const percent = new Percent(portion.numerator, portion.denominator)
    const path: RoutingDiagramEntry['path'] = []
    for (let i = 0; i < pools.length; i++) {
      const nextPool = pools[i]
      const tokenIn = tokenPath[i]
      const tokenOut = tokenPath[i + 1]
      const entry: RoutingDiagramEntry['path'][0] = [
        tokenIn,
        tokenOut,
        nextPool instanceof Pool ? nextPool.fee : V2_DEFAULT_FEE_TIER,
      ]
      path.push(entry)
    }
    return {
      percent,
      path,
      protocol,
    }
  })
}

export interface RoutingRingXDiagramEntry {
  percent: Percent
  path: [Token, Token][]
  protocol: Protocol
}

/**
 * Loops through all routes on a trade and returns an array of diagram entries.
 */
export function getRingXRoutingDiagramEntries(trade: RingXTrade, chainId: ChainId | undefined): any {
  if (trade.swapnetInfo && chainId) {
    const swapInfo = trade.swapnetInfo
    const routes = swapInfo.routes.map((item) => {
      const amountIn = swapInfo.tokens.find(
        (token) => item.fromTokens[0].referenceId == token.referenceId
      ) as ITokenInfoInResponse
      const amountOut = swapInfo.tokens.find(
        (token) => item.toTokens[0].referenceId == token.referenceId
      ) as ITokenInfoInResponse
      const amoutInToken = new Token(chainId, amountIn.address, amountIn.decimals, amountIn.symbol, amountIn.name)
      const amoutOutToken = new Token(chainId, amountOut.address, amountOut.decimals, amountOut.symbol, amountOut.name)
      const path: RoutingRingXDiagramEntry['path'] = [[amoutInToken, amoutOutToken]]
      const protocol = item.name
      return {
        // percent,
        path,
        protocol,
      }
    })
    const buyToken = trade.swapnetInfo.tokens.find((i) => i.referenceId == trade.swapnetInfo.buy.referenceId)
    const sellToken = trade.swapnetInfo.tokens.find((i) => i.referenceId == trade.swapnetInfo.sell.referenceId)
    return routes.sort((a, b) => {
      if (
        a.path[0][0].address.toLowerCase() == sellToken?.address.toLowerCase() &&
        b.path[0][0].address.toLowerCase() != sellToken?.address.toLowerCase()
      ) {
        return -1
      }
      if (
        a.path[0][0].address.toLowerCase() != sellToken?.address.toLowerCase() &&
        b.path[0][0].address.toLowerCase() == sellToken?.address.toLowerCase()
      ) {
        return 1
      }

      if (
        a.path[0][1].address.toLowerCase() == buyToken?.address.toLowerCase() &&
        b.path[0][1].address.toLowerCase() != buyToken?.address.toLowerCase()
      ) {
        return 1
      }
      if (
        a.path[0][1].address.toLowerCase() != buyToken?.address.toLowerCase() &&
        b.path[0][1].address.toLowerCase() == buyToken?.address.toLowerCase()
      ) {
        return -1
      }
      return 0
    })
  }
  return
}
