export interface PointData {
  id: string
  name: string
  twitter: string
  category: string
  imageUrl: string
  pointsAccrued: number
  pointsDistributed: number
  goldAccrued: number
  goldDistributed: number
}

interface DappsPoint {
  success: boolean
  dapps: PointData[]
}

const END_POINT = 'https://api.protocolring.com'

export const fetchPointData = async (): Promise<{ data?: PointData; loading: boolean; error: boolean }> => {
  try {
    const response = await fetch(`${END_POINT}/v1/dapps`)
    return response.json().then((res: DappsPoint) => {
      if (!res.success) {
        console.error(`Error fetching point from fetchPointData`)
        return { data: undefined, error: true, loading: false }
      }
      const data = {
        id: '',
        name: '',
        twitter: '',
        category: '',
        imageUrl: '',
        pointsAccrued: 0,
        pointsDistributed: 0,
        goldAccrued: 0,
        goldDistributed: 0,
      }

      const pointData = res.dapps.filter((item: PointData) => item.name === 'Ring Protocol')
      if (pointData.length) {
        Object.assign(data, pointData[0])
      }

      return { data, loading: false, error: false }
    })
  } catch (error) {
    console.error(`Error fetching point from fetchPointData: ${error}`)
    return { data: undefined, error: true, loading: false }
  }
}
