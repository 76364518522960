import { Percent } from 'eth-mainnet-few-sdk-core-2'

const PRECISION = 10_000
const DENOMINATOR = PRECISION * 100

// turn "0.5" into Percent representing 0.5%
export function toSlippagePercent(slippage: string): Percent {
  const numerator = Number(slippage) * PRECISION
  return new Percent(numerator, DENOMINATOR)
}
