import { Trans } from '@lingui/macro'
import { ToggleElement, ToggleWrapper } from 'components/Toggle/MultiToggle'
import { Currency, Token } from 'eth-mainnet-few-sdk-core-2'
import { useOriginalTokenOfFewToken } from 'hooks/useWrappedToken'
import { unwrappedOriginToken } from 'utils/unwrappedToken'

// the order of displayed base currencies from left to right is always in sort order
// currencyA is treated as the preferred base currency
export default function RateToggle({
  currencyA,
  currencyB,
  handleRateToggle,
  isOriginToken = false,
}: {
  currencyA: Currency
  currencyB: Currency
  handleRateToggle: () => void
  isOriginToken?: boolean
}) {
  const tokenA = currencyA?.wrapped
  const tokenB = currencyB?.wrapped

  const originTokenA = useOriginalTokenOfFewToken(tokenA)
  const originTokenB = useOriginalTokenOfFewToken(tokenB)

  let currency0 = unwrappedOriginToken(originTokenA as Token) as Token
  let currency1 = unwrappedOriginToken(originTokenB as Token) as Token

  if (isOriginToken) {
    currency0 = unwrappedOriginToken(tokenA as Token) as Token
    currency1 = unwrappedOriginToken(tokenB as Token) as Token
  }

  const isSorted = tokenA && tokenB && tokenA.sortsBefore(tokenB)

  return currency0 && currency1 ? (
    <div style={{ width: 'fit-content', display: 'flex', alignItems: 'center' }} onClick={handleRateToggle}>
      <ToggleWrapper width="fit-content">
        <ToggleElement isActive={isSorted} fontSize="12px">
          <Trans>{isSorted ? currency0.symbol : currency1.symbol}</Trans>
        </ToggleElement>
        <ToggleElement isActive={!isSorted} fontSize="12px">
          <Trans>{isSorted ? currency1.symbol : currency0.symbol}</Trans>
        </ToggleElement>
      </ToggleWrapper>
    </div>
  ) : null
}
