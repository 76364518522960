import { Trans } from '@lingui/macro'
import { useWeb3React } from '@web3-react/core'
import CampaignDarkIcon from 'assets/svg/campaign-dark-icon.svg'
import CampaignIcon from 'assets/svg/campaign-icon.svg'
import { useToggleAccountDrawer } from 'components/AccountDrawer'
import { V2Unsupported } from 'components/V2Unsupported'
import { isAddress } from 'ethers/lib/utils'
import useENS from 'hooks/useENS'
import { useNetworkSupportsV2 } from 'hooks/useNetworkSupportsV2'
import { Countdown } from 'pages/Earn/Countdown'
import { useCallback } from 'react'
import { useToggleWalletModal } from 'state/application/hooks'
import { useClaimCallback, useUserHasAvailableClaim } from 'state/claim/hooks'
import { AirdropTokenInfo } from 'state/stake/hooks'
import styled, { useTheme } from 'styled-components'
import { ThemedText } from 'theme'

import { ButtonPrimary } from '../../components/Button'
import { AutoColumn } from '../../components/Column'
import { CardBGImage, CardNoise, CardSection, DataCard } from '../../components/earn/v2/styled'
import Row, { RowBetween } from '../../components/Row'

const PageWrapper = styled(AutoColumn)`
  padding: 24px 18px;
  width: 100%;
  padding-inline-end: 1rem;
  padding-inline-start: 1rem;
  border: 1px solid ${({ theme }) => (theme.darkMode ? '#20242b' : '#22222212')};
  border-radius: 12px;
  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.md}px`}) {
    padding: 18px 8px;
  }
  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    padding-top: 20px;
  }
`

const PositionInfo = styled(AutoColumn)<{ dim: any }>`
  position: relative;
  width: 100%;
  opacity: ${({ dim }) => (dim ? 0.6 : 1)};
`

const BottomSection = styled(AutoColumn)`
  border-radius: 12px;
  width: 100%;
  position: relative;
`

const StyledDataCard = styled(DataCard)<{ bgColor?: any; showBackground?: any }>`
  background: radial-gradient(76.02% 75.41% at 1.84% 0%, #1e1a31 0%, #3d51a5 100%);
  z-index: 2;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  background: ${({ theme, bgColor, showBackground }) =>
    `radial-gradient(91.85% 100% at 1.84% 0%, ${bgColor} 0%,  ${
      showBackground ? theme.black : theme.deprecated_bg5
    } 100%) `};
`

const PoolData = styled(DataCard)`
  background: none;
  border: 1px solid ${({ theme }) => (theme.darkMode ? '#20242b' : '#22222212')};
  padding: 1rem;
  z-index: 1;
`

const DataRow = styled(RowBetween)`
  justify-content: center;
  gap: 12px;
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    flex-direction: column;
    gap: 12px;
  `};
`

export default function AirdropManage({ airdropTokenInfo }: { airdropTokenInfo: AirdropTokenInfo }) {
  const { account } = useWeb3React()
  // monitor for third party recipient of claim
  const { address: parsedAddress } = useENS(account)
  const theme = useTheme()

  const toggleWalletDrawer = useToggleAccountDrawer() // toggle wallet when disconnected

  const networkSupportsV2 = useNetworkSupportsV2()

  const airdropToken = airdropTokenInfo.airdropToken

  const airdrop = airdropTokenInfo.airdropType
  const hasAvailableClaim = useUserHasAvailableClaim(account, airdrop)
  const hasAirdrop = airdropTokenInfo?.hasAirdrop
  // toggle for staking modal and unstaking modal
  // const [showAirDropModal, setShowAirDropModal] = useState(false)

  // used for UI loading states
  // monitor the status of the claim from contracts and txns
  const { claimCallback } = useClaimCallback(parsedAddress, airdrop)

  const onClaim = useCallback(() => {
    claimCallback()
      // reset modal and log error
      .catch((error) => {
        console.log(error)
      })
  }, [claimCallback])

  const toggleWalletModal = useToggleWalletModal()

  const handleAirdropClick = useCallback(() => {
    if (account) {
      onClaim()
    } else {
      toggleWalletModal()
    }
  }, [account, onClaim, toggleWalletModal])

  if (!networkSupportsV2) return <V2Unsupported />

  return (
    <PageWrapper gap="lg" justify="center">
      <DataRow style={{ gap: '24px' }}>
        <PoolData>
          <AutoColumn gap="sm">
            <ThemedText.DeprecatedBody style={{ margin: 0 }}>
              <Row>
                <img src={theme.darkMode ? CampaignDarkIcon : CampaignIcon} className="w-6 h-6 mr-1" />
                <Trans>{airdropTokenInfo?.activity}</Trans>
              </Row>
            </ThemedText.DeprecatedBody>
          </AutoColumn>
        </PoolData>
        <Countdown exactEnd={airdropTokenInfo?.endTime} />
      </DataRow>
      <PositionInfo gap="lg" justify="center" dim={false}>
        <BottomSection gap="lg" justify="center">
          <StyledDataCard disabled={false}>
            <CardSection>
              <CardBGImage desaturate />
              <CardNoise />
              <AutoColumn gap="md">
                <RowBetween>
                  <ThemedText.DeprecatedWhite fontWeight={600}>
                    {hasAirdrop && !hasAvailableClaim ? <Trans>You has claimed</Trans> : <Trans>You can claim</Trans>}
                  </ThemedText.DeprecatedWhite>
                </RowBetween>
                <RowBetween style={{ alignItems: 'baseline' }}>
                  <ThemedText.DeprecatedWhite fontSize={36} fontWeight={600}>
                    {airdropTokenInfo?.airdropAmount?.toSignificant(6) ?? '-'}
                  </ThemedText.DeprecatedWhite>
                  <ThemedText.DeprecatedWhite>
                    <Trans>{airdropToken?.symbol}</Trans>
                  </ThemedText.DeprecatedWhite>
                </RowBetween>
              </AutoColumn>
            </CardSection>
          </StyledDataCard>
        </BottomSection>
        <DataRow>
          {!account ? (
            <ButtonPrimary $borderRadius="8px" width="100%" onClick={toggleWalletDrawer}>
              <Trans>Connect Wallet</Trans>
            </ButtonPrimary>
          ) : (
            <DataRow>
              <ButtonPrimary
                disabled={!isAddress(account ?? '') || !hasAvailableClaim}
                width="100%"
                $borderRadius="12px"
                onClick={handleAirdropClick}
              >
                <Trans>
                  {!hasAirdrop
                    ? 'Address has no available claim'
                    : account && !hasAvailableClaim
                    ? 'Reward has been claimed'
                    : 'Claim your airdrop'}
                </Trans>
              </ButtonPrimary>
            </DataRow>
          )}
        </DataRow>
      </PositionInfo>
    </PageWrapper>
  )
}
