import { motion } from 'framer-motion'
import { useEffect, useRef, useState } from 'react'

enum Delta {
  Decrease = 'decrease',
  Increase = 'increase',
  None = '',
}

const formatForDisplay = (number = '0') => number.split('').reverse()

function usePrevious(value: any) {
  const ref = useRef()

  useEffect(() => {
    ref.current = value
  })

  return ref.current
}

function DecimalOrCommaColumn({ symbol }: { symbol: string }) {
  return (
    <div>
      <span>{symbol}</span>
    </div>
  )
}

function addCommasToNumber({ num, fixed }: { num: number; fixed: number }) {
  const str = num.toFixed(fixed)
  if (str.includes('.')) {
    const parts = str.split('.')
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    return formatForDisplay(parts.join('.'))
  } else {
    return formatForDisplay(str.replace(/\B(?=(\d{3})+(?!\d))/g, ','))
  }
}

function NumberColumn({ digit, delta }: { digit: string; delta: Delta }) {
  const [position, setPosition] = useState(0)
  const [animationClass, setAnimationClass] = useState('')
  const previousDigit = usePrevious(digit)
  const columnContainer = useRef<HTMLDivElement>(null)

  const setColumnToNumber = (number: string) => {
    const clientHeight = columnContainer?.current?.clientHeight || 1
    const numberValue = parseInt(number, 10)
    setPosition(clientHeight * numberValue)
  }

  useEffect(() => setAnimationClass(delta), [digit, delta, previousDigit])

  useEffect(() => {
    setColumnToNumber(digit)
  }, [digit])

  return (
    <div className="ticker-column-container" ref={columnContainer}>
      <motion.div
        animate={{ y: position }}
        className={`ticker-column ${animationClass}`}
        onAnimationComplete={() => setAnimationClass('')}
      >
        {[9, 8, 7, 6, 5, 4, 3, 2, 1, 0].map((num) => (
          <div key={num} className="ticker-digit">
            <span>{num}</span>
          </div>
        ))}
      </motion.div>
      <span className="number-placeholder">0</span>
    </div>
  )
}

export const AnimateNumber = ({ number, fixed = 0 }: { number: number; fixed?: number }) => {
  const numArray = isNaN(number) ? addCommasToNumber({ num: 0, fixed }) : addCommasToNumber({ num: number, fixed })
  const previousNumber = usePrevious(number) || '0'

  const delta =
    number === Number(previousNumber) ? Delta.None : number > Number(previousNumber) ? Delta.Increase : Delta.Decrease

  return (
    <motion.span layout className="ticker-view tabular-nums">
      {numArray.map((number, index) =>
        number === '.' || number === ',' ? (
          <DecimalOrCommaColumn symbol={number} key={index} />
        ) : (
          <NumberColumn key={index} digit={number} delta={delta} />
        )
      )}
    </motion.span>
  )
}
