import { Trans } from '@lingui/macro'
import { V2Unsupported } from 'components/V2Unsupported'
import { useNetworkSupportsV2 } from 'hooks/useNetworkSupportsV2'
import styled from 'styled-components'
import { ThemedText } from 'theme'

import { AutoColumn } from '../../components/Column'
import { CardBGImage, CardNoise, CardSection, DataCard } from '../../components/earn/v2/styled'
import { RowBetween } from '../../components/Row'
import RedeemManager from './RedeemManager'

const PageWrapper = styled(AutoColumn)`
  padding: 68px 8px 0px;
  max-width: 640px;
  width: 100%;
  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.md}px`}) {
    padding: 48px 8px 0px;
  }
  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    padding-top: 20px;
  }
`

// eslint-disable-next-line import/no-unused-modules
export default function Converter() {
  const networkSupportsV2 = useNetworkSupportsV2()

  if (!networkSupportsV2) return <V2Unsupported />

  return (
    <PageWrapper gap="lg" justify="center">
      <DataCard>
        <CardBGImage />
        <CardNoise />
        <CardSection>
          <AutoColumn gap="md">
            <RowBetween>
              <ThemedText.DeprecatedWhite fontWeight={600}>
                <Trans>Ring Point Redeem Page</Trans>
              </ThemedText.DeprecatedWhite>
            </RowBetween>
            <RowBetween>
              <ThemedText.DeprecatedWhite fontSize={14}>
                <Trans>Ring Point token convert to Ring token.</Trans>
              </ThemedText.DeprecatedWhite>
            </RowBetween>
          </AutoColumn>
        </CardSection>
        <CardBGImage />
        <CardNoise />
      </DataCard>

      <RedeemManager />
    </PageWrapper>
  )
}
