import { Trans } from '@lingui/macro'
import { useWeb3React } from '@web3-react/core'
import { PortfolioLogo } from 'components/AccountDrawer/MiniPortfolio/PortfolioLogo'
import { BIG_INT_SECONDS_IN_YEAR } from 'constants/misc'
import { RING } from 'constants/tokens'
import { ChainId, CurrencyAmount, Token } from 'eth-mainnet-few-sdk-core-2'
import { DataRow } from 'pages/Earn'
import { Countdown } from 'pages/Earn/Countdown'
// import { useWrappedTokenFromOriginal } from 'hooks/useWrappedToken'
import styled from 'styled-components'

import { useColor } from '../../../hooks/useColor'
import useStablecoinPrice from '../../../hooks/useStablecoinPrice'
// import { FewStakingInfo } from '../../../state/stake/hooks'
import { StakingTokenInfo } from '../../../state/stake/hooks'
import { StyledInternalLink, ThemedText } from '../../../theme'
import { currencyId } from '../../../utils/currencyId'
// import { unwrappedToken } from '../../../utils/unwrappedToken'
import { ButtonPrimary } from '../../Button'
import { AutoColumn } from '../../Column'
import Row, { RowBetween } from '../../Row'
import { Break, CardBGImage, CardNoise } from './styled'

const StatContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 1rem;
  margin-right: 1rem;
  margin-left: 1rem;
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
  display: none;
`};
`

const Wrapper = styled(AutoColumn)<{ showBackground: boolean; bgColor: any }>`
  border-radius: 12px;
  width: 100%;
  overflow: hidden;
  position: relative;
  opacity: ${({ showBackground }) => (showBackground ? '1' : '1')};
  background: ${({ theme, bgColor, showBackground }) =>
    `radial-gradient(91.85% 100% at 1.84% 0%, ${bgColor} 0%, ${
      showBackground ? theme.black : theme.deprecated_bg5
    } 100%) `};
  color: ${({ theme, showBackground }) => (showBackground ? theme.white : theme.textPrimary)} !important;

  ${({ showBackground }) =>
    showBackground &&
    `  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);`}
`

const BottomSection = styled.div<{ showBackground: boolean }>`
  padding: 12px 16px;
  opacity: ${({ showBackground }) => (showBackground ? '1' : '0.4')};
  border-radius: 0 0 12px 12px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  z-index: 1;
`

export default function SingleTokenPoolCard({ stakingInfo }: { stakingInfo: StakingTokenInfo }) {
  const { chainId } = useWeb3React()
  // const fewToken = stakingInfo.token
  const originalToken = stakingInfo.originalToken

  // const currency = unwrappedToken(fewToken)

  // const originalCurrency = unwrappedToken(originalToken)

  const isStaking = Boolean(stakingInfo.stakedAmount.greaterThan('0'))

  // get the color of the token
  const token = originalToken.wrapped
  // const WETH = WRAPPED_NATIVE_CURRENCY[ChainId.BLAST_SEPOLIA]
  // const fwETH = useWrappedTokenFromOriginal(WRAPPED_NATIVE_CURRENCY[ChainId.BLAST_SEPOLIA])
  const backgroundColor = useColor(token)

  // const totalSupplyOfStakingToken = useTotalSupply(stakingInfo.stakedAmount.currency)
  // const [, stakingTokenPair] = useV2Pair(...stakingInfo.tokens)

  // let returnOverMonth: Percent = new Percent('0')
  const totalSupplyOfStakingToken = stakingInfo?.totalStakedAmount
  let valueOfTotalStakedAmountInOriginalToken: CurrencyAmount<Token> | undefined
  if (totalSupplyOfStakingToken && stakingInfo) {
    //TODO, fix usdc
    // take the total amount of LP tokens staked, multiply by ETH value of all LP tokens, divide by all LP tokens
    valueOfTotalStakedAmountInOriginalToken = CurrencyAmount.fromRawAmount(
      stakingInfo?.originalToken?.wrapped,
      stakingInfo.totalStakedAmount.quotient
    )
  }

  // get the USD value of staked WETH
  const USDPrice = useStablecoinPrice(stakingInfo?.originalToken.wrapped)
  const valueOfTotalStakedAmountInUSDC =
    valueOfTotalStakedAmountInOriginalToken && USDPrice?.quote(valueOfTotalStakedAmountInOriginalToken)

  let newPool
  let governanceToken

  const isEarnBlastGold =
    stakingInfo.earnedAmount.currency.chainId === ChainId.BLAST && originalToken.equals(RING[ChainId.BLAST])

  if (stakingInfo.earnedAmount.currency.chainId === ChainId.BLAST) {
    newPool = 'mainnet_pool'
    governanceToken = 'POINT'
  } else if (
    stakingInfo.stakingRewardAddress === '0x366Ac78214aFE145Ca35d4A6513F4eD9e8909Fe8' ||
    stakingInfo.stakingRewardAddress === '0x3f6d0620d655Dc618Cb956cc2073644f92736616'
  ) {
    newPool = 'newPool'
    governanceToken = 'POINT'
  } else {
    newPool = 'oldPool'
    governanceToken = 'RGB'
  }

  const unclaimedAmount = stakingInfo?.earnedAmount.subtract(stakingInfo?.claimedAmount)?.toFixed(6) ?? '0'

  return (
    <div>
      <DataRow style={{ alignItems: 'baseline' }}>
        <Countdown exactEnd={stakingInfo?.periodFinish} />
      </DataRow>
      <Wrapper showBackground={isStaking} bgColor={backgroundColor}>
        <CardBGImage desaturate />
        <CardNoise />

        <Row className="!p-4">
          <PortfolioLogo chainId={chainId as ChainId} currencies={[originalToken]} hiddenNetWork={true} />
          <ThemedText.DeprecatedWhite className="flex-1 pl-2 text-xl md:text-2xl" fontWeight={600}>
            {originalToken.symbol}
          </ThemedText.DeprecatedWhite>
          <StyledInternalLink to={`/earn/${currencyId(originalToken)}/${newPool}`}>
            <ButtonPrimary className="!py-1.5 md:!py-2 !px-2 md:!px-4" $borderRadius="8px">
              {isStaking ? <Trans>Manage</Trans> : <Trans>Deposit</Trans>}
            </ButtonPrimary>
          </StyledInternalLink>
        </Row>
        <StatContainer>
          <RowBetween>
            <ThemedText.DeprecatedWhite>
              <Trans>Total deposited</Trans>
            </ThemedText.DeprecatedWhite>
            <ThemedText.DeprecatedWhite>
              {valueOfTotalStakedAmountInUSDC
                ? `$${valueOfTotalStakedAmountInUSDC.toFixed(2, { groupSeparator: ',' })}`
                : `${totalSupplyOfStakingToken?.toSignificant(4, { groupSeparator: ',' }) ?? '-'} ${
                    totalSupplyOfStakingToken?.currency.symbol
                  }`}
            </ThemedText.DeprecatedWhite>
          </RowBetween>
          <RowBetween>
            <ThemedText.DeprecatedWhite>
              {isEarnBlastGold ? (
                <Trans>The protocol will regularly distribute Blast Gold Points based on the amount you stake.</Trans>
              ) : (
                <Trans>Pool rate per {originalToken.symbol}</Trans>
              )}
            </ThemedText.DeprecatedWhite>
            <ThemedText.DeprecatedWhite>
              {isEarnBlastGold ? (
                ''
              ) : stakingInfo ? (
                stakingInfo.active ? (
                  <Trans>
                    {stakingInfo.totalRewardRate
                      ?.multiply(BIG_INT_SECONDS_IN_YEAR)
                      ?.toFixed(2, { groupSeparator: ',' })}{' '}
                    {governanceToken} / year
                  </Trans>
                ) : (
                  <Trans>0 {governanceToken} / year</Trans>
                )
              ) : (
                '-'
              )}
            </ThemedText.DeprecatedWhite>
          </RowBetween>
          {Number(unclaimedAmount) > 0.000001 && (
            <RowBetween>
              <ThemedText.DeprecatedWhite>
                <Trans>Your unclaimed {governanceToken}</Trans>
              </ThemedText.DeprecatedWhite>
              <ThemedText.DeprecatedWhite>
                <Trans>{unclaimedAmount}</Trans>
              </ThemedText.DeprecatedWhite>
            </RowBetween>
          )}
        </StatContainer>

        {!isEarnBlastGold && isStaking && (
          <>
            <Break />
            <BottomSection showBackground={true}>
              <ThemedText.DeprecatedBlack color="white" fontWeight={500}>
                <span>
                  <Trans>Your rate per {originalToken.symbol}</Trans>
                </span>
              </ThemedText.DeprecatedBlack>

              <ThemedText.DeprecatedBlack style={{ textAlign: 'right' }} color="white" fontWeight={500}>
                <span role="img" aria-label="wizard-icon" style={{ marginRight: '0.5rem' }}>
                  ⚡
                </span>
                {stakingInfo ? (
                  stakingInfo.active ? (
                    <Trans>
                      {stakingInfo.rewardRate
                        ?.multiply(BIG_INT_SECONDS_IN_YEAR)
                        ?.toSignificant(4, { groupSeparator: ',' })}{' '}
                      {governanceToken} / year
                    </Trans>
                  ) : (
                    <Trans>0 {governanceToken} / year</Trans>
                  )
                ) : (
                  '-'
                )}
              </ThemedText.DeprecatedBlack>
            </BottomSection>
          </>
        )}
      </Wrapper>
    </div>
  )
}
