import { Trans } from '@lingui/macro'
import { useWeb3React } from '@web3-react/core'
import Column from 'components/Column'
import { LoadingRows } from 'components/Loader/styled'
import RoutingDiagram, { RoutingRingXDiagram } from 'components/RoutingDiagram/RoutingDiagram'
import Row from 'components/Row'
import { SUPPORTED_GAS_ESTIMATE_CHAIN_IDS } from 'constants/chains'
import useAutoRouterSupported from 'hooks/useAutoRouterSupported'
import { ClassicTrade, RingXTrade } from 'state/routing/types'
import { isClassicTrade, isRingXTrade } from 'state/routing/utils'
import { ExternalLink, Separator, ThemedText } from 'theme'
import getRoutingDiagramEntries, { getRingXRoutingDiagramEntries } from 'utils/getRoutingDiagramEntries'

import RouterLabel from '../RouterLabel'

export default function SwapRingXRoute({ trade, syncing }: { trade: ClassicTrade | RingXTrade; syncing: boolean }) {
  const { chainId } = useWeb3React()
  const autoRouterSupported = useAutoRouterSupported()

  const routes = isClassicTrade(trade)
    ? getRoutingDiagramEntries(trade)
    : isRingXTrade(trade)
    ? getRingXRoutingDiagramEntries(trade, chainId)
    : undefined

  const gasPrice =
    // TODO(WEB-2022)
    // Can `trade.gasUseEstimateUSD` be defined when `chainId` is not in `SUPPORTED_GAS_ESTIMATE_CHAIN_IDS`?
    trade.gasUseEstimateUSD && chainId && SUPPORTED_GAS_ESTIMATE_CHAIN_IDS.includes(chainId)
      ? trade.gasUseEstimateUSD === 0
        ? '<$0.01'
        : '$' + trade.gasUseEstimateUSD.toFixed(2)
      : undefined

  return (
    <Column gap="md">
      <Row justify="space-between">
        <RouterLabel trade={trade} />
        {isRingXTrade(trade) && <ExternalLink href="https://www.swap-net.xyz/">Powered By Swapnet</ExternalLink>}
      </Row>
      <Separator />
      {syncing ? (
        <LoadingRows>
          <div style={{ width: '100%', height: '30px' }} />
        </LoadingRows>
      ) : isRingXTrade(trade) ? (
        <RoutingRingXDiagram routes={routes} />
      ) : (
        <RoutingDiagram
          currencyIn={trade.inputAmount.currency}
          currencyOut={trade.outputAmount.currency}
          routes={routes}
        />
      )}
      {autoRouterSupported && (
        <>
          <Separator />
          {syncing ? (
            <LoadingRows>
              <div style={{ width: '100%', height: '15px' }} />
            </LoadingRows>
          ) : (
            <ThemedText.BodySmall color="neutral2">
              {gasPrice ? <Trans>Best price route costs ~{gasPrice} in gas.</Trans> : null}{' '}
              <Trans>
                This route optimizes your total output by considering split routes, multiple hops, and the gas cost of
                each step.
              </Trans>
            </ThemedText.BodySmall>
          )}
        </>
      )}
    </Column>
  )
}
